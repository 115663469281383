import { Injectable } from '@angular/core';

import { IObjectStringKeyMap } from '@shared/models';
import { UserService } from '@services/user/user.service';
import { TeamsService } from '@services/teams/teams.service';
import { ShiftService } from '@services/shift/shift.service';
import { RolesService } from '@services/roles/roles.service';
import { AccountsService } from '@services/accounts/accounts.service';
import { UserdataService } from '@services/userdata/userdata.service';
import { Permission } from '@services/permissions/permissions.service';
import { FormField } from '@services/formBuilder/form-builder.service';
import { CertificationsService } from '@services/certifications/certifications.service';

import {
  assign,
  cloneDeep,
  each,
  filter,
  find,
  get,
  includes,
  isEqual,
  isUndefined,
  lowerCase,
  map,
  reject,
  sortBy,
  toString as _toString
} from 'lodash';
import { TranslateService } from '@ngx-translate/core';

export interface IFilterComp {
  name: string;  // display name
  dropDownOptions: any[]; // the menu of options
  options: any;  // select2 components
  value?: any;  // pre-selected values for select2
  id?: string; // exact object name used in filterObject for quick mapping
}

@Injectable({
  providedIn: 'root'
})


export class FilterElementService {
  public latestFilterArray: (string | number)[];
  public rangeOptions = [
    {
      id: '',
      text: ''
    },
    {
      id: 'today',
      text: this.translate.instant('SHARED.Today')
    },
    {
      id: '7days',
      text: this.translate.instant('SHARED.7_Days')
    },
    {
      id: '14days',
      text: this.translate.instant('SHARED.14_Days')
    },
    {
      id: '30days',
      text: this.translate.instant('SHARED.30_Days')
    },
    {
      id: '90days',
      text: this.translate.instant('SHARED.90_Days')
    },
    {
      id: '365days',
      text: this.translate.instant('SHARED.365_days')
    }
  ];
  public fullRangeOption = [
    {
      id: '',
      text: ''
    },
    {
      id: 'today',
      text: this.translate.instant('SHARED.Today')
    },
    {
      id: '7days',
      text: this.translate.instant('SHARED.7_Days')
    },
    {
      id: '14days',
      text: this.translate.instant('SHARED.14_Days')
    },
    {
      id: '30days',
      text: this.translate.instant('SHARED.30_Days')
    },
    {
      id: '90days',
      text: this.translate.instant('SHARED.90_Days')
    },
    {
      id: '365days',
      text: this.translate.instant('SHARED.365_days')
    },
    {
      id: 'custom',
      text: this.translate.instant('SHARED.Custom')
    },
    {
      id: 'all',
      text: this.translate.instant('SHARED.ALL_TIME')
    },
  ];
  public timeSpanOptions = [
    {
      id: '',
      text: ''
    },
    {
      id: '7days',
      text: this.translate.instant('SHARED.7_Days')
    },
    {
      id: '14days',
      text: this.translate.instant('SHARED.14_Days')
    },
    {
      id: '30days',
      text: this.translate.instant('SHARED.30_Days')
    },
    {
      id: '90days',
      text: this.translate.instant('SHARED.90_Days')
    },
    {
      id: 'thisweek',
      text: this.translate.instant('SHARED.This_Week')
    },
    {
      id: 'lastweek',
      text: this.translate.instant('SHARED.Last_Week')
    },
    {
      id: 'thismonth',
      text: this.translate.instant('SHARED.This_Month')
    },
    {
      id: 'lastmonth',
      text: this.translate.instant('SHARED.Last_Month')
    },
    {
      id: 'thisfiscalquarter',
      text: this.translate.instant('SHARED.This_Fiscal_Quarter')
    },
    {
      id: 'thisquarter',
      text: this.translate.instant('SHARED.This_Quarter')
    },
    {
      id: 'lastfiscalquarter',
      text: this.translate.instant('SHARED.Last_Fiscal_Quarter')
    },
    {
      id: 'lastquarter',
      text: this.translate.instant('SHARED.Last_Quarter')
    },
    {
      id: 'thisfiscalyear',
      text: this.translate.instant('SHARED.This_Fiscal_Year')
    },
    {
      id: 'thisyear',
      text: this.translate.instant('SHARED.This_Year')
    },
    {
      id: 'lastfiscalyear',
      text: this.translate.instant('SHARED.Last_Fiscal_Year')
    },
    {
      id: 'lastyear',
      text: this.translate.instant('SHARED.Last_Year')
    },
    {
      id: 'all',
      text: this.translate.instant('SHARED.ALL_TIME')
    },
    {
      id: 'custom',
      text: this.translate.instant('SHARED.Custom')
    }
  ];
  public singleOptions = {
    minimumResultsForSearch: Infinity,
    width: '100%'
  };
  public multiOptions = {
    multiple: true,
    allowClear: true,
    closeOnSelect: false,
    width: '100%'
  };
  public multiOptionsDefaultSort = {
    multiple: true,
    allowClear: true,
    closeOnSelect: false,
    width: '100%',
    sorter: (data) => data
  };
  public slOptions = {
    closeOnSelect: false,
    templateResult: this.formatState,
    templateSelection: this.formatSelection,
    width: '100%',
    multiple: true,
    allowClear: true,
  };

  constructor(
    private userService: UserService,
    private userdataService: UserdataService,
    private teamService: TeamsService,
    private accountsService: AccountsService,
    protected translate: TranslateService,
    private shiftService: ShiftService,
    private rolesService: RolesService,
    private certificationsService: CertificationsService
  ) {
  }

  formatState(state) {
    let $state;
    if (state.id === 'highest-S') {
      $state = $('<span><img class="image-spacer obs-dot" src="assets/images/highestDot.svg"></span>');
      return $state;
    }

    if (state.id === 'high-S') {
      $state = $('<span><img class="image-spacer obs-dot" src="assets/images/highDot.svg"></span>');
      return $state;
    }

    if (state.id === 'medium-S') {
      $state = $('<span><img class="image-spacer obs-dot" src="assets/images/mediumDot.svg"></span>');
      return $state;
    }

    if (state.id === 'low-S') {
      $state = $('<span><img class="image-spacer obs-dot" src="assets/images/lowDot.svg"></span>');
      return $state;
    }

    if (state.id === 'lowest-S') {
      $state = $('<span><img class="image-spacer obs-dot" src="assets/images/lowestDot.svg"></span>');
      return $state;
    }

    if (state.id === 'highest-L') {
      $state = $('<span><img class="image-spacer obs-dot" src="assets/images/highestDot.svg"></span>');
      return $state;
    }

    if (state.id === 'high-L') {
      $state = $('<span><img class="image-spacer obs-dot" src="assets/images/highDot.svg"></span>');
      return $state;
    }

    if (state.id === 'medium-L') {
      $state = $('<span><img class="image-spacer obs-dot" src="assets/images/mediumDot.svg"></span>');
      return $state;
    }

    if (state.id === 'low-L') {
      $state = $('<span><img class="image-spacer obs-dot" src="assets/images/lowDot.svg"></span>');
      return $state;
    }

    if (state.id === 'lowest-L') {
      $state = $('<span><img class="image-spacer obs-dot" src="assets/images/lowestDot.svg"></span>');
      return $state;
    }

    if (!state.id) {
      return state.text;
    }
  }

  formatSelection(state) {
    // may be check the final list and enable/disabled whatever is selected.
    let $state;
    if (state.id === 'highest-S' || state.id === 'highest-L') {
      // tslint:disable-next-line:max-line-length
      $state = $('<span><img class="image-spacer obs-dot" src="assets/images/highestDot.svg">' + state.element.parentElement.getAttribute('label') + '</span>');
      return $state;
    }

    if (state.id === 'high-S' || state.id === 'high-L') {
      // tslint:disable-next-line:max-line-length
      $state = $('<span><img class="image-spacer obs-dot" src="assets/images/highDot.svg">' + state.element.parentElement.getAttribute('label') + '</span>');
      return $state;
    }

    if (state.id === 'medium-S' || state.id === 'medium-L') {
      // tslint:disable-next-line:max-line-length
      $state = $('<span><img class="image-spacer obs-dot" src="assets/images/mediumDot.svg">' + state.element.parentElement.getAttribute('label') + '</span>');
      return $state;
    }

    if (state.id === 'low-S' || state.id === 'low-L') {
      // tslint:disable-next-line:max-line-length
      $state = $('<span><img class="image-spacer obs-dot" src="assets/images/lowDot.svg">' + state.element.parentElement.getAttribute('label') + '</span>');
      return $state;
    }

    if (state.id === 'lowest-S' || state.id === 'lowest-L') {
      // tslint:disable-next-line:max-line-length
      $state = $('<span><img class="image-spacer obs-dot" src="assets/images/lowestDot.svg">' + state.element.parentElement.getAttribute('label') + '</span>');
      return $state;
    }

    if (!state.id) {
      return state.text;
    }

  }

  public buildFilterCustomElementObjects(customElementFields: FormField[], filterData: IObjectStringKeyMap<number>[]): IFilterComp[] {
    return map(customElementFields, (field) => {
      const filterDataByField = filterData[field.name];
      const options = reject(map(filterDataByField, (data) => {
        return { id: field?.filter?.getItemId(data), text: field?.filter?.getItemText(data) };
      }), (dataItem) => isUndefined(dataItem?.id));

      return {
        dropDownOptions: options,
        options: {},
        id: field.name,
        name: field.name,
        value: map(filterDataByField, (item) => field?.filter?.getItemId(item))
      }
    });
  }

  public buildFilterCustomObjects(data, filterData): IFilterComp[] {
    const tempArray: IFilterComp[] = [];
    each(filterData, (elemValue, elemName) => {
      const filterEl = find(data, (field) => {
        return field.id === elemName || field.name === elemName;
      });
      if (filterEl) {
        const opts = [];
        each(get(filterEl, 'options', []), (optValue, optName) => {
          const id = optValue.id || optValue.messageID || optValue.assetID || optValue.userID || optValue.locationID || optValue.groupID || optValue.checkID;
          let text = optValue.messageTitle || optValue.description || optValue.title || optValue.text || optValue.name || optValue.firstname + ' ' + optValue.lastname;
          if (optValue.checkID) {
            text = optValue.title;
          }
          if (optValue.locationID || optValue.groupID) {
            text = optValue.name;
          }
          if (!id || !text) {
            if (filterEl.id === 'targetZones') {
              each(get(optValue, 'children', []), (cValue, cName) => {
                const cId = cValue.id.split(':').length > 1 ? cValue.id : +cValue.id;
                opts.push({id: cId, text: cValue.text});
              });
            } else if (filterEl.id === 'deploymentName') {
              each(get(optValue, 'children', []), (cValue, cName) => {
                opts.push({id: cValue.id, text: cValue.text});
              });
            }
          } else {
            opts.push({id, text});
          }
        });
        const customItem: IFilterComp = {
          name: get(filterEl, 'title'),
          dropDownOptions: opts,
          options: cloneDeep(this.multiOptions),
          value: elemValue,
          id: elemName
        };
        tempArray.push(customItem);
      }
    });
    return tempArray;
  }

  /**
   *
   * @param filtArray - elements of the filter, can be zone, location, timespan, user, team
   * @param filtObject - filterObject that gets shared between common entities, one for dashboard
   and another one for tables
   * @returns - IFilterComp object with names and options generated for that menu.
   */

  /* HOW TO ADD FILTER ELEMENTS TO COMPONENTS
     core element menuObj: its composition is as follows:
       > id: name by which we'll get this filter element in filterObject
      > const menuObj: any = {
           name: pass translated name service which appears on top of drop-down
           dropDownOptions: options for multipe (multiOptions) and single select (singleOption)
           options: the select2 drop down, can pass function, or craft object like below.
           id: the identifer in filterObject
               options.placeholder: for multi use id: '-1', for single user id: '', see example below
           value: THIS IS TRICKY - multiOptions directly take array of values, for single select, whatever is returned from drop-down, put it in an array and pass back, in case of clear, pass empty array, see 'status example'
         };
  */
  public buildFilterObjects(filtArray: any[], filterObject, includeDisabled: boolean = true): IFilterComp[] {
    this.latestFilterArray = filtArray;
    const tempArray: IFilterComp[] = [];
    each(filtArray, elem => {
      switch (elem) {
        case 'location': {
          const menuObj: any = this.userService.buildLocationMenu(this.userdataService.locations, includeDisabled);
          menuObj.options = cloneDeep(this.multiOptions);
          menuObj.options.placeholder = {
            id: '-1',
            text: this.translate.instant('SHARED.All_Locations')
          };
          menuObj.id = 'locations';
          menuObj.value = map(filterObject.locations, val => _toString(val));
          tempArray.push(menuObj);
          break;
        }
        case 'team': {
          const menuObj: any = this.buildTeamMenu(this.userdataService.locations, includeDisabled);
          menuObj.options = cloneDeep(this.multiOptionsDefaultSort);
          menuObj.options.placeholder = {
            id: '-1',
            text: this.translate.instant('SHARED.All_Teams')
          };
          menuObj.id = 'groups';
          menuObj.value = map(filterObject.groups, val => _toString(val));
          tempArray.push(menuObj);
          break;
        }
        case 'role': {
          tempArray.push({
            id: 'roles',
            name: this.translate.instant('SHARED.Role'),
            value: map(filterObject.roles, String),
            dropDownOptions: map(reject(this.rolesService.roles.data, 'disabledAt'), (role) => ({
              id: role.roleID,
              text: role.name
            })),
            options: assign({}, this.multiOptionsDefaultSort, {
              placeholder: {
                id: '-1',
                text: this.translate.instant('SHARED.All_Roles')
              }
            })
          });
          break;
        }
        case 'certification': {
          tempArray.push({
            id: 'certifications',
            name: 'Certifications',
            value: map(filterObject.certifications, val => _toString(val)),
            dropDownOptions: map(reject(this.certificationsService.certifications.data, 'disabledAt'), (certification) => ({
              id: certification.certificationID,
              text: certification.name
            })),
            options: assign({}, this.multiOptionsDefaultSort, {
              placeholder: {
                id: '-1',
                text: this.translate.instant('SHARED.All_Certifications')
              }
            })
          });
          break;
        }
        case 'range': {
          const menuObj: any = (this.buildTimeSpanMenu());
          menuObj.options = cloneDeep(this.singleOptions);
          menuObj.options.placeholder = {
            id: '',
            text: this.translate.instant('OTable.All_History')
          };
          menuObj.id = 'timespan';
          if (filterObject.timespan) {
            if (filterObject.timespan === 'all') {
              menuObj.value = [];
            } else {
              menuObj.value = [filterObject.timespan];
            }

          }
          menuObj.options.sorter = (data) => data;
          tempArray.push(menuObj);
          break;
        }
        case 'extendedRange': {
          const menuObj: any = this.buildExendedTimeSpanMenu();
          menuObj.options = cloneDeep(this.singleOptions);
          menuObj.options.placeholder = {
            id: '',
            text: this.translate.instant('OTable.All_History')
          };
          menuObj.options.sorter = (data) => data;
          menuObj.id = 'timespan';
          if (filterObject.timespan) {
            if (filterObject.timespan === 'all') {
              menuObj.value = [];
            } else {
              menuObj.value = [filterObject.timespan];
            }

          }
          tempArray.push(menuObj);
          break;
        }
        case 'timespan': {
          const menuObj: any = {
            name: 'Timespan',
            dropDownOptions: this.timeSpanOptions,
            options: this.singleOptions
          };
          menuObj.options = cloneDeep(this.singleOptions);
          menuObj.options.placeholder = {
            id: '',
            text: this.translate.instant('OTable.All_History')
          };
          menuObj.options.sorter = (data) => data;
          menuObj.id = 'timespan';
          if (filterObject.timespan) {
            if (filterObject.timespan === 'all') {
              menuObj.value = [];
            } else {
              menuObj.value = [filterObject.timespan];
            }

          }
          tempArray.push(menuObj);
          break;
        }
        case 'user': {
          const menuObj: any = this.accountsService.buildUserMenu(this.userdataService.locations, false);
          menuObj.options = cloneDeep(this.multiOptions);
          menuObj.options.placeholder = {
            id: '-1',
            text: this.translate.instant('SHARED.All_Users')
          };
          menuObj.id = 'users';
          menuObj.value = map(filterObject.users, val => _toString(val));
          tempArray.push(menuObj);
          break;
        }
        case 'zone': {
          // const buildVal =  filterObject.zones;

          const menuObj: any = this.userService.buildZoneMenu(this.userdataService.locations, includeDisabled);
          menuObj.options = cloneDeep(this.multiOptions);
          menuObj.options.placeholder = {
            id: '-1',
            text: this.translate.instant('SHARED.All_Zones')
          };

          menuObj.options.sorter = (data) => sortBy(data, (sortItem: any) => {
            if (sortItem.children) {
              sortItem.children = sortBy(sortItem.children, (child) => child.text === 'Site-wide' || lowerCase(child.text));
            }
            return lowerCase(sortItem.text);
          });
          menuObj.id = 'zones';
          menuObj.value = map(filterObject.zones, val => _toString(val));
          tempArray.push(menuObj);
          break;
        }
        case 'sl': {
          const menuObj: any = this.buildSLMenu();
          menuObj.options.placeholder = {
            id: '-1',
            text: this.translate.instant('OTable.All_S_L')
          };
          menuObj.id = 'sl';
          menuObj.value = [];
          // read from likelihood and severity and put inside menuObj
          if (filterObject.likelihoods && filterObject.likelihoods.length) {
            each(filterObject.likelihoods, val => {
              menuObj.value.push(val + '-L');
            });
          }

          if (filterObject.severities && filterObject.severities.length) {
            each(filterObject.severities, val => {
              menuObj.value.push(val + '-S');
            });
          }
          tempArray.push(menuObj);
          break;
        }

        case 'active': {
          const menuObj: any = {
            name: this.translate.instant('REPORTING.User_Status'),
            dropDownOptions: [
              {
                id: 'active',
                text: this.translate.instant('SHARED.Active')
              },
              {
                id: 'inactive',
                text: this.translate.instant('SHARED.Inactive')
              },
            ],
            options: cloneDeep(this.multiOptions),
            id: 'active',
            value: filterObject.active
          };

          menuObj.options.placeholder = {
            id: '-1',
            text: this.translate.instant('OTable.ALL_STATUSES')
          };

          tempArray.push(menuObj);
          break;
        }
        case 'accountType': {
          const menuObj: any = {
            name: this.translate.instant('OTable.Account_Type'),
            dropDownOptions: [
              {
                id: 'dedicated',
                text: this.translate.instant('SHARED.Dedicated')
              },
              {
                id: 'shared',
                text: this.translate.instant('SHARED.Shared')
              },
              {
                id: 'viewer',
                text: this.translate.instant('SHARED.Viewer')
              },
              {
                id: 'reporting',
                text: this.translate.instant('SHARED.Reporting')
              },
              {
                id: 'observer',
                text: this.translate.instant('SHARED.Observer')
              }
            ],
            options: cloneDeep(this.multiOptions),
            id: 'accountType',
            value: filterObject.accountType
          };

          menuObj.options.placeholder = {
            id: '-1',
            text: this.translate.instant('OTable.All_Types')
          };

          tempArray.push(menuObj);
          break;
        }
        case 'permissionLevel': {
          const menuObj: any = {
            name: this.translate.instant('OTable.Permission_Level'),
            dropDownOptions: [
              {
                id: Permission.Worker,
                text: this.translate.instant('SHARED.Worker')
              },
              {
                id: Permission.Helper,
                text: this.translate.instant('SHARED.Helper')
              },
              {
                id: Permission.Supervisor,
                text: this.translate.instant('SHARED.Supervisor')
              },
              {
                id: Permission.Manager,
                text: this.translate.instant('SHARED.Manager')
              },
              {
                id: Permission.Admin,
                text: this.translate.instant('SHARED.Administration')
              },
              {
                id: Permission.SuperAdmin,
                text: this.translate.instant('SHARED.Global_Administration')
              }
            ],
            options: cloneDeep(this.multiOptions),
            id: 'permissionLevel',
            value: filterObject.permissionLevel
          };

          menuObj.options.placeholder = {
            id: '-1',
            text: this.translate.instant('OTable.All_Types')
          };

          tempArray.push(menuObj);
          break;
        }
        case 'status': {
          const menuObj: any = {
            name: this.translate.instant('SHARED.Status'),
            dropDownOptions: [
              {
                id: '',
                text: ''
              },
              {
                id: 1,
                text: this.translate.instant('SHARED.Active')
              },
              {
                id: 2,
                text: this.translate.instant('SHARED.Inactive')
              }
            ],
            options: cloneDeep(this.multiOptions),
            id: 'status',
          };
          /*
           This is for single select like status and time span,
           the value selected needs to be passed as item inside array.
            The array needs to be empty when no-selection is made (either through cancellings
            or clearing filter)  through filter-component
          */
          if (filterObject.status) {
            menuObj.value = this.multiOptions.multiple ? filterObject.status : [filterObject.status];
          } else {
            menuObj.value = [];
          }

          menuObj.options.placeholder = {
            id: '',
            text: this.translate.instant('OTable.ALL_STATUSES')
          };

          tempArray.push(menuObj);
          break;
        }
        case 'shift': {
          const allShifts: any = [];
          const shiftMenuObj = {};
          each(this.shiftService.getByLocationIds(this.userdataService.locations), (shift: any) => {
            const loc = this.userService.getLocation(shift.locationID);
            if (loc) {
              const childObject = {
                id: shift.uuid,
                text: shift.name,
                locationId: shift.locationID
              };
              if (shiftMenuObj[loc.name]) {
                shiftMenuObj[loc.name].push(childObject);
              } else {
                shiftMenuObj[loc.name] = [childObject];
              }


            }

          });
          const shiftsByLocations: any[] = get(filterObject, 'locations.length') ? filter(allShifts, (shift: any) => includes(filterObject.locations, shift.locationId)) : allShifts;

          each(shiftMenuObj, (obj, key) => {
            allShifts.push({
              text: key,
              children: obj
            });

          });

          const menuObj: any = {
            name: this.translate.instant('SHARED.Shift'),
            allOptions: allShifts,
            dropDownOptions: allShifts,
            options: cloneDeep(this.multiOptions),
            id: 'shift',
            value: map(filterObject.shift, val => _toString(val))
          };

          menuObj.options.placeholder = {
            id: '-1',
            text: this.translate.instant('OTable.All_Shifts')
          };

          tempArray.push(menuObj);
          break;
        }
      }
    });

    return tempArray;

  }

  compareForms(oldForm, newForm) {
    if (newForm.timespan?.type === 'all') {
      delete newForm.timespan;
    }
    if (oldForm.timespan?.type === 'all') {
      delete oldForm.timespan;
    }
    const filterOld = this.buildFilterObjects(this.latestFilterArray, oldForm);
    const filterNew = this.buildFilterObjects(this.latestFilterArray, newForm);
    const valuesOld = map(filterOld, 'value');
    const valuesNew = map(filterNew, 'value');

    return isEqual(valuesOld, valuesNew);
  }

  filterValuesAvailable(filterComps) {
    let filterAvailable = false;
    each(filterComps, comp => {
      if (comp.value && comp.value.length > 0) {
        filterAvailable = true;
        return false;
      }
    });
    return filterAvailable;
  }

  public buildTeamMenu(locations: number[] = [], includeDisabled: boolean = true, ids: number[] = []) {
    const teamArray = [];
    let theData = locations.length ? this.teamService.getTeamList(locations) : this.teamService.teams.data;

    // filter out data by ids, if any.
    if (ids.length) {
      theData = filter(theData, (data: any) => {
        if (includes(ids, data.groupID)) {
          return true;
        }
      });
    }

    // if the location is empty, or more than one then we know this is shared team.
    // if team has ONE and ONLY location then we populate it under the location Name
    const sharedTeams = [];
    const locationTeamObj = {};
    each(theData, data => {
      if (!includeDisabled && data.disabledAt) {
        return;
      }
      if (data.locations.length !== 1) {
        sharedTeams.push(data);
      } else {
        if (locationTeamObj[data.locations[0]]) {
          locationTeamObj[data.locations[0]].push(data);
        } else {
          locationTeamObj[data.locations[0]] = [data];
        }
      }
    });

    // now start putting teams with location first.
    each(locationTeamObj, (teamObj, key) => {
      const locObj = this.userService.getLocation(+key);
      const children = [];
      each(teamObj, (data: any) => {
        const childObj = {
          id: data.groupID,
          parentLocationIds: [+key],
          text: data.disabledAt ? data.name + ' (' + this.translate.instant('SHARED.Deactivated') + ')' : data.name
        };
        children.push(childObj);
      });
      if (locObj) {
        teamArray.push({
          text: locObj.name,
          children,
          id: +key
        });
      }

    });

    // now put shared teams
    const sharedChildren = [];
    each(sharedTeams, (data: any) => {
      const childObj = {
        id: data.groupID,
        parentLocationIds: data.locations,
        text: data.disabledAt ? data.name + ' (' + this.translate.instant('SHARED.Deactivated') + ')' : data.name
      };
      sharedChildren.push(childObj);
    });

    if (sharedChildren.length) {
      teamArray.unshift({
        text: this.translate.instant('SHARED.Shared_Teams'),
        id: this.translate.instant('SHARED.Shared_Teams'),
        children: sharedChildren
      });
    }

    return {
      name: 'Team',
      dropDownOptions: teamArray,
    };
  }

  private buildTimeSpanMenu() {
    return {
      name: 'Timespan',
      dropDownOptions: this.rangeOptions,
      options: this.singleOptions,
    };
  }

  private buildExendedTimeSpanMenu() {
    return {
      name: 'Timespan',
      dropDownOptions: this.fullRangeOption,
      options: this.singleOptions,
    };
  }

  private buildSLMenu() {
    const dropDownOpts = [
      {
        text: this.translate.instant('SHARED.Severity'),
        id: 'severity',
        children: [
          {
            id: 'highest-S',
            text: this.translate.instant('OTable.Sev')
          },
          {
            id: 'high-S',
            text: this.translate.instant('OTable.Sev')
          },
          {
            id: 'medium-S',
            text: this.translate.instant('OTable.Sev')
          }, {
            id: 'low-S',
            text: this.translate.instant('OTable.Sev')
          }, {
            id: 'lowest-S',
            text: this.translate.instant('OTable.Sev')
          }
        ]
      },
      {
        text: this.translate.instant('SHARED.Likelihood'),
        id: 'likelihood',
        children: [
          {
            id: 'highest-L',
            text: this.translate.instant('OTable.Lik')
          },
          {
            id: 'high-L',
            text: this.translate.instant('OTable.Lik')
          },
          {
            id: 'medium-L',
            text: this.translate.instant('OTable.Lik')
          }, {
            id: 'low-L',
            text: this.translate.instant('OTable.Lik')
          }, {
            id: 'lowest-L',
            text: this.translate.instant('OTable.Lik')
          }
        ]
      }
    ];

    return {
      name: 'S + L',
      dropDownOptions: dropDownOpts,
      options: this.slOptions,
    };

  }


}
