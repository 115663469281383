import { Component, EventEmitter, Output } from '@angular/core';

@Component({ template: '' })
export abstract class CustomFormComponent {
  @Output() onChanged: EventEmitter<any> = new EventEmitter<any>();
  public required: boolean;

  private _formValue;

  get formValue() {
    return this._formValue;
  }

  set formValue(value) {
    this._formValue = value;
    this.onChanged.emit(value);
  }
}
