import { IonMenu, MenuController, PopoverController } from '@ionic/angular';
import { AfterViewInit, Component, ElementRef, OnDestroy, OnInit, ViewChild } from '@angular/core';

import { PopoverTemplateComponent } from '@shared/components';
import { SelectFiltersFormComponent } from '@modules/reporting/components';
import { FilterElementService, FormBuilderService, FormConfig, FormField } from '@services';
import { FilterData, FilterMenuFormConfig, FilterMenuFormField, FilterMenuService, ToggleEvent } from '@shared/modules/menu-filter/services/filter-menu.service';

import merge from 'deepmerge';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { TranslateService } from '@ngx-translate/core';
import { cloneDeep, each, filter, includes, isArray, isString, isUndefined, map, some } from 'lodash';

@Component({
  selector: 'app-filter-menu',
  templateUrl: './filter-menu.component.html',
  styleUrls: ['./filter-menu.component.scss'],
})
export class FilterMenuComponent implements OnInit, OnDestroy, AfterViewInit {

  @ViewChild('formElement') formElement: ElementRef<HTMLFormElement>;
  @ViewChild(IonMenu) ionMenu: IonMenu;

  public readonly menuId: string = 'filterMenu';
  public currentConfig: FilterMenuFormConfig;
  protected formConfig: FormConfig = {
    autocomplete: false,
    canClear: true,
    containers: true,
    prefix: this.menuId,
    del: null,
    save: null,
    cancel: null,
    fields: []
  };
  private closeEvent: (data: FilterData) => void;
  private subscriptionDestroy: Subject<boolean> = new Subject<boolean>();
  private currentFields: FormField[] = [];
  private filterField: FormField = {
    title: null,
    name: 'filtersFields',
    type: 'customElement',
    component: SelectFiltersFormComponent,
    inputs: {
      options: [],
      selectedItems: []
    },
    outputs: {
      onChanged: (selectedItems: string[]) => {
        this.currentConfig.data = this.getFormData($(this.formElement.nativeElement));
        this.prepareFormConfig(this.currentConfig);

        this.filterField.inputs.selectedItems = selectedItems;
        this.formConfig.fields = [
          ...this.filterCurrentFields(selectedItems),
          this.filterField
        ];
        this.createForm(this.currentConfig.data);
      }
    }
  };

  constructor(
    private filterMenuService: FilterMenuService,
    private filterElementService: FilterElementService,
    private menuController: MenuController,
    private popover: PopoverController,
    private translate: TranslateService,
    private formBuilderService: FormBuilderService
  ) {
  }

  ngOnInit() {
    this.filterMenuService.onToggle
      .pipe(takeUntil(this.subscriptionDestroy))
      .subscribe((event: ToggleEvent) => this.init(event));
  }

  ngOnDestroy() {
    this.subscriptionDestroy.next(true);
    this.subscriptionDestroy.unsubscribe();
  }

  ngAfterViewInit() {
    this.ionMenu.ionDidClose.pipe(takeUntil(this.subscriptionDestroy)).subscribe(async (event) => {
      const data = this.getFormData($(this.formElement.nativeElement));

      if (!this.filterElementService.compareForms(this.currentConfig.data, data)) {
        const popup = await this.popover.create(<any>{
          component: PopoverTemplateComponent,
          animated: false,
          componentProps: {
            config: {
              onCancel: () => this.enableMenu(),
              onSave: () => this.applyFilters(),
              showCloseButton: true,
              title: this.translate.instant('SHARED.Apply_Filters') + '?',
              description: this.translate.instant('SHARED.FiltersApplyOrCancel'),
              cancelTitle: this.translate.instant('SHARED.Keep_Editing'),
              saveTitle: this.translate.instant('SHARED.Apply_Filters'),
            }
          }
        });
        popup.present();
      }
    });
  }

  public async applyFilters() {
    const formInstance: any = $(this.formElement.nativeElement);

    if (formInstance.valid()) {
      this.currentConfig.data = cloneDeep(this.getFormData(formInstance));
      await this.ionMenu.close();
      this.closeEvent(this.currentConfig.data);
    }
  }

  public clearForm() {
    this.currentConfig.data = {};
    this.filterField.inputs.selectedItems = [];

    this.prepareFormConfig(this.currentConfig);
    this.createForm();
  }

  private getFormData(formInstance): FilterData {
    const data: FilterData = this.formBuilderService.getFormData(formInstance);
    delete data.filtersFields;

    each(data, (values: (string | number)[], key: string) => {
      if (isArray(values)) {
        if (values.length) {
          data[key] = map(values, (value) => isNaN(+value) ? value : +value);
        } else {
          delete data[key];
        }
      } else {
        try {
          data[key] = JSON.parse(values);
        } catch (e) {}
      }
    });

    return data;
  }

  private init({closeEvent, config}: ToggleEvent) {
    this.enableMenu();
    this.closeEvent = (data) => closeEvent(data);

    if (!this.currentConfig || config.resetForm) {
      this.currentConfig = config;
      this.prepareFormConfig(config);
      this.createForm(config.data);
    }
  }

  private createForm(data: FilterData = {}) {
    this.addTitleField();
    this.formBuilderService.showForm(this.formElement.nativeElement, this.formConfig, data);
  }

  private enableMenu() {
    this.menuController.enable(true, this.menuId);
    this.menuController.toggle(this.menuId);
  }

  private prepareFormConfig(config: FilterMenuFormConfig) {
    this.initFormFields(config);
    this.defineAvailableFields(config);
  }

  private initFormFields(config: FilterMenuFormConfig) {
    this.currentFields = this.createFieldBy(config);
    this.formConfig.fields = [];

    this.filterField.inputs.selectedItems = [];
    this.filterField.inputs.options = cloneDeep(filter(this.currentFields, 'canDelete'));
  }

  private createFieldBy(config: FilterMenuFormConfig): FormField[] {
    return filter(map(config.fields, (field) => {
      if (isString(field)) {
        field = this.getFieldByType(field, config);
      }

      if (field) {
        if (config.extendedFieldConfig?.[field.name]) {
          const overwriteMerge = (destinationArray, sourceArray, options) => sourceArray;
          field = merge(field, config.extendedFieldConfig?.[field.name], { arrayMerge: overwriteMerge });
        }
        field.break = true;

        if (field.canDelete) {
          field.onRemoveField = (field) => {
            const componentRef: SelectFiltersFormComponent = <SelectFiltersFormComponent>this.filterField.componentRef;
            componentRef.selectedItems = filter(componentRef.selectedItems, (filter) => filter !== field.name);

            componentRef.ngOnInit();
          };
        }
      }

      return field;
    }));
  }

  private defineAvailableFields(config: FilterMenuFormConfig): void {
    const isFilterFieldAvailable = some(this.currentFields, 'canDelete');

    each(this.currentFields, (field) => {
      const isMandatoryField = !field.canDelete;
      const isFieldDataDefined = !isUndefined(config.data[field.name]);
      const canShowOptionalField = config.showOptionalFieldsWhileInit && !isMandatoryField;

      if (isMandatoryField || isFieldDataDefined || canShowOptionalField) {
        this.formConfig.fields.push(field);

        if (field.canDelete) {
          this.filterField.inputs.selectedItems.push(field.name);
        }
      }
    });

    if (isFilterFieldAvailable) {
      this.formConfig.fields.push(this.filterField);
    }
  }

  private addTitleField() {
    const filterTitle = {
      title: 'SHARED.Filter_Title',
      type: 'subtitle',
      containerClass: 'page-description',
    };

    const position = filter(this.formConfig.fields, ['canDelete', false]).length;
    this.formConfig.fields.splice(position, 0, filterTitle);
  }

  private getFieldByType(type: FilterMenuFormField, config: FilterMenuFormConfig): FormField {
    const fieldConfig = {
      config,
      formConfig: this.formConfig,
      getFieldsHandler: () => {
        const currentFields: string[] = map(this.formConfig.fields, 'name');
        return this.filterCurrentFields(currentFields);
      }
    };

    return this.filterMenuService.getFieldByType(type, fieldConfig);
  }

  private filterCurrentFields(currentFields: string[]): FormField[] {
    return filter(this.currentFields, (field: FormField) => includes(currentFields, field.name) || !field.canDelete);
  }
}
