import { Location } from '@angular/common';
import { ActivatedRoute } from '@angular/router';
import { ElementRef, Injectable, Injector } from '@angular/core';

import { awaitHandler } from '@utils/awaitHandler';
import { IObjectStringKeyMap } from '@shared/models';
import { BaseDetailsPage } from '@modules/management/pages/details/abstractDetails/abstractBaseDetails.page';
import {
  AccountsService,
  FormBuilderService,
  FormField,
  FormFieldMedia,
  LoadingService,
  ObjectItem,
  ObjectsService,
  PopoverService,
  SettingsService,
  UserdataService,
  UtilsService
} from '@services';

import { TranslateService } from '@ngx-translate/core';
import { each, find, get, isEmpty, sortBy } from 'lodash';

export enum ContentBaseField {
  Description = 'description',
  ContentObject = 'contentObject',
  Revision = 'revision',
  Tags = 'tags',
  Resources = 'users'
}

@Injectable()
export abstract class AbstractContentBasePage extends BaseDetailsPage {
  public objectData: ObjectItem;

  protected accountsService: AccountsService = this.injector.get(AccountsService);
  protected utils: UtilsService = this.injector.get(UtilsService);
  protected objectsService: ObjectsService = this.injector.get(ObjectsService);
  protected settingsService: SettingsService = this.injector.get(SettingsService);
  protected userdataService: UserdataService = this.injector.get(UserdataService);
  protected sharedFields = {
    [ContentBaseField.Description]: {
      inputtype: 'verbatim',
      required: true,
      name: 'description',
      title: this.translate.instant('MGMT_DETAILS.Title'),
      type: 'text',
      size: 20
    },
    [ContentBaseField.ContentObject]: {
      required: true,
      name: 'contentObject',
      title: this.translate.instant('CONTENT.Module_Name'),
      type: 'media',
      extensions: ['image', 'pdf', 'audio', 'video', 'other'],
      multiple: false,
      addButton: this.translate.instant('SHARED.Upload_Image'),
      updateButton: this.translate.instant('SHARED.Replace_Image'),
      imageSize: '150px',
      hideFieldsOnValue: {
        select: [
          {
            hideClasses: ['translation-object'],
            valueFunction: (val) => !val
          }
        ]
      }
    },
    [ContentBaseField.Revision]: {
      required: true,
      inputtype: 'verbatim',
      name: 'revision',
      title: this.translate.instant('CONTENT.Revision'),
      type: 'text',
      size: 20
    },
    [ContentBaseField.Tags]: {
      title: this.translate.instant('SHARED.Tags'),
      name: 'tags',
      type: 'selectmenu',
      multiple: true,
      tags: true,
      options: sortBy(this.settingsService.customTags.data, 'tag'),
      canClear: false,
      valueProperty: 'tagID',
      func: (item: any) => item.tag,
      placeholder: this.translate.instant('SHARED.Add_Tags')
    },
    [ContentBaseField.Resources]: {
        title: this.translate.instant('MGMT_DETAILS.Go_Tos'),
        type: 'selectmenu',
        name: 'users',
        multiple: true,
        valueProperty: 'userID',
        placeholder: '',
        options: this.accountsService.getUserlist(this.userdataService.locations),
        test(ref) {
          if (ref?.disabledAt || !ref?.active) {
            return false;
          } else {
            if (ref.hasOwnProperty('type') && (ref.type === 'dedicated' || ref.type === 'shared')) {
              return true;
            } else {
              return false;
            }
          }
        },
        func: (ref) => this.accountsService.fullUserName(ref.userID)
      },
  };

  protected constructor(protected injector: Injector) {
    super(
      injector.get(ActivatedRoute),
      injector.get(FormBuilderService),
      injector.get(ElementRef),
      injector.get(PopoverService),
      injector.get(Location),
      injector.get(LoadingService),
      injector.get(TranslateService),
    );
  }

  protected async prepareDataAsync(): Promise<any> {
    const customTagsHandler: Promise<any> = isEmpty(this.settingsService.customTags.data) ?
      this.settingsService.getCustomTags() : Promise.resolve();

    await this.loadingService.enable();
    await awaitHandler(Promise.all([customTagsHandler]));
    this.loadingService.disable();

    const tagField: FormField = find(this.formConfig.fields, {name: 'tags'});
    if (tagField) {
      tagField.options = sortBy(this.settingsService.customTags.data, 'tag');
    }
    const resourcesField: FormField = find(this.formConfig.fields, {name: 'users'});
    if (resourcesField) {
      resourcesField.options = this.accountsService.accounts.data;
    }
  }

  protected hideTranslationObjects() {
    const selector = `#${this.formConfig.prefix}translation_contentObject_container`;
    this.formBuilderService.setElementVisibilityBy(this.elementRef.nativeElement, selector, false);
  }

  protected decodeReviewFields(objectItem: ObjectItem) {
    each(objectItem?.attributes?.reviewInfo, (value, name) => {
      objectItem[name] = isNaN(+value) ? value : +value;
    });
  }

  protected decodeTranslationsObjects() {
    each(get(this.objectData, 'translations.objects'), (objectTranslation: IObjectStringKeyMap<any>) => {
      if (objectTranslation.language) {
        this.objectData[`translation_contentObject_${objectTranslation.language}`] = objectTranslation.value;
      }
    });
  }

  protected handleUploading(mediaConfig: FormFieldMedia) {
    const onSuccessHandler = mediaConfig?.events?.onSuccess && mediaConfig.events.onSuccess.bind(this);
    const onErrorHandler = mediaConfig?.events?.onError && mediaConfig.events.onError.bind(this);
    return new Promise((resolve) => {
      mediaConfig.events.onSuccess = (data) => {
        onSuccessHandler && onSuccessHandler(data);
        resolve(data);
      };

      mediaConfig.events.onError = () => {
        onErrorHandler && onErrorHandler();
        resolve(null);
      };
    });
  }

  protected async savePendingAttachments(formData?: IObjectStringKeyMap<any>) {
    await awaitHandler(this.savePendingObjects(formData));
    await awaitHandler(this.savePendingTranslatedObjects());
  }

  protected savePendingTranslatedObjects() {
    const data = this.getData();
    const mediaField = find(this.formConfig.fields, {type: 'mediaTranslations'});
    const requestHandlers: Promise<any>[] = [];

    mediaField?.mediaConfig?.startUpload && mediaField.mediaConfig.startUpload();

    each(data?.translations?.objects, (object) => {
      const languageField = find(mediaField?.mediaConfig?.languageFieldsReference, (field) => {
        return field?.mediaConfig?.options?.formData?.language === object.language;
      });
      const imageInstance = languageField?.mediaConfig?.imageInstance;
      const fileIsReplaced = (imageInstance?.selectedFiles === 0 && imageInstance?.fileCounter === 1) || imageInstance?.fileCounter > 1;

      if (fileIsReplaced && object?.value) {
        requestHandlers.push(this.objectsService.removeObject(object.value));
      }
    });

    each(mediaField?.mediaConfig?.languageFieldsReference, (field: FormField) => {
      if (field?.mediaConfig?.imageInstance?.selectedFiles) {
        const mediaConfig = field?.mediaConfig;
        requestHandlers.push(this.handleUploading(mediaConfig));
      }
    });

    return Promise.all(requestHandlers);
  }

  protected savePendingObjects(formData?: IObjectStringKeyMap<any>) {
    const mediaField = find(this.formConfig.fields, {type: 'media'});
    const requestHandlers: Promise<any>[] = [];

    if (formData?.revision) {
      mediaField.mediaConfig.options.relatedData.revision = formData.revision;
    }

    if (formData?.objectID) {
      mediaField.mediaConfig.options.relatedData.updates = formData.objectID;
    }

    if (mediaField?.mediaConfig?.imageInstance?.selectedFiles) {
      const mediaConfig = mediaField?.mediaConfig;
      requestHandlers.push(this.handleUploading(mediaConfig));
      mediaConfig?.startUpload && mediaField.mediaConfig.startUpload();
    }

    return Promise.all(requestHandlers);
  }
}
