import { Injectable } from '@angular/core';

import { IObjectStringKeyMap } from '@shared/models';
import { Observation, ObservationTypes } from '@services/observations/observation.interfaces';
import {
  Asset,
  HealthEvent,
  IPropertyMeta,
  IPropertyValue,
  ReportAsset
} from '@services/assets/asset.interfaces';
import { AssetStateHistoryEntity } from '@modules/observation/pages/asset-status/pages/detail/model/asset-state.interfaces';
import {
  AssetByHealthEvent,
  AssetByObservation,
  AssetByPropertyEvent,
  AssetByPropertyValue
} from '@modules/reporting/models/types/asset/model';
import {
  AccountsService,
  AssetsService,
  FoldersDataService,
  ObjectsService,
  ObservationService,
  PropertyService,
  TeamsService,
  UserService,
  UtilsService
} from '@services';

import { TranslateService } from '@ngx-translate/core';
import {
  cloneDeep,
  each,
  filter,
  find,
  flatten,
  get,
  has,
  includes,
  intersection,
  isNumber,
  isUndefined,
  join,
  keys,
  map,
  omit,
  some,
  sumBy,
  values
} from 'lodash';

@Injectable({
  providedIn: 'root'
})
export class AssetSummaryModelService {
  private columnOptions = [
    {
      id: 'observationsAdded',
      description: this.translate.instant('REPORTING.Observations_Added'),
      label: this.translate.instant('REPORTING.Observations_Added'),
      units: this.translate.instant('REPORTING.Observations_Added'),
      cellType: 'number',
      showWhen: true,
      func: (collection: Asset[]) => {
        const asset: Asset = collection?.[0];
        if (asset) {
          return filter(values(asset?.observationEvents), { activity: 'created' }).length;
        } else {
          return 0;
        }
      }
    },
    {
      id: 'observationsFixed',
      description: this.translate.instant('REPORTING.Observations_Fixed'),
      label: this.translate.instant('REPORTING.Observations_Fixed'),
      units: this.translate.instant('REPORTING.Observations_Fixed'),
      cellType: 'number',
      showWhen: true,
      func: (collection: Asset[]) => {
        const asset: Asset = collection?.[0];
        if (asset) {
          return filter(values(asset?.observationEvents), { activity: 'fixed' }).length;
        } else {
          return 0;
        }
      }
    },
    {
      id: 'observationsClosed',
      description: this.translate.instant('REPORTING.Observations_Closed'),
      label: this.translate.instant('REPORTING.Observations_Closed'),
      units: this.translate.instant('REPORTING.Observations_Closed'),
      cellType: 'number',
      showWhen: true,
      func: (collection: Asset[]) => {
        const asset: Asset = collection?.[0];
        if (asset) {
          return filter(values(asset?.observationEvents), { activity: 'resolved' }).length;
        } else {
          return 0;
        }
      }
    },
    {
      id: 'averagePropertyValue',
      description: this.translate.instant('REPORTING.Average_Property_Value'),
      label: this.translate.instant('REPORTING.Average_Property_Value'),
      units: this.translate.instant('REPORTING.Average_Property_Value'),
      cellType: 'number',
      showWhen: true,
      func: (collection: Asset[]) => {
        const asset: Asset = collection?.[0];
        const valueByProperty: IObjectStringKeyMap<number> = {};

        each(asset?.propertyEvents, (propertyEvents, propertyID) => {
          const propertyValues: number[] = [];

          each(propertyEvents, (event) => {
            const eventValue = this.getEventNumberValue(event, +propertyID);

            if (!isUndefined(eventValue)) {
              propertyValues.push(eventValue);
            }
          });

          if (propertyValues.length) {
            valueByProperty[propertyID] = +(sumBy(propertyValues) / propertyValues.length).toFixed(1);
          }
        });

        const propertiesValues: number[] = values(valueByProperty);

        if (propertiesValues.length) {
          return +(sumBy(propertiesValues) / propertiesValues.length).toFixed(1);
        } else {
          return 0;
        }
      }
    },
    {
      id: 'totalTimeUnavailable',
      description: this.translate.instant('PROPERTY.Total_Time_Unavailable'),
      label: this.translate.instant('PROPERTY.Total_Time_Unavailable'),
      units: this.translate.instant('PROPERTY.Total_Time_Unavailable'),
      cellType: 'number',
      showWhen: true,
      func: (collection: ReportAsset[]) => {
        const asset = collection?.[0];
        return asset ? this.getTotalHealthTime(asset.healthEntities, 0) : 0;
      }
    },
    {
      id: 'totalTimeStrained',
      description: this.translate.instant('PROPERTY.Total_Time_Strained'),
      label: this.translate.instant('PROPERTY.Total_Time_Strained'),
      units: this.translate.instant('PROPERTY.Total_Time_Strained'),
      cellType: 'number',
      showWhen: true,
      func: (collection: ReportAsset[]) => {
        const asset = collection?.[0];
        return asset ? this.getTotalHealthTime(asset.healthEntities, 1) : 0;
      }
    },
    {
      id: 'totalTimeAvailable',
      description: this.translate.instant('PROPERTY.Total_Time_Available'),
      label: this.translate.instant('PROPERTY.Total_Time_Available'),
      units: this.translate.instant('PROPERTY.Total_Time_Available'),
      cellType: 'number',
      showWhen: true,
      func: (collection: ReportAsset[]) => {
        const asset = collection?.[0];
        return asset ? this.getTotalHealthTime(asset.healthEntities, 2) : 0;
      }
    },
    {
      id: 'averageTimeUnavailable',
      description: this.translate.instant('PROPERTY.Average_Time_Unavailable'),
      label: this.translate.instant('PROPERTY.Average_Time_Unavailable'),
      units: this.translate.instant('PROPERTY.Average_Time_Unavailable'),
      cellType: 'number',
      showWhen: true,
      func: (collection: ReportAsset[]) => {
        const asset = collection?.[0];
        return asset ? this.getAverageHealthTime(asset.healthEntities, 0) : 0;
      }
    },
    {
      id: 'averageTimeStrained',
      description: this.translate.instant('PROPERTY.Average_Time_Strained'),
      label: this.translate.instant('PROPERTY.Average_Time_Strained'),
      units: this.translate.instant('PROPERTY.Average_Time_Strained'),
      cellType: 'number',
      showWhen: true,
      func: (collection: ReportAsset[]) => {
        const asset = collection?.[0];
        return asset ? this.getAverageHealthTime(asset.healthEntities, 1) : 0;
      }
    },
    {
      id: 'averageTimeAvailable',
      description: this.translate.instant('PROPERTY.Average_Time_Available'),
      label: this.translate.instant('PROPERTY.Average_Time_Available'),
      units: this.translate.instant('PROPERTY.Average_Time_Available'),
      cellType: 'number',
      showWhen: true,
      func: (collection: ReportAsset[]) => {
        const asset = collection?.[0];
        return asset ? this.getAverageHealthTime(asset.healthEntities, 2) : 0;
      }
    }
  ];
  private fieldOptions = [
    {
      id: 'assetID',
      description: this.translate.instant('MGMT_LIST.Asset'),
      label: this.translate.instant('MGMT_LIST.Asset'),
      fieldName: 'assetID',
      fieldType: 'number',
      fieldFunc: (assetID: number) => {
        let assetName = this.assetService.getAssetById(assetID)?.[0]?.name;

        if (!assetName) {
          assetID = 0;
          assetName = this.translate.instant('DASHPAGES.NA');
        }

        return [assetID, assetName];
      }
    },
    {
      id: 'assetFolder',
      description: this.translate.instant('MGMT_DETAILS.Asset_Folder'),
      label: this.translate.instant('MGMT_DETAILS.Asset_Folder'),
      fieldName: 'assetID',
      fieldType: 'number',
      fieldFunc: (assetID: number) => {
        const folderID = this.assetService.getAssetById(assetID)?.[0]?.folderID;
        const folderName = this.foldersDataService.getFolderByID(folderID, false)?.title;

        return [folderID || 0, folderName || this.translate.instant('DASHPAGES.NA')];
      }
    },
    // { TODO these fields will be moved to a new content report
    //   id: 'content',
    //   description: this.translate.instant('LAYOUT.Content'),
    //   label: this.translate.instant('LAYOUT.Content'),
    //   fieldName: 'contentItems',
    //   fieldType: 'array',
    //   fieldFunc: (contentID: number) => {
    //     const contentName = this.objectsService.getCachedObjectById(contentID)?.description;
    //     return [contentID, contentName || this.translate.instant('DASHPAGES.NA')];
    //   }
    // },
    // {
    //   id: 'contentFolder',
    //   description: this.translate.instant('CONTENT.Content_Folder'),
    //   label: this.translate.instant('CONTENT.Content_Folder'),
    //   fieldName: 'contentItems',
    //   fieldType: 'array',
    //   fieldFunc: (contentID: number) => {
    //     const contentObject = this.objectsService.getCachedObjectById(contentID);
    //
    //     if (contentObject?.folderID) {
    //       const folderName = this.foldersDataService.getFolderByID(contentObject.folderID, false)?.title
    //
    //       if (folderName) {
    //         return [contentObject.folderID, folderName];
    //       }
    //     }
    //     return [0, this.translate.instant('DASHPAGES.NA')];
    //   }
    // },
    {
      id: 'property',
      description: this.translate.instant('SHARED.Property'),
      label: this.translate.instant('SHARED.Property'),
      fieldName: 'properties',
      fieldType: 'array',
      fieldFunc: (property: IPropertyMeta) => {
        const propertyName = this.propertyService.getPropertyById(property?.propertyID)?.title;
        return [property?.propertyID || 0, propertyName || this.translate.instant('DASHPAGES.NA')];
      }
    },
    {
      id: 'propertyFolder',
      description: this.translate.instant('PROPERTY.Property_Folder'),
      label: this.translate.instant('PROPERTY.Property_Folder'),
      fieldName: 'properties',
      fieldType: 'array',
      fieldFunc: (property: IPropertyMeta) => {
        const folderID = this.propertyService.getPropertyById(property?.propertyID)?.folderID;
        const folder = this.foldersDataService.getFolderByID(folderID);
        return [folder || 0, folder?.title || this.translate.instant('DASHPAGES.NA')];
      }
    },
    {
      id: 'location',
      description: this.translate.instant('SHARED.Location'),
      label: this.translate.instant('SHARED.Location'),
      fieldName: 'location',
      fieldType: 'number',
      fieldFunc: (locationId: number) => {
        let locationName: string = get(this.userService.findLocation(locationId), 'name');

        if (!locationName) {
          locationId = 0;
          locationName = this.translate.instant('DASHPAGES.NA');
        }
        return [locationId, locationName];
      }
    },
    {
      id: 'zone',
      description: this.translate.instant('SHARED.Zone'),
      label: this.translate.instant('SHARED.Zone'),
      fieldName: 'zone',
      fieldType: 'integer',
      fieldFunc: (zone: number, asset: Asset) => {
        const zsig = asset.location + ':' + asset.zone;
        const locRef = this.userService.findLocation(asset.location);
        const zoneRef = this.userService.findAnyZone(locRef, asset.zone);
        if (zoneRef) {
          return [zsig, `${locRef.name}: ${zoneRef.name}`];
        } else {
          if (locRef) {
            return [zsig, locRef.name + '/Site-wide'];
          } else {
            return [zsig, 'Site-wide'];
          }
        }
      }
    },
    {
      id: 'resource',
      label: this.translate.instant('MGMT_DETAILS.Go_Tos'),
      description: this.translate.instant('MGMT_DETAILS.Go_Tos'),
      fieldName: 'users',
      fieldType: 'array',
      fieldFunc: (userID: number) => {
        return [userID, this.userService.getFullname(userID)];
      }
    },
    {
      id: 'health',
      label: this.translate.instant('SHARED.Status'),
      description: this.translate.instant('SHARED.Status'),
      fieldName: 'health',
      fieldType: 'integer',
      fieldFunc: (health: number) => {
        return [health, this.assetService.assetState(health)];
      }
    }
  ];

  private tableColumns = {
    observation: [
      {
        id: 'observation',
        label: this.translate.instant('SHARED.Response_ID'),
        func: (observation: Observation) => {
          return observation?.observationID || this.translate.instant('SHARED.None');
        }
      },
      {
        id: 'observation',
        label: this.translate.instant('SHARED.Type'),
        func: (observation: Observation) => {
          return this.observationService.getTitle(observation.type as ObservationTypes);
        }
      },
      {
        id: 'observation',
        label: this.translate.instant('SHARED.SUBTYPE'),
        func: (observation: Observation) => {
          if (observation.subtype) {
            return this.observationService.getTitle(observation.type as ObservationTypes, observation.subtype);
          } else {
            return this.translate.instant('SHARED.None');
          }
        }
      },
      {
        id: 'observation',
        label: this.translate.instant('REPORTING.CREATOR'),
        func: (observation: Observation) => {
          return this.accountService.fullname(observation.userID);
        }
      },
      {
        id: 'observation',
        label: this.translate.instant('REPORTING.CREATED'),
        func: (observation: Observation) => {
          return this.utils.dateTimeFormat(observation.created, null, true);
        }
      },
      {
        id: 'observation',
        label: this.translate.instant('SHARED.OWNER'),
        func: (observation: Observation) => {
          return this.accountService.fullname(observation.ownerID);
        }
      },
      {
        id: 'observation',
        label: this.translate.instant('SHARED.TEAM'),
        func: (observation: Observation) => {
          return this.teamsService.teamNameByID(observation.groupID);
        }
      }
    ],
    propertyValue: [
      {
        id: 'propertyValue',
        label: this.translate.instant('SHARED.USER'),
        func: (propertyValue: AssetByPropertyValue) => {
          return this.accountService.fullname(propertyValue?.value?.userID);
        }
      },
      {
        id: 'propertyValue',
        label: this.translate.instant('MGMT_DETAILS.Log_Activity'),
        func: (propertyValue: AssetByPropertyValue) => {
          return this.propertyService.getPropertyActivity(propertyValue?.value?.activity);
        }
      },
      {
        id: 'propertyValue',
        label: this.translate.instant('PROPERTY.Property_value'),
        func: (propertyValue: AssetByPropertyValue) => {
          return this.propertyService.getPropertyValue(propertyValue.value, propertyValue.id);
        }
      }
    ],
    totalTime: [
      {
        id: 'healthEvent',
        label: this.translate.instant('SHARED.When'),
        func: (event: HealthEvent) => {
          return this.utils.dateTimeFormat(event.time, null, true);
        }
      },
      {
        id: 'healthEvent',
        label: this.translate.instant('PROPERTY.Event_Data'),
        func: (event: HealthEvent) => {
          return join(map(omit(event, 'time'), (value, property) => {
            return `${property}: ${value}`;
          }), ', ');
        }
      }
    ]
  }

  constructor(
    private translate: TranslateService,
    private userService: UserService,
    private assetService: AssetsService,
    private utils: UtilsService,
    private foldersDataService: FoldersDataService,
    private objectsService: ObjectsService,
    private propertyService: PropertyService,
    private observationService: ObservationService,
    private accountService: AccountsService,
    private teamsService: TeamsService
  ) {}

  public getColumns() {
    return filter(cloneDeep(this.columnOptions), (opt) => opt.showWhen);
  }

  public getFieldOptions() {
    return this.fieldOptions;
  }

  public outOfRange(opts, asset: Asset, theTime: number, interval): boolean {
    const testTime = this.utils.toMilliseconds(theTime);
    let start = this.utils.toMilliseconds(interval?.startTime ?? interval?.start);
    let end = this.utils.toMilliseconds(interval?.endTime ?? interval?.end);
    if (!start || !end) {
      return false;
    }
    if (opts?.locationTime) {
      if (asset.location) {
        const locRef = this.userService.getLocation(asset.location);
        if (locRef?.timezone) {
          if (!interval?.timezones) {
            interval.timezones = {};
          }

          if (!interval.timezones[locRef.timezone]) {
            const delta = this.utils.tzDelta(locRef.timezone, start);
            interval.timezones[locRef.timezone] = {
              start: start - delta,
              end: end - delta
            };
          }
          start = interval.timezones[locRef.timezone].start;
          end = interval.timezones[locRef.timezone].end;
        }
      }
    }

    return testTime < start || testTime > end;
  }

  public isValid(asset: Asset, selectors): any {
    // location filter
    if (selectors.locations && selectors.locations.length) {
      if (!includes(selectors.locations, asset.location)) {
        return false;
      } else {
        let zonesIds: any = [0];

        each(selectors.locations, (locationId: number) => {
          const location: any = find(this.userService.locations.data, <any>{locationID: locationId});
          const currentZonesIds: any = [];
          each(location.zones, zone => {
            if (!zone.disabledAt) {
              currentZonesIds.push(zone.zoneID);
            }
            // now look for subzones within these zones
            each(zone.zones, subZone => {
              if (!subZone.disabledAt) {
                currentZonesIds.push(subZone.zoneID);
              }
            });
          });
          zonesIds = [...zonesIds, ...currentZonesIds];
        });

        if (!includes(zonesIds, asset.zone)) {
          return false;
        }
      }
    }

    // zone filter
    if (selectors.zones && selectors.zones.length) {
      const zoneId = asset.zone ? +asset.zone : `${asset.location}:${asset.zone}`;
      const zoneIds = map(selectors.zones, (zoneId: string | number) => {
        return isNaN(+zoneId) ? zoneId : +zoneId;
      });
      if (!includes(zoneIds, zoneId)) {
        return false;
      }
    }

    if (selectors?.resources?.length && asset?.users?.length) {
      if (intersection(selectors.resources, map(asset.users, Number)).length === 0) {
        return false;
      }
    }

    if (has(selectors, 'active')) {
      if (asset.active !== selectors.active) {
        return false
      }
    }

    if (selectors?.targetAssets?.length) {
      const isMatch = some(selectors?.targetAssets, (assetFolder) => {
        if (assetFolder.assetID) {
          return assetFolder.assetID === +asset.assetID;
        } else {
          return this.foldersDataService.hasIn(assetFolder.folderID, asset?.folderID, selectors.assetFolderTree);
        }
      });

      if (!isMatch) {
        return false;
      }
    }

    if (selectors?.targetContent?.length) {
      const folderIDs = filter(map(asset?.contentItems, (contentID) => {
        return this.objectsService.getCachedObjectById(+contentID)?.folderID;
      }));
      const isMatch = some(selectors?.targetContent, (contentFolder) => {
        if (contentFolder.itemID) {
          return includes(asset.contentItems, contentFolder.itemID);
        } else {
          return includes(folderIDs, contentFolder.folderID);
        }
      });

      if (!isMatch) {
        return false;
      }
    }

    if (selectors.statusValue?.length && has(asset, 'health')) {
      if (!includes(selectors.statusValue, asset.health)) {
        return false;
      }
    }

    return true;
  }

  public getEventNumberValue(event: IPropertyValue, propertyID: number): number {
    const result = this.propertyService.getPropertyValue(event, propertyID);

    if (isNumber(+result) && !isNaN(+result)) {
      return +result;
    }
  }

  public getAssetColumns(reportOptions: IObjectStringKeyMap<any>) {
    const columns = [];
    const fields = this.getFieldOptions();

    columns.push(find(fields, { id: reportOptions?.primary }));
    columns.push(find(fields, { id: reportOptions?.secondary }));

    if (this.isObservationGraph(reportOptions.graphSecondary)) {
      columns.push(...this.tableColumns.observation);
    } else if (reportOptions.graphSecondary === 'averagePropertyValue') {
      columns.push(...this.tableColumns.propertyValue);
    } else if (this.isTotalTimeGraph(reportOptions.graphSecondary)) {
      columns.push(...this.tableColumns.totalTime);
    }

    return map(filter(columns), (column) => {
      column.headerClass = 'table-header';

      if (!column.title && column.label) {
        column.title = column.label;
      }

      if (!column.func && column.fieldFunc) {
        column.func = (id, data) => column.fieldFunc(id || data[column.fieldName], data)?.[1]
      }

      return column;
    });
  }

  public getTableAssetDataByGraphType(assets: Asset[], options: IObjectStringKeyMap<any>) {
    const type = options?.graphSecondary;

    if (this.isObservationGraph(type)) {
      let assetsByObservation: AssetByObservation[] = [];
      const observationTypeMap = {
        observationsAdded: 'created',
        observationsFixed: 'fixed',
        observationsClosed: 'resolved'
      };

      each(assets, (asset) => {
        const observations = filter(map(filter(asset.observationEvents, { activity: observationTypeMap[type] }), (event) => {
          return this.observationService.getObservationById(event.observationID) as Observation;
        }));

        each(observations, (observation) => {
          assetsByObservation.push(Object.assign({}, asset, { observation }));
        });
      });

      return assetsByObservation;
    } else if (type === 'averagePropertyValue') {
      let assetsByEvent: AssetByPropertyEvent[] = [];
      each(assets, (asset) => {
        each(asset.propertyEvents, (events, propertyID) => {
          each(events, (event) => {
            assetsByEvent.push(Object.assign({}, asset, {
              propertyValue: {
                id: +propertyID,
                value: event
              }
            }));
          });
        });
      });

      return assetsByEvent;
    } else if(this.isTotalTimeGraph(type)) {
      let assetsByEvent: AssetByHealthEvent[] = [];

      each(assets, (asset) => {
        each(asset.healthEvents, (healthEvents) => {
          each(healthEvents, (healthEvent) => {
            const eventTime = healthEvent.time * 1000;
            if (eventTime >= options.startTime && eventTime <= options.endTime) {
              assetsByEvent.push(Object.assign({}, asset, { healthEvent }));
            }
          });
        });
      });

      return assetsByEvent;
    }

    return assets;
  }

  public isObservationGraph(type: string) {
    return includes(['observationsAdded', 'observationsFixed', 'observationsClosed'], type);
  }

  public isTotalTimeGraph(type: string) {
    return includes([
      'totalTimeAvailable',
      'totalTimeUnavailable',
      'totalTimeStrained',
      'averageTimeUnavailable',
      'averageTimeStrained',
      'averageTimeAvailable'
    ], type);
  }

  private getTotalHealthTime(healthEntities: AssetStateHistoryEntity[], health: number) {
    let duration = 0;

    each(healthEntities, (healthEntity) => {
      each(healthEntity.stateHistoryPeriods, (stateHistoryPeriod) => {
        const periods = filter(flatten([stateHistoryPeriod.period]));
        const targetPeriod = find(periods, { health });

        if (targetPeriod?.value) {
          duration += targetPeriod.value;
        }
      });
    });

    return duration;
  }

  private getAverageHealthTime(healthEntities: AssetStateHistoryEntity[], health: number) {
    const propertiesCount = keys(healthEntities)?.length || 1;
    return +(this.getTotalHealthTime(healthEntities, health) / propertiesCount).toFixed(1);
  }
}
