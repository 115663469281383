import { Injectable } from '@angular/core';

import { AssetByPropertyEvent } from '@modules/reporting/models/types/asset/model';
import { IPropertyEventValue, PropertyStyle } from '@services/property/property-model.interfaces';
import { AssetsService, FoldersDataService, PropertyService, UserService, UtilsService } from '@services';

import { TranslateService } from '@ngx-translate/core';
import { cloneDeep, filter, get, includes, isObject } from 'lodash';

@Injectable({
  providedIn: 'root'
})
export class AssetDetailModelService {
  private columnOptions = [
    {
      id: 'assetName',
      description: this.translate.instant('MGMT_DETAILS.Asset_Name'),
      label: this.translate.instant('MGMT_DETAILS.Asset_Name'),
      units: this.translate.instant('MGMT_DETAILS.Asset_Name'),
      cellType: 'string',
      showWhen: true,
      func: (asset: AssetByPropertyEvent) => {
        return asset?.name ?? this.translate.instant('SHARED.Unknown');
      }
    },
    {
      id: 'assetFolder',
      description: this.translate.instant('MGMT_DETAILS.Asset_Folder'),
      label: this.translate.instant('MGMT_DETAILS.Asset_Folder'),
      units: this.translate.instant('MGMT_DETAILS.Asset_Folder'),
      cellType: 'string',
      showWhen: true,
      func: (asset: AssetByPropertyEvent) => {
        const folderName = this.foldersDataService.getFolderByID(asset?.folderID, false)?.title;
        return folderName || this.translate.instant('DASHPAGES.NA');
      }
    },
    {
      id: 'fullAssetName',
      description: this.translate.instant('DASHPAGES.Asset_Full_Name'),
      label: this.translate.instant('DASHPAGES.Asset_Full_Name'),
      units: this.translate.instant('DASHPAGES.Asset_Full_Name'),
      cellType: 'string',
      showWhen: true,
      func: (asset: AssetByPropertyEvent) => {
        const folderPath = this.assetsService.folderPath(asset);
        return folderPath ? `<span class="folder-path">${folderPath}/ </span>${asset?.name}` : this.translate.instant('SHARED.Unknown');
      }
    },
    {
      id: 'eventTime',
      description: this.translate.instant('SHARED.Time'),
      label: this.translate.instant('SHARED.Time'),
      units: this.translate.instant('SHARED.Time'),
      cellType: 'string',
      showWhen: true,
      func: (asset: AssetByPropertyEvent) => {
        return this.utils.dateTimeFormat(asset?.propertyValue?.value?.time, null, true);
      }
    },
    {
      id: 'eventActivity',
      description: this.translate.instant('MGMT_DETAILS.Log_Activity'),
      label: this.translate.instant('MGMT_DETAILS.Log_Activity'),
      units: this.translate.instant('MGMT_DETAILS.Log_Activity'),
      cellType: 'string',
      showWhen: true,
      func: (asset: AssetByPropertyEvent) => {
        return this.propertyService.getPropertyActivity(asset?.propertyValue?.value?.activity);
      }
    },
    {
      id: 'fullPropertyName',
      description: this.translate.instant('PROPERTY.Property_Full_Name'),
      label: this.translate.instant('PROPERTY.Property_Full_Name'),
      units: this.translate.instant('PROPERTY.Property_Full_NameProperty_Full_Name'),
      cellType: 'string',
      showWhen: true,
      func: (asset: AssetByPropertyEvent) => {
        const property = this.propertyService.getPropertyById(asset.propertyValue.id);
        const folderPath = this.propertyService.folderPath(property);

        return folderPath ? `<span class="folder-path">${folderPath}/ </span>${property?.title}` : this.translate.instant('SHARED.Unknown');
      }
    },
    {
      id: 'propertyName',
      description: this.translate.instant('SHARED.Property_Name'),
      label: this.translate.instant('SHARED.Property_Name'),
      units: this.translate.instant('SHARED.Property_Name'),
      cellType: 'string',
      showWhen: true,
      func: (asset: AssetByPropertyEvent) => {
        const property = this.propertyService.getPropertyById(asset.propertyValue.id);
        return property.title ?? this.translate.instant('SHARED.Unknown');
      }
    },
    {
      id: 'propertyEventValue',
      description: this.translate.instant('PROPERTY.Property_Event_Value'),
      label: this.translate.instant('PROPERTY.Property_Event_Value'),
      units: this.translate.instant('PROPERTY.Property_Event_Value'),
      cellType: 'string',
      showWhen: true,
      func: (asset: AssetByPropertyEvent) => {
        return this.propertyService.getPropertyValue(asset.propertyValue.value, asset.propertyValue.id);
      }
    },
    {
      id: 'propertyEventSource',
      description: this.translate.instant('SHARED.Data_Source'),
      label: this.translate.instant('SHARED.Data_Source'),
      units: this.translate.instant('SHARED.Data_Source'),
      cellType: 'string',
      showWhen: true,
      func: (asset: AssetByPropertyEvent) => {
        return asset?.propertyValue?.value?.source ?? '';
      }
    },
    {
      id: 'propertyEventUser',
      description: this.translate.instant('SHARED.User'),
      label: this.translate.instant('SHARED.User'),
      units: this.translate.instant('SHARED.User'),
      cellType: 'string',
      showWhen: true,
      func: (asset: AssetByPropertyEvent) => {
        return this.userService.getFullname(asset?.propertyValue?.value?.userID);
      }
    },
    {
      id: 'propertyEventHealthValue',
      description: this.translate.instant('SHARED.Status'),
      label: this.translate.instant('SHARED.Status'),
      units: this.translate.instant('SHARED.Status'),
      cellType: 'string',
      showWhen: true,
      func: (asset: AssetByPropertyEvent) => {
        const propertyID = asset?.propertyValue?.id;
        const valueObject = asset.propertyValue?.value;
        const eventValue = valueObject?.value;

        if (this.propertyService.isHealthPropertyValue(valueObject, propertyID)) {
          return this.propertyService.getTableCellHealth(eventValue as string, asset, propertyID) || '';
        }

        return '';
      }
    },
  ];

  private fieldOptions = [
    {
      id: 'zone',
      description: this.translate.instant('SHARED.Zone'),
      label: this.translate.instant('SHARED.Zone'),
      fieldName: 'zoneID',
      fieldType: 'array',
      fieldFunc: (val, ref) => {
        // find the zone in the location
        const zsig = ref.locationID + ':' + val;
        const locRef = this.userService.findLocation(ref.locationID);
        const zoneRef = this.userService.findAnyZone(locRef, val);
        if (zoneRef) {
          return [zsig, `${locRef.name}: ${zoneRef.name}`];
        } else {
          if (locRef) {
            return [zsig, locRef.name + '/Site-wide'];
          } else {
            return [zsig, 'Site-wide'];
          }
        }
      }
    },
    {
      id: 'locationID',
      description: this.translate.instant('SHARED.Location'),
      label: 'Location',
      fieldName: 'locationID',
      fieldType: 'number',
      fieldFunc: (locationId: number) => {
        let locationName: string = get(this.userService.findLocation(locationId), 'name');
        if (!locationName) {
          locationId = 0;
          locationName = this.translate.instant('DASHPAGES.NA');
        }
        return [locationId, locationName];
      }
    }
  ];

  constructor(
    private translate: TranslateService,
    private userService: UserService,
    private assetsService: AssetsService,
    private foldersDataService: FoldersDataService,
    private utils: UtilsService,
    private propertyService: PropertyService
  ) {}

  public getColumns() {
    return filter(cloneDeep(this.columnOptions), (opt) => opt.showWhen);
  }

  public getFieldOptions() {
    return this.fieldOptions;
  }
}
