import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';

import { SubscriberService } from '@services';
import { CustomFormComponent } from '@services/formBuilder/abstract-custom-form-field';

import { split } from 'lodash';
import { Options } from 'select2';
import { parse } from "tinyduration";
import { NGXLogger } from 'ngx-logger';
import { TranslateService } from '@ngx-translate/core';

@Component({
  selector: 'app-time-picker',
  templateUrl: './time-picker.component.html',
  styleUrls: ['./time-picker.component.scss'],
})
export class TimePickerComponent extends CustomFormComponent implements OnInit {
  @Output() onChanged: EventEmitter<any> = new EventEmitter<any>();

  @Input() duration8601 = '';
  @Input() time = '00:00';
  @Input() isDuration = false;
  @Input() min = '00:00';
  @Input() max = '23:59';
  @Input() label = this.translate.instant('SHARED.Time');
  @Input() tooltip = '';
  @Input() noLabel = false;
  @Input() required = false;
  @Input() longDuration = false;

  public format = 'h:mm A';
  public instanceID: string;
  public months = this.getArrayOfOptions(13, false);
  public weeks = this.getArrayOfOptions(53, false);
  public days = this.getArrayOfOptions(8, false);
  public hours = this.getArrayOfOptions(24);
  public minutes = this.getArrayOfOptions(60);

  public selectedMonths = '0';
  public selectedWeeks = '0';
  public selectedDays = '0';
  public selectedHour = '0';
  public selectedMinute = '0';

  public selectOptions: Options = {
    width: '4em',
    minimumResultsForSearch: 100,
    // sorter: null
  };

  constructor(
    private subscriberService: SubscriberService,
    private logger: NGXLogger,
    private translate: TranslateService
  ) {
    super();
  }

  ngOnInit() {
    if (this.longDuration) {
      this.hours = this.getArrayOfOptions(24, false);
      this.minutes = this.getArrayOfOptions(60, false);
    }
    this.initTimePicker();
  }

  public emit8601(): void {
    if (this.longDuration) {
      const theTime = `P${this.selectedMonths}M${this.selectedWeeks}W${this.selectedDays}DT${this.selectedHour}H${this.selectedMinute}M`;
      this.formValue = theTime;
    } else {
      this.formValue = `${this.selectedHour}:${this.selectedMinute}`;
    }
  }

  public emitChanges(theTime: string): void {
    this.formValue = theTime;
  }

  public onChangedMonths(event) {
    this.selectedMonths = event.value;
    this.emit8601();
  }

  public onChangedWeeks(event) {
    this.selectedWeeks = event.value;
    this.emit8601();
  }

  public onChangedDays(event) {
    this.selectedDays = event.value;
    this.emit8601();
  }

  public onChangedHour(event) {
    this.selectedHour = event.value;
    this.emit8601();
  }

  public onChangedMinute(event) {
    this.selectedMinute = event.value;
    this.emit8601();
  }

  public onChangedValue(event) {
    this.emitChanges(event.target.value);
  }

  private initTimePicker(): void {
    if (!this.isDuration) {
      // use the subscriber preference?
      const subConfig = this.subscriberService.getUnits('time');
      if (subConfig === '24h') {
        this.format = 'HH:mm';
      } else {
        this.format = 'h:mm A';
      }
    }
    const initialValue = this.formValue ?? '' ;
    if (initialValue !== '') {
      if (this.longDuration) {
        const myDuration = parse(this.formValue);
        this.selectedMonths = myDuration.months.toString();
        this.selectedWeeks = myDuration.weeks.toString();
        this.selectedDays = myDuration.days.toString();
        this.selectedHour = myDuration.hours.toString();
        this.selectedMinute = myDuration.minutes.toString();
      } else {
      // parse the initial value
      this.time = initialValue.toString();
      if (this.time.includes(':')) {
        if (this.time.includes('"')) {
          this.time = this.time.replace(/"/g, '');
        }
        const t = split(this.time, ':');
        this.selectedHour = t[0];
        this.selectedMinute = t[1];
      }
      }
    } else {
      // force initialization
      this.emit8601();
    }
  }

  private getArrayOfOptions(length: number, zeroFill: boolean = true) {
    return Array.from({ length }, (value, index) => {
      if (zeroFill) {
        return ( index > 9 ? { value: index, description: index} : { value: `0${index}`, description:`0${index}` } ) ;
      } else {
        // no leading zero
        return ( index > 9 ? { value: index, description: index} : { value: index, description:`0${index}` } ) ;
      }
    });
  }
}
