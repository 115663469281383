<div class="time-picker-field">
  <label [title]="tooltip">
    {{ label | translate }}<span *ngIf="required" class="required">*</span>
  </label>
  <!-- <ion-datetime *ngIf="isDuration" [value]="time" [min]="min" [max]="max" [displayFormat]="format"
    (ionChange)="onChangedValue($event)">
  </ion-datetime> -->
  <ng-container *ngIf="isDuration">
    <div class="time-select">
      <div class="select-row" *ngIf="longDuration">
        <select2 [cssImport]="false" [options]="selectOptions" [value]="selectedMonths" (valueChanged)="onChangedMonths($event)">
          <option *ngFor="let month of months" [value]="month.value">{{ month.description }}</option>
        </select2> <label>{{ 'SHARED.Months' | translate }}</label>
        <select2 [cssImport]="false" [options]="selectOptions" [value]="selectedWeeks" (valueChanged)="onChangedWeeks($event)">
          <option *ngFor="let week of weeks" [value]="week.value">{{ week.description }}</option>
        </select2> <label>{{ 'SHARED.Weeks' | translate }}</label>
        <select2 [cssImport]="false" [options]="selectOptions" [value]="selectedDays" (valueChanged)="onChangedDays($event)">
          <option *ngFor="let day of days" [value]="day.value">{{ day.description }}</option>
        </select2> <label>{{ 'SHARED.Days' | translate }}</label>
      </div>

      <div class="select-row">
        <select2 [cssImport]="false" [options]="selectOptions" [value]="selectedHour" (valueChanged)="onChangedHour($event)">
          <option *ngFor="let hour of hours" [value]="hour.value">{{ hour.description }}</option>
        </select2>
        <span>:</span>
        <select2 [cssImport]="false" [options]="selectOptions" [value]="selectedMinute" (valueChanged)="onChangedMinute($event)">
          <option *ngFor="let minute of minutes" [value]="minute.value">{{ minute.description }}</option>
        </select2>
      </div>
    </div>
  </ng-container>

  <input *ngIf="!isDuration" type="time" [value]="time" [min]="min" [max]="max" (change)="onChangedValue($event)" />
</div>
