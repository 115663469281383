import { IObjectNumberKeyMap, IObjectStringKeyMap } from '@shared/models';
import { Module } from '@services/subscriber/subscriber.service';
import { Permission } from '@services/permissions/permissions.service';
import { IPropertyMeta, IPropertyValue } from '@services/assets/asset.interfaces';

export enum ObjectState {
  Active = 'active',
  Archived = 'archived',
  Inactive = 'inactive',
  Deleted = 'deleted'
}

export enum ObjectPhase {
  Draft = 'draft',
  InReview = 'inReview',
  Rejected = 'rejected',
  Approved = 'approved',
  Pending = 'pending',
  Inactive = 'inactive',
  Active = 'active',
  Published = 'published',
  Superseded = 'superseded',
  Deprecated = 'deprecated',
  Withdrawn = 'withdrawn',
  Archived = 'archived',
}

export enum ObjectTransition {
  Activate = 'activated',
  Deactivate = 'deactivated',
  Create = 'created',
  Retire = 'retired',
  StartReview = 'reviewStart',
  Approve = 'approved',
  Reject = 'rejected',
  Schedule = 'pending',
  Reschedule = 'pending2',
  Publish = 'published',
  Renew = 'republished',
  Supersede = 'superseded',
  Withdraw = 'withdrawn',
  Deprecate = 'deprecated',
  Archive = 'archived'
}

export enum ObjectLifecycle {
  Review = 'review',
  Basic = 'basic'
}

export enum ObjectPhaseWidget {
  Rules = 'rules',
  RulesEditor = 'rulesEditor',
  Access = 'access',
  AccessEditor = 'accessEditor',
  History = 'history',
  Events = 'events',
  Reviews = 'reviews',
  Observations = 'observations',
  Responses = 'responses',
  ReviewStatus = 'reviewStatus',
  Notes = 'notes'
}
export enum ObjectPhaseHeaderItemType {
  Text = 'text',
  Date = 'date',
  DateTime = 'datetime',
  Duration = 'duration',
  User = 'user'
}
export interface ObjectPhaseHeaderItem {
  type: ObjectPhaseHeaderItemType;
  title: string;
  value: ObjectPhaseHeaderItemValue;
}

export type ObjectPhaseHeaderItemValue = string | number | ((item: ObjectItem) => string | number);

export interface ObjectPhaseRule {
  description: string;
  type: ObjectState;
  restrictions?: ObjectTransitionKeyMap<ObjectPhaseRestriction>,
  transitions: ObjectTransition[];
  widgets?: ObjectPhaseWidget[];
  header?: ObjectPhaseHeaderItem[];
  canUpdateObject?: boolean;
  canUpdateRules?: boolean;
  canUpdateTags?: boolean;
  canAddReview?: boolean;
  canDeleteRevision?: boolean;
}

export interface ObjectTransitionRule {
  description: string;
  target: ObjectPhase;
  activity?: string;
}

export interface ObjectPhaseRestriction {
  check?: (object: ObjectItem, user) => boolean;
  isOwner?: boolean;
  permissions?: Permission[];
  // When these are actually editable, extend this with roles, certificates, users, teams
}

type ObjectPhaseKeyMap<T> = {
  [key in ObjectPhase]?: T;
}
type ObjectTransitionKeyMap<T> = {
  [key in ObjectTransition | 0]?: T;
}

export interface ObjectLifecycleCollection {
  usesRevision?: boolean;
  description?: string;
  phases: ObjectPhaseKeyMap<ObjectPhaseRule>,
  transitions: ObjectTransitionKeyMap<ObjectTransitionRule>;
  restrictions?: IObjectStringKeyMap<ObjectPhaseRestriction>;
  modules?: Module[]
}
export interface UpdateObjectAttributes {
  lifecycle?: ObjectLifecycle;
  reviewInfo?: ObjectItemReviewInfo;
  publishWhen?: number;
  nextReview?: number;
  selectedProperties?: IPropertyMeta[];
}

export interface UpdateObjectParams {
  objectUUID?: string;
  objectID?: number;
  mediaType?: string;
  description?: string;
  type?: string;
  subtype?: string;
  state?: string;
  isPublic?: number;
  folderID?: number;
  attributes?: UpdateObjectAttributes
}

export interface UpdateMultipleObjectParams {
  objects: IObjectNumberKeyMap<UpdateObjectParams>;
}

export interface ObjectHistoryItem {
  noteID: number;
  objectID: number;
  revision: string;
  userID: number;
  activity: {
    enum: ObjectHistoryItemActivity[];
  },
  relatedType: string;
  relatedItem: string;
  relatedData: object;
  time: number;
}

export enum ObjectHistoryItemActivity {
  Created = 'created',
  Activated = 'activated',
  Deactivated = 'deactivated',
  Deleted = 'deleted',
  Retired = 'retired',
  ReviewStart = 'reviewStart',
  ReviewEnd = 'reviewEnd',
  ReviewResult = 'reviewResult',
  ReviewResultWithNote = 'reviewResultWithNote',
  Note = 'note',
  Approved = 'approved',
  Rejected = 'rejected',
  Pending = 'pending',
  Published = 'published',
  Withdrawn = 'withdrawn',
  Deprecated = 'deprecated',
  Superseded = 'superseded',
  Archived = 'archived',
  Moved = 'moved'
}

export interface ObjectNote {
  addedAt: number;
  addedBy: number;
  disabledAt: number;
  disabledBy: number;
  lastUpdate: number;
  noteID: number;
  objectID: number;
  revision: string;
  sentiment: null;
  shift: string;
  subtype: string;
  type: string;
  value: string;
}

export interface ObjectItem {
  contentCategory: number;
  createdAt: number;
  creatorID: number;
  creatorShift: number;
  description: string;
  disabledAt: number;
  disabledBy: number;
  isPublic: number;
  folderID: number;
  lastUpdate: number;
  mediaType: string;
  objectID: number;
  rowID: number;
  revision: string;
  revisions?: ObjectItem[];
  translationOf: number;
  language: string;
  objectUUID: string;
  state: ObjectPhase;
  subtype: string;
  locations?: number[];
  users?: number[];
  properties?: IPropertyMeta[]
  tags: number[];
  type: string;
  attributes?: ObjectItemAttributes;
  notes?: ObjectNote[];
  observationID?: number;
  observationUUID?: number;
  history?: ObjectHistoryItem[];
  monitorHealth?: number;
  health?: number;
  stateEntered?: number;
  propertyValues?: IObjectStringKeyMap<IPropertyValue>;
}

export interface ObjectNote {
  addedAt: number;
  addedBy: number;
  disabledAt: number;
  disabledBy: number;
  lastUpdate: number;
  noteID: number;
  objectID: number;
  revision: string;
  shift: string;
  subtype: string;
  type: string;
  value: string;
}

export interface ObjectItemAttributes {
  lifecycle?: ObjectLifecycle;
  reviewInfo: ObjectItemReviewInfo;
  reapprovalProgress?: ObjectItemReviewProgress;
  reviewProgress?: ObjectItemReviewProgress;
  publishWhen?: number;
  nextReview?: number;
  properties?: IPropertyMeta[];
  parentProperties?: IPropertyMeta[];
  selectedProperties?: IPropertyMeta[];
}

export enum ObjectDurationScale {
  Days = 'days',
  Weeks = 'weeks',
  Months = 'months',
  Years = 'years'
}

export interface ObjectItemReviewInfo {
  document_review: number;

  // number of required re-approvers
  reapproval_count: number;
  reapproval_interval: number;
  reapproval_interval_unit: ObjectDurationScale;

  // number of required reviewers
  reviewer_count: number;
  review_duration: number;
  review_duration_unit: ObjectDurationScale;

  // controls to allow streamlining of publication
  auto_approve: boolean;
  auto_reject: boolean;
  auto_publish: boolean;

  // limits
  review_certifications: number[];
  review_permissions: string[];
  review_roles: number[];
  review_teams: number[];
  reviewers: number[];

  // limits
  approver_certifications?: number[];
  approver_permissions?: string[];
  approver_roles?: number[];
  approver_teams?: number[];
  approvers?: number[];

  // limits
  publisher_certifications?: number[];
  publisher_permissions?: string[];
  publisher_roles?: number[];
  publisher_teams?: number[];
  publishers?: number[];

  // limits
  reapproval_certifications?: number[];
  reapproval_permissions?: string[];
  reapproval_roles?: number[];
  reapproval_teams?: number[];
  reapprovers?: number[];
}

export interface ObjectItemReviewProgress {
  started: number;
  lastReview: number;
  completed: number;
  target: number;
  results: ObjectItemReviewResult[];
  reapprovals: ObjectItemReapproval[];
}

export enum ObjectItemReviewStatus {
  Approved = 'approved',
  Rejected = 'rejected'
}

export interface ObjectItemReapprovalRecord {
  userID: number;
  when: number;
  noteID?: number;
}

export interface ObjectItemReapproval {
  started: number;  // first approval timestamp
  completed: number; // all approvals complete
  results: ObjectItemReapprovalRecord[];
}

export interface ObjectItemReviewResult {
  userID: number;
  result: ObjectItemReviewStatus;
  when: number;
  noteID?: number;
}
