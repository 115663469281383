import { PopoverController } from '@ionic/angular';
import { Component, EventEmitter, Input, OnInit, Output, ViewChild } from '@angular/core';

import { ItemListComponent, ItemListDragAndDropComponent } from '@shared/components';
import { CustomFormComponent } from '@services/formBuilder/abstract-custom-form-field';

import { TranslateService } from '@ngx-translate/core';
import { cloneDeep, filter, find, forEach, map } from 'lodash';

@Component({
  selector: 'app-questions-to-include-form-modal',
  templateUrl: './questions-to-include-form-modal.component.html',
  styleUrls: ['./questions-to-include-form-modal.component.scss'],
})
export class QuestionsToIncludeFormModalComponent extends CustomFormComponent implements OnInit {
  @Input() options: any[];
  @Output() onSelected: EventEmitter<string[]> = new EventEmitter<string[]>();
  @ViewChild(ItemListComponent) itemListComponent: ItemListComponent;
  @ViewChild(ItemListDragAndDropComponent) itemListDragAndDropComponent: ItemListDragAndDropComponent;

  public selectedItems: any[] = [];
  public selectedItemsIDs: string[];
  public title: string;

  constructor(
    private popoverController: PopoverController,
    private translate: TranslateService
  ) {
    super();
  }

  ngOnInit() {
    this.title = this.translate.instant('SHARED.Select_Arrange_Column_Data');
  }

  ionViewDidEnter() {
    this.disableElements();
    setTimeout(() => {
      this.itemListComponent.setSelectedItems(map(this.selectedItems, 'id'));
    }, 100);
  }

  public onItemsSelected(): void {
    this.onSelected.emit(this.getSelectedItems());
    this.disableElements();
  }

  public getSelectedItems(): any[] {
    this.selectedItems = cloneDeep(this.itemListComponent.getSelectedByID());
    this.selectedItemsIDs = map(this.selectedItems, 'id');
    return this.selectedItemsIDs;
  }

  public disableElements() {
    forEach(filter(this.options, 'disabledFor'), (el) => {
      el.isDisabled = false;
    });
    forEach(filter(this.selectedItems, 'disabledFor'), (el) => {
      const hiddenEl = find(this.options, {id: el?.disabledFor});
      hiddenEl.isDisabled = true;
      if (el.isDisabled && hiddenEl.isDisabled) {
        el.isDisabled = false;
        this.selectedItems = filter(this.selectedItems, (item: any) => item.id !== hiddenEl.id);
      }
    });
  }

  public close() {
    this.popoverController.dismiss();
  }

  public applyForm() {
    this.popoverController.dismiss(this.itemListDragAndDropComponent.viewItems);
  }
}
