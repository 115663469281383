import { Injectable } from '@angular/core';

import { IObjectStringKeyMap } from '@shared/models';
import { PropertyService, UtilsService } from '@services';
import { HealthEvent } from '@services/assets/asset.interfaces';
import {
  StateHistoryPeriodValue,
  StateHistoryHealthEvent,
  AssetStateHistoryEntity,
  StateHistoryPeriod,
  AssetStateHistoryPeriodEntity,
  PeriodDateRange,
  StateBreakdown,
} from './../model/asset-state.interfaces';

import moment from 'moment';
import {
  each,
  filter,
  find,
  findIndex,
  findLast,
  findLastIndex,
  flatten,
  includes,
  last,
  map,
  orderBy,
  some,
  sumBy
} from 'lodash';

@Injectable({
  providedIn: 'root'
})
export class AssetStatusService {
  constructor(
    private propertyService: PropertyService,
    private utilsService: UtilsService
  ) {}

  public buildStates(healthEvents: IObjectStringKeyMap<HealthEvent[]>, timespan: string, dateRange?: PeriodDateRange, periodsByDateRange?: boolean): AssetStateHistoryEntity[] {
    return filter(map(healthEvents, (events, propertyKey) => {
      const propertyId: number = +propertyKey;
      const property = this.propertyService.getPropertyById(propertyId);

      if (property?.disabledAt === 0 || propertyId === 0) {
        return this.buildState(events, propertyId, timespan, dateRange, periodsByDateRange);
      }
    }));
  }

  private buildState(healthEvents: HealthEvent[], propertyKey: number, timespan: string, periodDateRange?: PeriodDateRange, periodsByDateRange?: boolean): AssetStateHistoryEntity {
    const dateRange = periodDateRange || this.utilsService.timespan(timespan);
    const indexedHealthEvents: StateHistoryHealthEvent[] = this.indexHealthEvents(healthEvents);
    const stateHistoryPeriods: StateHistoryPeriod[] = periodsByDateRange ? this.buildPeriodsByDateRange(dateRange) : this.buildPeriodsByTimespan(timespan, dateRange);
    this.defineEventsBasedOnPeriods(stateHistoryPeriods, indexedHealthEvents);

    return this.getStateHistoryEntity(stateHistoryPeriods, propertyKey, timespan);
  }

  private indexHealthEvents(healthEvents: HealthEvent[]): StateHistoryHealthEvent[] {
    const events = filter(orderBy(healthEvents, 'time'), (event) => {
      return event.health <= 2;
    });

    return map(events, (event, index) => ({
      ...event,
      time: Math.round(event.time * 1000),
      index
    }));
  }

  private buildPeriodsByDateRange(dateRange: PeriodDateRange): StateHistoryPeriod[] {
    const startTimeValue = moment(dateRange.startTime).valueOf();
    const endTimeValue = moment(dateRange.endTime).valueOf();

    return [{
      range: { startTime: startTimeValue, endTime: endTimeValue },
      title: moment(startTimeValue).format(),
      events: [],
      states: []
    }];
  }

  private buildPeriodsByTimespan(timespanKey: string, dateRange: PeriodDateRange): StateHistoryPeriod[] {
    const periods: StateHistoryPeriod[] = [];
    const daysDiff = moment(dateRange.endTime).diff(dateRange.startTime, 'days');
    const monthsDiff = moment(dateRange.endTime).diff(dateRange.startTime, 'months');
    const yearsDiff = moment(dateRange.endTime).diff(dateRange.startTime, 'year');

    if (timespanKey === 'custom') {
      if (daysDiff === 0) {
        timespanKey = 'day';
      } else if (daysDiff <= 7) {
        timespanKey = 'week';
      } else if (daysDiff <= 31) {
        timespanKey = 'month';
      } else if (daysDiff <= 91) {
        timespanKey = 'quarter';
      } else if (monthsDiff < 6) {
        timespanKey = 'halfYear';
      } else if (yearsDiff < 6) {
        timespanKey = 'year';
      } else {
        timespanKey = 'all';
      }
    }

    if (timespanKey === 'week') {
      for (let i = 0; i <= 6; i++) {
        let startTimeValue = moment(dateRange.startTime).add(i, 'day').startOf('day').valueOf();
        let endTimeValue = moment(dateRange.startTime).add(i, 'day').endOf('day').valueOf();

        periods.push({
          range: { startTime: startTimeValue, endTime: endTimeValue },
          title: moment(startTimeValue).format('dd')[0],
          events: [],
          states: []
        });
      }
    } else if (includes(['day', 'yesterday'], timespanKey)) {
      for (let i = 0; i <= 1; i++) {
        let startTimeValue = moment(dateRange.startTime).startOf('day').add(i * 12, 'hour').valueOf();
        let endTimeValue = moment(dateRange.startTime).startOf('day').add((i + 1) * 12, 'hour').valueOf() - 1;

        periods.push({
          range: { startTime: startTimeValue, endTime: endTimeValue },
          title: `${i * 12}:00`,
          events: [],
          states: []
        });
      }
    } else if (timespanKey === 'month') {
      for (let i = 0; i <= 3; i++) {
        let startTimeValue = moment(dateRange.startTime).startOf('month').add(i, 'week').valueOf();
        let endTimeValue = moment(dateRange.startTime).startOf('month').add(i + 1, 'week').valueOf();

        periods.push({
          range: {startTime: startTimeValue, endTime: endTimeValue},
          title: `${moment(endTimeValue).format('MMM')} ${moment(startTimeValue).format('DD')}-${moment(endTimeValue).format('DD')}`,
          events: [],
          states: []
        });
      }
    } else if (timespanKey === 'quarter') {
      for (let i = 0; i <= 2; i++) {
        let startTimeValue = moment(dateRange.startTime).startOf('quarter').add(i, 'month').valueOf();
        let endTimeValue = moment(dateRange.startTime).startOf('quarter').add(i + 1, 'month').valueOf();

        periods.push({
          range: { startTime: startTimeValue, endTime: endTimeValue },
          title: `${moment(startTimeValue).format('MMM')}`,
          events: [],
          states: []
        });
      }
    } else if (includes(['30days', '60days', '90days'], timespanKey)) {
      let periodLimit: number = +timespanKey[0];
      let dayAmount: number = 10;

      if (timespanKey === '90days') {
        periodLimit = 6;
        dayAmount = 15;
      }

      for (let i = 0; i < periodLimit; i++) {
        let startTimeValue = moment(dateRange.startTime).startOf('day').add(i * dayAmount, 'day').valueOf();
        let endTimeValue = moment(dateRange.startTime).startOf('day').add((i + 1) * dayAmount, 'day').valueOf();

        periods.push({
          range: {startTime: startTimeValue, endTime: endTimeValue},
          title: `${moment(startTimeValue).format('MMM')} ${moment(startTimeValue).format('DD')}-${moment(endTimeValue).format('DD')}`,
          events: [],
          states: []
        });
      }
    } else if (timespanKey === 'halfYear') {
      for (let i = 0; i <= monthsDiff; i++) {
        let startTimeValue = moment(dateRange.startTime).add(i, 'month').valueOf();
        let endTimeValue = moment(dateRange.startTime).add(i + 1, 'month').valueOf();

        periods.push({
          range: { startTime: startTimeValue, endTime: endTimeValue },
          title: `${moment(startTimeValue).format('MMM Y')}`,
          events: [],
          states: []
        });
      }
    } else if (timespanKey === 'year') {
      for (let i = 0; i <= yearsDiff; i++) {
        let startTimeValue = moment(dateRange.startTime).add(i, 'year').valueOf();
        let endTimeValue = moment(dateRange.startTime).add(i + 1, 'year').valueOf();

        periods.push({
          range: { startTime: startTimeValue, endTime: endTimeValue },
          title: `${moment(startTimeValue).format('MMM Y')}`,
          events: [],
          states: []
        });
      }
    } else if (timespanKey === 'all') {
      const maxPeriod = 6;
      const timeDiff = dateRange.endTime - dateRange.startTime;
      const timeInPeriod = timeDiff / maxPeriod;

      for (let i = 0; i <= maxPeriod; i++) {
        let startTimeValue = dateRange.startTime + (timeInPeriod * i);
        let endTimeValue = dateRange.startTime + (timeInPeriod * (i + 1));

        periods.push({
          range: { startTime: startTimeValue, endTime: endTimeValue },
          title: `${moment(startTimeValue).format('MMM Y')}`,
          events: [],
          states: []
        });
      }
    }

    return periods;
  }

  private defineEventsBasedOnPeriods(stateHistoryPeriods: StateHistoryPeriod[], stateEvents: StateHistoryHealthEvent[]) {
    each(stateHistoryPeriods, (period) => {
      each(stateEvents, (event) => {
        if (event.time >= period.range.startTime && event.time <= period.range.endTime) {
          period.events.push(event);
        }
      });

      this.definePeriodStatesInRange(period, stateEvents);
    });
    this.definePeriodStatesForEmptyPeriods(stateHistoryPeriods, stateEvents);
  }

  private definePeriodStatesForEmptyPeriods(stateHistoryPeriods: StateHistoryPeriod[], stateEvents: StateHistoryHealthEvent[]) {
    const currentTime = moment().valueOf();

    each(stateHistoryPeriods, (period: StateHistoryPeriod, index) => {
      if (period?.events?.length === 0 && period.range.startTime < currentTime) {
        const lastEvent = findLast(stateEvents, (event) => {
          return event.time < period.range.startTime;
        });

        if (lastEvent) {
          const endTime = currentTime > period.range.endTime ? period.range.endTime : currentTime;
          period.events.push(lastEvent);
          period.states.push({
            health: lastEvent.health,
            seconds: Math.round((endTime - period.range.startTime) / 1000)
          });
        }
      }
    });
  }

  private definePeriodStatesInRange(period: StateHistoryPeriod, stateEvents: StateHistoryHealthEvent[]) {
    each(period.events, (event, index: number) => {
      const nextEvent = period.events[index + 1];
      if (period.states.length === 0) {
        const previousEvent = find(stateEvents, { index: event.index - 1 });

        if (previousEvent) {
          period.states.push({
            health: previousEvent.health,
            seconds: Math.round((event.time - period.range.startTime) / 1000)
          });
        }
      }

      if (nextEvent) {
        period.states.push({
          health: event.health,
          seconds: Math.round((nextEvent.time - event.time) / 1000)
        });
      } else {
        const currentTime = moment().valueOf();
        let endData = period.range.endTime;

        if (endData > currentTime) {
          endData = currentTime;
        }

        period.states.push({
          health: event.health,
          seconds: Math.round((endData - event.time) / 1000)
        });
      }
    });
  }

  private getStateHistoryEntity(eventPeriods: StateHistoryPeriod[], propertyKey: number, timespan: string): AssetStateHistoryEntity {
    const currentState: AssetStateHistoryEntity = { statesBreakdown: [], stateHistoryPeriods: []};

    this.defineStateHistoryItems(eventPeriods, currentState, propertyKey);
    this.defineStatesBreakdown(eventPeriods, currentState, timespan);

    return currentState;
  }

  private defineStateHistoryItems(eventPeriods: StateHistoryPeriod[], currentState: AssetStateHistoryEntity, propertyKey: number) {
    const currentTime = moment().valueOf();
    const inRangePeriodIndex = findIndex(eventPeriods, (period) => {
      return currentTime > period.range.startTime && currentTime < period.range.endTime;
    });

    each(eventPeriods, (period, periodIndex) => {
      const lastStateIndex = findLastIndex(period.states, 'seconds');
      const isInRangePeriod = inRangePeriodIndex === periodIndex;

      const states: StateHistoryPeriodValue[] = map(period.states, (state, stateIndex) => {
        const isLastState = lastStateIndex === stateIndex;
        const stateValue: StateHistoryPeriodValue = {
          color: this.propertyService.getAIStatusByValue(state.health)?.type,
          value: state.seconds,
          tooltipLabel: this.utilsService.secsToDuration(state.seconds),
          health: state.health,
          range: period.range
        };

        if (state.seconds && isInRangePeriod && isLastState && period.range.endTime > currentTime) {
          stateValue.startTime = {
            seconds: Math.round((currentTime / 1000) - state.seconds),
            trimHours: true,
            format: 'mm:ss'
          };
        }

        return stateValue;
      });

      let title = '';

      if (propertyKey) {
        const property = this.propertyService.getPropertyById(propertyKey);

        if (periodIndex === 0 && property) {
          title = property.title;
        }
      } else {
        title = period.title;
      }

      const stateHistoryItem: AssetStateHistoryPeriodEntity = { title };

      if (states.length) {
        stateHistoryItem.period = states;
      }

      currentState.stateHistoryPeriods.push(stateHistoryItem);
    });

    this.defineCurrentPeriodHistoryItems(eventPeriods, currentState);
  }

  private defineCurrentPeriodHistoryItems(eventPeriods: StateHistoryPeriod[], currentState: AssetStateHistoryEntity) {
    const currentStateHistoryIndex = findLastIndex(currentState.stateHistoryPeriods, (stateHistoryPeriod) => {
      return some(stateHistoryPeriod.period, 'startTime');
    });

    if (currentState.stateHistoryPeriods[currentStateHistoryIndex]) {
      const currentStateHistory = currentState.stateHistoryPeriods[currentStateHistoryIndex];
      const currentEventPeriod = eventPeriods[currentStateHistoryIndex];

      const timeRange = currentEventPeriod.range;
      const secondsInRange = Math.round((timeRange.endTime - timeRange.startTime) / 1000);
      const lastEvent = last(currentEventPeriod.events);
      const isEventInRange = lastEvent.time >= timeRange.startTime && lastEvent.time <= timeRange.endTime;
      const periods = currentStateHistory.period as StateHistoryPeriodValue[];

      if (isEventInRange) {
        const currentPeriod = find(periods, 'startTime');
        const leftSecondsAfterCurrentPeriod = Math.round((timeRange.endTime - lastEvent.time) / 1000) - currentPeriod.value;

        periods.push({
          value: leftSecondsAfterCurrentPeriod,
          tooltipLabel: null
        });

        const periodSeconds = sumBy(periods, 'value');
        const previousPeriodExists = periodSeconds >= secondsInRange;

        if (!previousPeriodExists) {
          const secondsBeforeFirstPeriod = secondsInRange - periodSeconds;

          periods.unshift({
            value: secondsBeforeFirstPeriod,
            tooltipLabel: null
          });
        }
      } else {
        const periodSeconds = sumBy(periods, 'value');

        periods.push({
          value: secondsInRange - periodSeconds,
          tooltipLabel: null
        });
      }
    }
  }

  private defineStatesBreakdown(eventPeriods: StateHistoryPeriod[], currentState: AssetStateHistoryEntity, timespan: string) {
    let timeFormat: string = 'mm:ss';
    let trimHours = true;

    if (!includes(['day', 'yesterday'], timespan)) {
      trimHours = false;
      timeFormat = 'mm';
    }
    const states = flatten(map(eventPeriods, 'states'));
    const lastState = last(states);
    const stateHealthMap = {
      0: 0,
      1: 0,
      2: 0
    };

    each(states, (state) => {
      stateHealthMap[state.health] += state.seconds;
    });

    each(stateHealthMap, (value, health) => {
      const stateBreakdown: StateBreakdown = {
        value: this.utilsService.secsToDuration(value, trimHours, timeFormat),
        color: this.propertyService.getAIStatusByValue(+health)?.type,
      };

      if (value && lastState.health === +health && timespan !== 'yesterday') {
        stateBreakdown.startTime = {
          seconds: Math.round((moment().valueOf() / 1000) - value),
          trimHours,
          format: timeFormat
        };
      }

      currentState.statesBreakdown.unshift(stateBreakdown);
    });
  }
}
