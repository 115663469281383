import { Router } from '@angular/router';
import { Injectable } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';

import { Events } from '@services/events/events.service';
import { CommsService } from '@services/comms/comms.service';
import { UserdataService } from '@services/userdata/userdata.service';
import { AccountsService } from '@services/accounts/accounts.service';
import { Permission } from '@services/permissions/permissions.service';
import { PropertyService } from '@services/property/property.service';
import { IObjectStringKeyMap, IObjectNumberKeyMap } from '@shared/models';
import { FoldersDataService } from '@services/folders/folders-data.service';
import { Module, SubscriberService } from '@services/subscriber/subscriber.service';
import { IProperty, PropertyCategory } from '@services/property/property-model.interfaces';
import {
  ObjectItem,
  UpdateObjectParams,
  UpdateMultipleObjectParams,
  ObjectLifecycle,
  ObjectPhase,
  ObjectState,
  ObjectLifecycleCollection,
  ObjectTransition,
  ObjectPhaseWidget,
  ObjectItemReviewStatus,
  ObjectItemReviewInfo,
  ObjectPhaseHeaderItemType
} from './objects.interfaces';

import { NGXLogger } from 'ngx-logger';
import {
  cloneDeep,
  each,
  every,
  filter,
  find,
  findLast,
  get,
  has,
  includes,
  intersection,
  isEmpty,
  isNumber,
  isUndefined,
  some,
  sortBy,
  toArray,
  toString,
  uniq,
  upperFirst
} from 'lodash';


@Injectable({
  providedIn: 'root'
})
export class ObjectsService {
  public filterObject: any = {};
  private primaryObjectsByType: IObjectStringKeyMap<IObjectNumberKeyMap<ObjectItem>>;
  private objectsByType: IObjectStringKeyMap<IObjectNumberKeyMap<IObjectStringKeyMap<ObjectItem>>> = {};
  private lastRequest: number;

  private lifecycles: {
    data: IObjectStringKeyMap<ObjectLifecycleCollection>,
    lastUpdate: number | null;
  } = {
      data: {
        [ObjectLifecycle.Review]: {
          description: 'CONTENT.Lifecycle_Review',
          modules: [ Module.CONTENT, Module.CONTENT_REVIEW],
          usesRevision: true,
          restrictions: {
            // only a superadmin can archive
            [ObjectTransition.Archive]: {
              permissions: [
                Permission.SuperAdmin
              ]
            }
          },
          phases:
          {
            [ObjectPhase.Draft]: {
              description: 'CONTENT.Draft',
              type: ObjectState.Inactive,
              canUpdateObject: true,
              canUpdateRules: true,
              canUpdateTags: true,
              canDeleteRevision: true,
              transitions: [
                ObjectTransition.StartReview,
                ObjectTransition.Withdraw,
                ObjectTransition.Archive,
              ],
              widgets: [
                ObjectPhaseWidget.History,
                ObjectPhaseWidget.Notes
              ],
              header: [
                {
                  type: ObjectPhaseHeaderItemType.Text,
                  title: 'CONTENT.Status',
                  value: 'CONTENT.Draft'
                },
                {
                  type: ObjectPhaseHeaderItemType.Date,
                  title: 'SHARED.Created',
                  value: (item) => item.createdAt.toString()
                },
                {
                  type: ObjectPhaseHeaderItemType.User,
                  title: 'SHARED.Owner',
                  value: (item) => item.creatorID
                }
              ]
            },
            [ObjectPhase.InReview]:
            {
              description: 'CONTENT.InReview',
              type: ObjectState.Inactive,
              canUpdateTags: true,
              canAddReview: true,
              restrictions: {
                [ObjectTransition.Reject]: {
                  check: (item: ObjectItem, user?: number) => this.canReject(item, user)
                },
                [ObjectTransition.Approve]: {
                  check: (item: ObjectItem, user?: number) => this.canApprove(item, user)
                }
              },
              transitions: [
                ObjectTransition.Reject,
                ObjectTransition.Approve,
                ObjectTransition.Withdraw,
                ObjectTransition.Archive
              ],
              widgets: [
                ObjectPhaseWidget.ReviewStatus,
                ObjectPhaseWidget.Reviews,
                ObjectPhaseWidget.History,
                ObjectPhaseWidget.Notes
              ],
              header: [
                {
                  type: ObjectPhaseHeaderItemType.Text,
                  title: 'CONTENT.Status',
                  value: 'CONTENT.InReview'
                },
                {
                  type: ObjectPhaseHeaderItemType.Date,
                  title: 'CONTENT.Review_Started',
                  value: (item) => item.attributes.reviewProgress.started
                },
                {
                  type: ObjectPhaseHeaderItemType.Date,
                  title: 'CONTENT.Target',
                  value: (item) => item.attributes.reviewProgress.target
                },
                {
                  type: ObjectPhaseHeaderItemType.Text,
                  title: 'CONTENT.Required',
                  value: (item) => toString(item.attributes?.reviewInfo?.reviewer_count)
                },
                {
                  type: ObjectPhaseHeaderItemType.Text,
                  title: 'CONTENT.Approved',
                  value: (item) => toString(filter(item.attributes?.reviewProgress?.results, { result: ObjectItemReviewStatus.Approved }).length || 0)
                },
                {
                  type: ObjectPhaseHeaderItemType.Text,
                  title: 'CONTENT.Rejected',
                  value: (item) => toString(filter(item.attributes?.reviewProgress?.results, { result: ObjectItemReviewStatus.Rejected }).length || 0)
                }
              ]
            },
            [ObjectPhase.Rejected]:
            {
              description: 'CONTENT.Rejected',
              type: ObjectState.Deleted,
              transitions: [
                ObjectTransition.Archive
              ],
              widgets: [
                ObjectPhaseWidget.Reviews,
                ObjectPhaseWidget.Notes,
                ObjectPhaseWidget.History
              ],
              header: [
                {
                  type: ObjectPhaseHeaderItemType.Text,
                  title: 'CONTENT.Status',
                  value: 'CONTENT.Rejected'
                },
                {
                  type: ObjectPhaseHeaderItemType.Date,
                  title: 'CONTENT.Review_Started',
                  value: (item) => item.attributes.reviewProgress?.started || 'SHARED.Unknown'
                },
                {
                  type: ObjectPhaseHeaderItemType.Date,
                  title: 'CONTENT.Review_Completed',
                  value: (item) => item.attributes.reviewProgress?.completed || 'SHARED.Unknown'
                },
                {
                  type: ObjectPhaseHeaderItemType.Text,
                  title: 'CONTENT.Required',
                  value: (item) => toString(item.attributes?.reviewInfo?.reviewer_count)
                },
                {
                  type: ObjectPhaseHeaderItemType.Text,
                  title: 'CONTENT.Approved',
                  value: (item) => toString(filter(item.attributes?.reviewProgress?.results, { result: ObjectItemReviewStatus.Approved }).length || 0)
                },
                {
                  type: ObjectPhaseHeaderItemType.Text,
                  title: 'CONTENT.Rejected',
                  value: (item) => toString(filter(item.attributes?.reviewProgress?.results, { result: ObjectItemReviewStatus.Rejected }).length || 0)
                }
              ]
            },
            [ObjectPhase.Approved]:
            {
              description: 'CONTENT.Approved',
              type: ObjectState.Inactive,
              canUpdateTags: true,
              restrictions: {
                [ObjectTransition.Publish]: {
                  check: (item: ObjectItem, user?: number) => this.canPublish(item, user)
                },
                [ObjectTransition.Schedule]: {
                  check: (item: ObjectItem, user?: number) => this.canPublish(item, user)
                }
              },
              transitions: [
                ObjectTransition.Schedule,
                ObjectTransition.Publish,
                ObjectTransition.Withdraw,
                ObjectTransition.Archive
              ],
              widgets: [
                ObjectPhaseWidget.Reviews,
                ObjectPhaseWidget.Notes,
                ObjectPhaseWidget.History
              ],
              header: [
                {
                  type: ObjectPhaseHeaderItemType.Text,
                  title: 'CONTENT.Status',
                  value: 'CONTENT.Approved'
                },
                {
                  type: ObjectPhaseHeaderItemType.Date,
                  title: 'CONTENT.Review_Started',
                  value: (item) => item.attributes.reviewProgress?.started || 'SHARED.Unknown'
                },
                {
                  type: ObjectPhaseHeaderItemType.Date,
                  title: 'CONTENT.Review_Completed',
                  value: (item) => item.attributes.reviewProgress?.completed || 'SHARED.Unknown'
                },
                {
                  type: ObjectPhaseHeaderItemType.Text,
                  title: 'CONTENT.Required',
                  value: (item) => toString(item.attributes?.reviewInfo?.reviewer_count)
                },
                {
                  type: ObjectPhaseHeaderItemType.Text,
                  title: 'CONTENT.Approved',
                  value: (item) => toString(filter(item.attributes?.reviewProgress?.results, { result: ObjectItemReviewStatus.Approved }).length || 0)
                },
                {
                  type: ObjectPhaseHeaderItemType.Text,
                  title: 'CONTENT.Rejected',
                  value: (item) => toString(filter(item.attributes?.reviewProgress?.results, { result: ObjectItemReviewStatus.Rejected }).length || 0)
                }
              ]
            },
            [ObjectPhase.Pending]:
            {
              description: 'CONTENT.Pending',
              type: ObjectState.Inactive,
              canUpdateTags: true,
              restrictions: {
                [ObjectTransition.Publish]: {
                  check: (item: ObjectItem, user?: number) => this.canPublish(item, user)
                },
                [ObjectTransition.Reschedule]: {
                  check: (item: ObjectItem, user?: number) => this.canPublish(item, user)
                }
              },
              transitions: [
                ObjectTransition.Reschedule,
                ObjectTransition.Publish,
                ObjectTransition.Withdraw,
                ObjectTransition.Archive
              ],
              widgets: [
                ObjectPhaseWidget.Reviews,
                ObjectPhaseWidget.Notes,
                ObjectPhaseWidget.History
              ],
              header: [
                {
                  type: ObjectPhaseHeaderItemType.Text,
                  title: 'CONTENT.Status',
                  value: 'CONTENT.Pending'
                },
                {
                  type: ObjectPhaseHeaderItemType.Date,
                  title: 'CONTENT.Review_Completed',
                  value: (item) => item.attributes.reviewProgress?.completed || 'SHARED.Unknown'
                },
                {
                  type: ObjectPhaseHeaderItemType.Date,
                  title: 'CONTENT.Publication_Date',
                  value: (item) => item.attributes?.publishWhen
                },
              ]
            },
            [ObjectPhase.Published]:
            {
              description: 'CONTENT.Published',
              type: ObjectState.Active,
              canUpdateTags: true,
              restrictions: {
                [ObjectTransition.Renew]: {
                  check: (item: ObjectItem, user?: number) => this.canRenew(item, user)
                }
              },
              transitions: [
                // ObjectTransition.Supersede,
                ObjectTransition.Renew,
                ObjectTransition.Withdraw,
                ObjectTransition.Deprecate,
                ObjectTransition.Archive
              ],
              widgets: [
                ObjectPhaseWidget.Reviews,
                ObjectPhaseWidget.Notes,
                ObjectPhaseWidget.History
              ],
              header: [
                {
                  type: ObjectPhaseHeaderItemType.Text,
                  title: 'CONTENT.Status',
                  value: 'CONTENT.Published'
                },
                {
                  type: ObjectPhaseHeaderItemType.DateTime,
                  title: 'CONTENT.Published_Date',
                  value: (item) => item.attributes?.publishWhen || item?.stateEntered || item.lastUpdate
                },
                {
                  type: ObjectPhaseHeaderItemType.Date,
                  title: 'CONTENT.Next_Review',
                  value: (item) => item.attributes?.nextReview || 'SHARED.Unknown'
                }
              ]
            },
            [ObjectPhase.Deprecated]:
            {
              description: 'CONTENT.Deprecated',
              type: ObjectState.Active,
              canUpdateTags: true,
              restrictions: {
                [ObjectTransition.Renew]: {
                  check: (item: ObjectItem, user?: number) => this.canRenew(item, user)
                }
              },
              transitions: [
                ObjectTransition.Renew,
                ObjectTransition.Withdraw,
                ObjectTransition.Supersede,
                ObjectTransition.Archive
              ],
              widgets: [
                ObjectPhaseWidget.Reviews,
                ObjectPhaseWidget.Notes,
                ObjectPhaseWidget.History
              ],
              header: [
                {
                  type: ObjectPhaseHeaderItemType.Text,
                  title: 'CONTENT.Status',
                  value: 'CONTENT.Deprecated'
                },
                {
                  type: ObjectPhaseHeaderItemType.Date,
                  title: 'SHARED.Last_Uodated',
                  value: (item) => item?.stateEntered || item.lastUpdate
                }
              ]
            },
            [ObjectPhase.Superseded]:
            {
              description: 'CONTENT.Superseded',
              type: ObjectState.Inactive,
              transitions: [
                ObjectTransition.Archive
              ],
              widgets: [
                ObjectPhaseWidget.Notes,
                ObjectPhaseWidget.History
              ],
              header: [
                {
                  type: ObjectPhaseHeaderItemType.Text,
                  title: 'CONTENT.Status',
                  value: 'CONTENT.Superseded'
                },
                {
                  type: ObjectPhaseHeaderItemType.Date,
                  title: 'SHARED.Last_Updated',
                  value: (item) => item?.stateEntered || item.lastUpdate
                }
              ]
            },
            [ObjectPhase.Withdrawn]:
            {
              description: 'CONTENT.Withdrawn',
              type: ObjectState.Inactive,
              widgets: [
                ObjectPhaseWidget.History,
                ObjectPhaseWidget.Notes
              ],
              transitions: [
                ObjectTransition.Archive
              ],
              header: [
                {
                  type: ObjectPhaseHeaderItemType.Text,
                  title: 'CONTENT.Status',
                  value: 'CONTENT.Withdrawn'
                },
                {
                  type: ObjectPhaseHeaderItemType.Date,
                  title: 'SHARED.Last_Updated',
                  value: (item) => item?.stateEntered || item.lastUpdate
                }
              ]
            },
          },
          transitions:
          {
            "0": {
              description: 'CONTENT.Add_Revision',
              target: ObjectPhase.Draft,
              activity: 'addRevision'
            },
            [ObjectTransition.StartReview]:
            {
              description: 'CONTENT.Start_Review',
              target: ObjectPhase.InReview
            },
            [ObjectTransition.Approve]: {
              description: 'CONTENT.Approve',
              target: ObjectPhase.Approved
            },
            [ObjectTransition.Reject]: {
              description: 'CONTENT.Reject',
              target: ObjectPhase.Rejected
            },
            [ObjectTransition.Reschedule]: {
              description: 'CONTENT.Reschedule_Publication',
              target: ObjectPhase.Pending
            },
            [ObjectTransition.Schedule]: {
              description: 'CONTENT.Schedule_Publication',
              target: ObjectPhase.Pending
            },
            [ObjectTransition.Publish]: {
              description: 'CONTENT.Publish',
              target: ObjectPhase.Published
            },
            [ObjectTransition.Renew]: {
              description: 'CONTENT.Renew_Approval',
              target: ObjectPhase.Published
            },
            [ObjectTransition.Supersede]: {
              description: 'CONTENT.Supersede',
              target: ObjectPhase.Superseded
            },
            [ObjectTransition.Withdraw]: {
              description: 'CONTENT.Withdraw',
              target: ObjectPhase.Withdrawn
            },
            [ObjectTransition.Deprecate]: {
              description: 'CONTENT.Deprecate',
              target: ObjectPhase.Deprecated
            },
            [ObjectTransition.Archive]: {
              description: 'CONTENT.Archive',
              target: ObjectPhase.Archived
            },
          }
        },
        [ObjectLifecycle.Basic]: {
          description: 'CONTENT.Lifecycle_Basic',
          modules: [ Module.CONTENT ],
          usesRevision: false,
          restrictions: {
            // only a superadmin can archive
            [ObjectPhase.Archived]: {
              permissions: [
                Permission.SuperAdmin
              ]
            }
          },
          transitions: {
            0: {
              description: 'CONTENT.Add_Item',
              target: ObjectPhase.Inactive,
              activity: "create"
            },
            [ObjectTransition.Activate]:
            {
              description: 'CONTENT.Activate',
              target: ObjectPhase.Active
            },
            [ObjectTransition.Deactivate]: {
              description: 'CONTENT.Deactivate',
              target: ObjectPhase.Inactive
            },
            [ObjectTransition.Archive]: {
              description: 'CONTENT.Archive',
              target: ObjectPhase.Archived
            }
          },
          phases: {
            [ObjectPhase.Inactive]:
            {
              description: 'CONTENT.Inactive',
              type: ObjectState.Inactive,
              transitions: [
                ObjectTransition.Activate,
                ObjectTransition.Archive
              ]
            },
            [ObjectPhase.Active]:
            {
              description: 'CONTENT.Active',
              type: ObjectState.Active,
              transitions: [
                ObjectTransition.Deactivate,
                ObjectTransition.Archive
              ]
            },
          },
        },
      },
      lastUpdate: Date.now()
    };

  constructor(
    private comms: CommsService,
    private subscriber: SubscriberService,
    private userdataService: UserdataService,
    private events: Events,
    private logger: NGXLogger,
    private router: Router,
    private foldersDataService: FoldersDataService,
    private accounts: AccountsService,
    private translate: TranslateService,
    private propertyService: PropertyService
  ) {}

  /**
   *
   * @param objectID  the ID of the object from the object_map table
   * @param thumbnail whether or not to request the thumbnail version
   *
   */
  public URI(objectID: number, thumbnail: boolean = false, oldStyle: boolean = false, revision?: string ) {
    let cmd = '';
    const token = this.comms.token || 'none';
    let ret = '';

    if (oldStyle) {
      cmd = this.comms.serviceURL.replace('corvex.cgi', 'getObject.cgi');

      ret = cmd +
        '?cmd=getObject&token=' + this.comms.token +
        '&subscriberID=' + this.subscriber.subInfo.subscriberID +
        '&objectID=' + objectID;

      if (thumbnail) {
        ret += '&thumb=1';
      }
    } else {
      cmd = this.comms.serviceURL.replace('api/v2', '');
      if (thumbnail) {
        cmd += 'thumb/';
      } else {
        cmd += 'object/';
      }
      ret = `${cmd}${this.subscriber.subInfo.subscriberID}/${token}/${objectID}`;
      if (!isUndefined(revision)) {
        ret += '/' + encodeURIComponent(revision);
      }
    }
    return ret;
  }

  public removeObject(id, revision?: string, allRevisions?: boolean) {
    const fData = { cmd: 'deleteObject', objectID: id };
    if (!isUndefined(revision)) {
      fData['revision'] = revision;
    } else if (!isUndefined(allRevisions)) {
      fData['allRevisions'] = 1;
    }
    return this._updateAndRefresh(fData, () => this.refresh());
  }

  public refresh(): Promise<IObjectStringKeyMap<ObjectItem[]>> {
    const requestData = {
      cmd: 'getObjectList',
      lastRequest: this.lastRequest,
      types: ['content','tier'],
      states: JSON.stringify([]),
      includeDisabled: false,
      incremental: 1,
      includeRevisions: 1,
      includeHistory: 1,
      includeNotes: 1
    };

    return this.comms.sendMessage(requestData, false, false).then((data) => {
      const objects = get(data, 'result.objects') || [];

      if (data?.reqStatus === 'OK') {
        this.updateCache(data);
      }

      return objects;
    });
  }

  public updateCache(data) {
    each(data?.result?.objects, (object: ObjectItem) => {
      if (!this.objectsByType[object.type]) {
        this.objectsByType[object.type] = {};
      }
      if (!this.objectsByType[object.type][object.objectID]) {
        this.objectsByType[object.type][object.objectID] = {};
      }
      this.objectsByType[object.type][object.objectID][object.rowID] = object;
    });

    each(data?.result?.removed, (object: ObjectItem) => {
      const targetObject = this.getObjectById(object.objectID, object.rowID, true) as ObjectItem;

      if (targetObject) {
        if (targetObject.attributes?.lifecycle === 'review') {
          if (targetObject.revision === object.revision) {
            delete this.objectsByType[targetObject.type][targetObject.objectID][targetObject.rowID];
            if (!Object.keys(this.objectsByType[targetObject.type][targetObject.objectID]).length) {
              delete this.objectsByType[targetObject.type][targetObject.objectID];
            }
          }
        } else {
          delete this.objectsByType[targetObject.type][targetObject.objectID];
        }
      }
    });

    // rebuild the primary collection
    this.primaryObjectsByType = {};
    each(this.objectsByType, (collections, theType) => {
      this.primaryObjectsByType[theType] = {};
      each(collections, (collection, objectID) => {
        this.primaryObjectsByType[theType][objectID] = this.primaryRevision(collection);
      });
    });

    this.lastRequest = data.result.timestamp;
    this.events.publish('ccs:objectsUpdate', true);
  }

  public getRevisionByID(objectID: number, rowID?: number): ObjectItem {
    if (!rowID) {
      return this.getPrimaryRevision(objectID);
    }  else {
      return this.getObjectById(objectID, rowID) as ObjectItem;
    }
  }

  public getPrimaryRevision(objectID: number, alias?: string): ObjectItem {
    if (isUndefined(alias)) {
      alias = 'content';
    }
    let ret;
    each(this.primaryObjectsByType, (objects, alias) => {
      if (objects?.[objectID]) {
        ret = objects[objectID];
      }
    });
    return ret;
  }

  public async updateObjectRevision(params: UpdateObjectParams): Promise<any> {
    const requestParams = {
      cmd: 'updateObject',
      ...params
    };

    return this._updateAndRefresh(requestParams, () => this.refresh());
  }

  public updateObject(params: UpdateObjectParams | UpdateMultipleObjectParams): Promise<any> {
    const requestParams = {
      cmd: 'updateObject',
      ...params
    };

    return this._updateAndRefresh(requestParams, () => this.refresh());
  }

  public addRevision(params: UpdateObjectParams | UpdateMultipleObjectParams): Promise<any> {
    const p = cloneDeep(params);
    delete p['folderID'];
    delete p['contentCategory'];
    const requestParams = {
      cmd: 'addRevision',
      ...p
    };

    return this._updateAndRefresh(requestParams, () => this.refresh());
  }

  public getCachedObjectsByType(alias: string, translated?: boolean): IObjectNumberKeyMap<ObjectItem> {
    let ret = this.primaryObjectsByType[alias];

    if (Object.keys(ret).length) {
      if (translated) {
        let translatedRet: IObjectNumberKeyMap<ObjectItem> = {};
        const userLang = this.userdataService.getLanguage();
        each(ret, (item: ObjectItem) => {
          const translatedLanguage: any = findLast(get(item, 'translations.objects'), { language: userLang });
          if (translatedLanguage) {
            translatedRet[item.objectID] = translatedLanguage;
          } else {
            translatedRet[item.objectID] = item;
          }
        });
        ret = translatedRet;
      }
    }
    return ret;
  }

  public getCachedObjectById(id: number, alias?: string, translated?: boolean): ObjectItem {
    let object = this.getPrimaryRevision(id, alias);

    if (translated) {
      const translatedLanguage: any = findLast(get(object, 'translations.objects'), { language: this.userdataService.getLanguage() });

      if (translatedLanguage) {
        object = this.getCachedObjectById(translatedLanguage.value, alias);
      }
    }

    return cloneDeep(object);
  }

  public getCachedObjectByIds(ids: number[], onlyActive?: boolean): ObjectItem[] {
    const items: ObjectItem[] = [];

    each(uniq(ids), (id) => {
      const item = this.getPrimaryRevision(id);

      if (!onlyActive || this.isActive( [item])) {
        items.push(item);
      }
    });

    return items;
  }

  public getCachedObjectByAlias(alias: string): ObjectItem[] {
    return cloneDeep(toArray(this.primaryObjectsByType?.[alias]));
  }

  public getIconByType(mediaType: string): string {
    return this.getIconByBaseType(this.getObjectType(mediaType));
  }

  public getIconByBaseType(baseType: string = 'other'): string {
    const iconMap = {
      pdf: 'file-pdf.svg',
      image: 'file-image.svg',
      video: 'file-video.svg',
      audio: 'file-audio.svg',
      word: 'file-word.svg',
      excel: 'file-xls.svg',
      ppt: 'file-ppt.svg',
      html: 'file-html.svg',
      other: 'file-other.svg',
      csv: 'file-csv.svg',
      txt: 'file-txt.svg',
      md: 'file-md.svg'
    };

    return iconMap[baseType] ? `assets/icons/media_types/${iconMap[baseType]}` : null;
  }

  public getObjectType(mediaType: string) {
    let type: 'image' | 'pdf' | 'video' | 'audio' | 'word' | 'excel' | 'ppt' | 'csv' | 'other' = 'other';

    const map: any = {
      'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet': 'excel',
      'application/vnd.ms-excel': 'excel',
      'application/xhtml+xml': 'html',
      'application/html': 'html',
      'text/html': 'html',
      'application/vnd.openxmlformats-officedocument.presentationml.presentation': 'ppt',
      'application/vnd.ms-powerpoint': 'ppt',
      'application/vnd.openxmlformats-officedocument.wordprocessingml.document': 'word',
      'application/msword': 'word',
      'text/csv': 'csv',
      'application/pdf': 'pdf',
      'text/plain': 'txt',
      'text/markdown':'md'
    };

    if (includes(mediaType, 'video/')) {
      type = 'video';
    } else if (includes(mediaType, 'audio/')) {
      type = 'audio';
    } else if (includes(mediaType, 'image/')) {
      type = 'image';
    } else {
      if (has(map, mediaType)) {
        type = map[mediaType];
      }
    }
    return type;
  }

  public filterData(objects: ObjectItem[], onlyActive = false) {
    objects = filter(objects, (item) => {
      return !item.translationOf && (onlyActive && this.isActive(item.objectID) || !onlyActive);
    });

    if (isEmpty(this.filterObject)) {
      return objects;
    } else {
      return filter(objects, (dataItem) => this.checkFilter(dataItem));
    }
  }

  public isPropertyRelevant(item: ObjectItem, property: number | IProperty): boolean {
    let relevant = false;

    if (isNumber(property)) {
      property = this.propertyService.getPropertyById(property);
    }

    if (item.attributes?.properties) {
      // this object has properties
      if (some(item.attributes.properties, { propertyID: property.propertyID })) {
        if (property.category === PropertyCategory.ReviewDate) {
          // this is only relevant if the revision is in review
          if (item.state === ObjectPhase.InReview) {
            relevant = true;
          }
        } else if (property.category === PropertyCategory.ReapprovalDate) {
          if (item.state === ObjectPhase.Published || item.state === ObjectPhase.Deprecated) {
            relevant = true;
          }
        } else {
          // it is some other  category and it is in the list of properties so it must be relevant
          relevant = true;
        }
      }
    }
    return relevant;
  }

  public isActive(item: number | ObjectItem[] | IObjectNumberKeyMap<ObjectItem>): boolean {
    let ret = true;
    const primary = this.primaryRevision(item);
    if (primary) {
      if (!includes([ObjectPhase.Published, ObjectPhase.Deprecated, ObjectPhase.Active], primary.state)) {
        ret = false;  // this is not an active phase
      }
    } else {
      ret = false;
    }
    return ret;
  }

  public getObjectFileUrl(object: ObjectItem, revision?: string): string {
    if (!object) {
      return null;
    }

    const type = this.getObjectType(object?.mediaType);
    let imgSource = '';

    if (type === 'image' || type === 'pdf' ) {
      imgSource = this.comms.objectURI(object?.objectID, true, false, true, revision);
    } else {
      imgSource = this.getIconByType(object.mediaType);
    }

    return imgSource;
  }

  public getObjectEvents(objectID: number, revision: string): Promise<ObjectItem> {
    return new Promise((resolve, reject) => {
      const when = Date.now();
      this.comms.sendMessage({
        cmd: 'getObjectList',
        objectID,
        revision,
        includeDisabled: false,
        includeHistory: 1,
        includeNotes: 1,
        includeEvents: 1,
        includeProperties: 1,
      }, false, false).then(data => {
        resolve(data?.result?.objects?.[0] || {});
      }).catch((err) => {
        reject(err);
      });
    });
  }

  public getActiveLifecyles(): ObjectLifecycleCollection[] {
    const ret = [];
    each(this.lifecycles.data, (item, name) => {
      if (item?.modules) {
        let matched = 1;
        each(item.modules, module => {
          if (!this.subscriber.usesModule(module, true)) {
            matched = 0;
          }
        });
        if (matched) {
          ret.push(item);
        }
      } else {
        ret.push(item);
      }
    });
    return ret;
  }

  public getLifecycle(name: ObjectLifecycle, checkActive: boolean = false): ObjectLifecycleCollection {
    if (has(this.lifecycles.data, name)) {
      const item = this.lifecycles.data[name];
      if (checkActive) {
        let active = 1;
        if (item?.modules) {
          each(item.modules, module => {
            if (!this.subscriber.usesModule(module, true)) {
              active = 0;
            }
          });
        }
        if (active) {
          return item;
        }
      } else {
        return item;
      }
    } else {
      return;
    }
  }

  public getPhaseRule(name: ObjectLifecycle, phase: ObjectPhase) {
    const lc = this.getLifecycle(name);
    if (lc) {
      const thisPhase = get(lc.phases, phase);
      if (thisPhase) {
        return thisPhase;
      }
    }
  }

  public openDetailPage(objectID: number) {
    const object = this.getPrimaryRevision(objectID);
    const folder = this.foldersDataService.getFolderByID(object?.folderID);
    if (object?.attributes?.lifecycle === ObjectLifecycle.Review) {
      this.router.navigate([`pages/management/content/document/${objectID}`]);
    } else {
      this.router.navigate(['pages/management/content/detail', {messageID: objectID}]);
    }
  }

  public addReview(objectID: number, revision: string, result: ObjectItemReviewStatus, note?: string ) {
    const fData: IObjectStringKeyMap<string | number> = {
      cmd: 'reviewObject',
      objectID,
      result,
      revision
    };

    if (!isUndefined(note)) {
      fData.note = note;
    }

    return this._updateAndRefresh(fData, () => this.refresh()) ;
  }

  public addComment(objectID: number, text: string, revision?: string) {
    const fData: IObjectStringKeyMap<string | number> = {
      cmd: 'addObjectNote',
      objectID,
      value: text,
      type: 'comment'
    };

    if (!isUndefined(revision)) {
      fData.revision = revision;
    }

    return this._updateAndRefresh(fData, () => this.refresh()) ;
  }

  public getRevisionList(objectID: number, noClone?: boolean): ObjectItem[] {
    const collection = this.getObjectById(objectID, null, noClone);
    return sortBy(collection, ['rowID']).reverse();
  }

  public primaryRevision(collection: IObjectNumberKeyMap<ObjectItem> | ObjectItem[] | number ): ObjectItem {
    if (isNumber(collection)) {
      collection = this.getRevisionList(collection);
    }
    const revList = sortBy(collection, [ 'rowID' ]).reverse();
    if (!revList.length) {
      return;
    }

    const firstItem = revList[0];
    let ret;

    if (firstItem?.attributes?.lifecycle === ObjectLifecycle.Review) {
      // in review the most important state is the one we want I think...
      const priorities = [
        ObjectPhase.Published,
        ObjectPhase.Deprecated,
        ObjectPhase.Pending,
        ObjectPhase.Approved,
        ObjectPhase.InReview,
        ObjectPhase.Draft,
        ObjectPhase.Withdrawn,
        ObjectPhase.Superseded,
        ObjectPhase.Rejected
      ];
      let idx = priorities.length - 1;
      while (idx > -1) {
        const match = find(revList, { state: priorities[idx]});
        if (match) {
          ret = match;
        }
        idx--;
      }
      if (!ret) {
        ret = firstItem;
      }
    } else {
      ret = firstItem;
    }

    return ret;
  }

  public getPhase(name: ObjectLifecycle, phase: ObjectPhase) {
    const lc = this.getLifecycle(name);
    if (lc) {
      const thisPhase = get(lc.phases, phase);
      if (thisPhase) {
        return thisPhase;
      }
    }
  }

  public getPhaseTitle(lifecycle: ObjectLifecycle, state: ObjectPhase): string {
    const rule = this.getPhase(lifecycle, state);

    if (rule) {
      return this.translate.instant(rule.description);
    } else {
      return this.translate.instant(`CONTENT.${upperFirst(state)}`);
    }
  }

  public getTransition(name: ObjectLifecycle, transition: ObjectTransition) {
    const lc = this.getLifecycle(name);
    if (lc) {
      const thisTransition = get(lc.transitions, transition);
      if (thisTransition) {
        return thisTransition;
      }
    }
  }

  /**
   * getActions - return a list of action IDs and descriptions for use in menus
   *
   * @param name an optional lifecycle name.  If omitted, returns a list of actions for all active lifecycles.
   */
  public getActions(name?: ObjectLifecycle): any {
    let ret = [];
    const lifecycles = name ? [ this.getLifecycle(name, true) ] : this.getActiveLifecyles();
    each(lifecycles, cycle => {
      const t = cycle.transitions;
      each(t, (rule, name) => {
        const activity = rule?.activity ?? name;
        if (!find(ret, { id: activity } ) ) {
          ret.push({ id: activity, description: rule.description });
        }
      });
    });
    return ret;
  }

  /**
   * getPhases - return a list of action IDs and descriptions for use in menus
   *
   * @param name an optional lifecycle name.  If omitted, returns a list of phases for all active lifecycles.
   */
  public getPhases(name?: ObjectLifecycle): any {
    let ret = [];
    const lifecycles = name ? [ this.getLifecycle(name, true) ] : this.getActiveLifecyles();
    each(lifecycles, cycle => {
      const t = cycle.phases;
      each(t, (rule, name) => {
        if (!find(ret, { id: name } ) ) {
          ret.push({ id: name, description: rule.description });
        }
      });
    });
    return ret;
  }

  public renewApproval(objectID: number, revision: string, note: string) {
    const requestData = {
      cmd: 'renewObject',
      objectID,
      revision,
      note
    };

    return this._updateAndRefresh(requestData, () => this.refresh());
  }

  public completeReview(objectID: number, revision: string, transition: string) {
    const requestData = {
      cmd: 'completeReview',
      objectID,
      revision,
      state: transition
    };

    return this._updateAndRefresh(requestData, () => this.refresh());
  }

  public startReview(objectID: number, revision: string) {
    const requestData = {
      cmd: 'startReview',
      objectID,
      revision
    };

    return this._updateAndRefresh(requestData, () => this.refresh());
  }

  public canReview(item: ObjectItem): boolean {
    // check the various filter rules against the current user
    const settings = item.attributes?.reviewInfo;
    if (settings?.reviewers && settings.reviewers.length) {
      // if there is a list of reviewers - check it
      if (includes(settings.reviewers, +this.userdataService.userID)) {
        return true;
      }
    }
    return this.checkReviewerRules(settings);
  }

  public checkTransitionRestriction(item: ObjectItem, transition: ObjectTransition, user?: number): boolean {
    let result = true;
    const lc = item.attributes.lifecycle;
    if (lc) {
      // there is a lifecycle
      const p = this.getPhaseRule(lc, item.state);
      if (p && p?.restrictions) {
        if (has(p.restrictions, transition)) {
          // there is a rule for this transition
          const rule = p.restrictions[transition];
          if (isUndefined(user)) {
            user = +this.userdataService.userID;
          }
          if (rule.permissions) {
            // permissions always get access if there is a rule
            const acct = this.accounts.getByID(user);
            if (acct && acct?.permissions) {
              result = false;
              each(acct.permissions, (enabled, key) => {
                if (enabled) {
                  if (intersection(rule.permissions, [key]).length) {
                    result = true;
                  }
                }
              });
            } else {
              // there is a permission restriction and this user has no permissions
              result = false;
            }
          }
          if (rule.isOwner) {
            // the isOwner rule always grants permission too
            if (item.creatorID === user) {
              result = true;
            }
          }
          // if we get this far and still can do it, is there a check function too?
          if (result && rule?.check) {
            // there is a check function; if that is present just use it
            result = rule.check(item, user);
          }
        }
      }
    }

    return result;
  }

  private checkFilter(dataItem: any): boolean {
    const keyMap = {
      permissions: 'permissionLevel',
      locations: 'locations',
      roles: 'roles',
      shifts: 'shift',
      certifications: 'certifications',
      users: 'users'
    };

    const matchKeyMap = {
      users: 1
    };

    return every(keyMap, (filterKey: string, dataKey: string) => {
      const filter = this.filterObject[filterKey];
      const dataByKey = dataItem[dataKey];
      const matched = intersection(filter, dataByKey).length > 0;
      const dataIsEmpty = dataByKey.length === 0 && !has(matchKeyMap, filterKey);

      return filter?.length ? (dataIsEmpty || matched) : true;
    });
  }

  private checkReviewerRules(dataItem: ObjectItemReviewInfo, type: string = 'review'): boolean {
    const keyMap = {
      permissions: 'permissions',
      roles: 'roles',
      teams: 'teams',
      certifications: 'certifications'
    };

    return every(keyMap, (filterKeySuffix: string, dataKey: string) => {
      const filterKey = `${type}_${filterKeySuffix}`;
      const dataByKey = dataKey === 'permissions' ? Object.keys(this.userdataService.Permissions) : this.userdataService[dataKey];
      const filter = dataItem[filterKey];
      const matched = intersection(filter, dataByKey).length > 0;
      const filterIsEmpty = filter?.length === 0;

      return filter?.length ? (filterIsEmpty || matched) : true;
    });
  }

  public getObjectById(id: number, rowID?: number, noClone?: boolean): ObjectItem | IObjectNumberKeyMap<ObjectItem> {
    let object: ObjectItem | IObjectNumberKeyMap<ObjectItem>;

    each(this.objectsByType, (objectsByType) => {
      if (objectsByType?.[id]) {
        if (rowID) {
          object = objectsByType[id]?.[rowID];
        } else {
          object = objectsByType[id];
        }
        return;
      }
    });

    if (noClone) {
      return object;
    } else {
      return cloneDeep(object);
    }
  }

  private _updateAndRefresh(fData, updateHandler): Promise<any> {
    const cmdOpts = cloneDeep(fData);

    return new Promise((resolve, reject) => {
      this.comms.sendMessage(cmdOpts, false, false)
        .then((data) => {
          if (data && data.reqStatus === 'OK') {
            updateHandler()
              .then((ores) => {
                resolve(data);
              })
              .catch((oerr) => {
                this.logger.error('update failed: ' + oerr);
              });
          } else {
            resolve(data);
          }
        })
        .catch((err) => {
          this.logger.error('update failed: ' + err);
          reject(err);
        });
    });
  }

  private canApprove(item: ObjectItem, user: number): boolean {
    let ret = true;
    // first - can this person even do it?
    // check the various filter rules against the current user
    const settings = item.attributes?.reviewInfo;
    if (settings?.approvers && settings.approvers.length) {
      // if there is a list of reviewers - check it
      if (includes(settings.approvers, +this.userdataService.userID)) {
        return true;
      }
    }
    ret = this.checkReviewerRules(settings, 'approver');

    // look at the object; are we in review and do we have enough reviews?
    if (ret) {
      if (item.attributes.reviewInfo?.reviewer_count) {
        // there is a required number of reviews
        if (item.attributes?.reviewProgress?.results) {
          if (item.attributes.reviewInfo.reviewer_count < item.attributes.reviewProgress.results.length) {
            ret = false;
          }
        } else {
          // there are no reviews at all
          ret = false;
        }
      }
    }
    return ret;
  }

  private canReject(item: ObjectItem, user: number): boolean {
    let ret = true;
    // first - can this person even do it?
    // check the various filter rules against the current user
    const settings = item.attributes?.reviewInfo;
    if (settings?.approvers && settings.approvers.length) {
      // if there is a list of reviewers - check it
      if (includes(settings.approvers, +this.userdataService.userID)) {
        return true;
      }
    }
    ret = this.checkReviewerRules(settings, 'approver');

    return ret;
  }

  private canPublish(item: ObjectItem, user: number): boolean {
    if (item.state !== ObjectPhase.Approved) {
      return false;
    }
    let ret = true;
    // first - can this person even do it?
    // check the various filter rules against the current user
    const settings = item.attributes?.reviewInfo;

    ret = this.checkReviewerRules(settings, 'publisher');
    if (!ret) {
      if (settings?.publishers && settings.publishers.length) {
        // if there is a list of reviewers - check it
        if (includes(settings.publishers, +this.userdataService.userID)) {
          ret = true;
        }
      }
    }

    // look at the object; are we in review and do we have enough reviews?
    if (ret) {
      if (item.attributes.reviewInfo?.reviewer_count) {
        // there is a required number of reviews
        if (item.attributes?.reviewProgress?.results) {
          if (item.attributes.reviewInfo.reviewer_count < item.attributes.reviewProgress.results.length) {
            ret = false;
          }
        } else {
          // there are no reviews at all
          ret = false;
        }
      }
    }
    return ret;
  }

  private canRenew(item: ObjectItem, user: number): boolean {
    let ret = true;
    // first - can this person even do it?
    // check the various filter rules against the current user
    const settings = item.attributes?.reviewInfo;

    ret = this.checkReviewerRules(settings, 'reapprover');
    if (!ret) {
      if (settings?.reapprovers && settings.reapprovers.length) {
        // if there is a list of reviewers - check it
        if (includes(settings.reapprovers, +user)) {
          ret = true;
        }
      }
    }

    return ret;
  }
}
