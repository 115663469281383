import { PopoverController } from '@ionic/angular';
import { Component, OnDestroy, OnInit } from '@angular/core';

import { ReportClass } from '@modules/reporting/models';
import { ReportService } from '@modules/reporting/services/report.service';
import { CustomFormComponent } from '@services/formBuilder/abstract-custom-form-field';
import { DataToIncludeFormModalComponent } from './../data-to-include-form-modal/data-to-include-form-modal.component';

import { TranslateService } from '@ngx-translate/core';
import { cloneDeep, compact, filter, find, get, includes, map, orderBy } from 'lodash';

@Component({
  selector: 'app-data-to-include-form',
  templateUrl: './data-to-include-form.component.html',
  styleUrls: ['./data-to-include-form.component.scss'],
})
export class DataToIncludeFormComponent extends CustomFormComponent implements OnInit, OnDestroy {

  public btnTitle: string;
  public options: any[];
  public reportID: string;
  public selectedItems: any[] = [];

  constructor(
    private popoverController: PopoverController,
    private translate: TranslateService,
    private reportService: ReportService,
  ) {
    super();
  }

  ngOnInit() {
    const reportData = find(cloneDeep(this.reportService.reports.data), <any>{reportID: +this.reportID});
    if (this.selectedItems && !this.selectedItems.length) {
      this.selectedItems = filter(get(reportData, 'selectors.extraColumns'));
    }
    if (this.selectedItems && this.selectedItems.length) {
      if (!this.selectedItems[0].id) {
        this.selectedItems = map(filter(this.selectedItems), (value) => {
          const reportType = get(reportData, 'type');
          let item = {id: value, description: value};
          if (includes([ReportClass.CheckDetail, ReportClass.ObsDetail, ReportClass.AssetDetail], reportType)) {
            item = find(this.options, ['id', value]);
          }
          return item;
        });
      }
      this.btnTitle = this.translate.instant('SHARED.Manage_Data');
    } else {
      this.btnTitle = this.translate.instant('SHARED.Select_Data');
    }
    // sanitize the array of selectedItems
    this.selectedItems = filter(compact(this.selectedItems));
  }

  ngOnDestroy() {
  }

  public showDataToIncludeForm(): void {
    this.popoverController.create(<any>{
      component: DataToIncludeFormModalComponent,
      animated: false,
      componentProps: {
        options: orderBy(this.options, 'description'),
        selectedItems: this.selectedItems
      }
    }).then((element: HTMLIonPopoverElement) => {
      element.present();

      element.onDidDismiss().then((event) => {
        if (event.data) {
          if (event.data !== this.selectedItems) {
            this.formValue = event.data;
          }
          this.onChanged.emit(event.data);
        }
      });
    });
  }

}
