import { Injectable } from '@angular/core';
import { Router } from '@angular/router';

import { Feature, Module, SubscriberService } from '@services/subscriber/subscriber.service';
import { Permission, PermissionsService } from '@services/permissions/permissions.service';
import { UserdataService } from '@services/userdata/userdata.service';
import { IModuleSubscriberAccess, ModuleAccessService } from '@services/moduleAccess/module-access.service';
import { Brands } from '@services/brand/brand.service';
import { AccountTypes } from '@services';
import { cloneDeep, each, find, get, isEmpty, last, map, some, split } from 'lodash';


export interface MenuItem {
  title: string;
  icon?: string;
  url?: string;
  level?: string | Permission;
  features?: Feature[];
  feature?: Feature;
  module?: Module;
  modules?: Module[];
  brands?: string[];
  accountType?: string;
  isAvailable?: boolean;
  isOpen?: boolean;
  plugin?: string;
  isActive?: boolean;
  class?: string;
  children?: MenuItem[];
  subscriberAccess?: IModuleSubscriberAccess;
  key?: string;
  defaultPageFor?: Brands;

  isValid?(): boolean;
}

@Injectable({
  providedIn: 'root'
})
export class MenuService {

  private brand = 'corvex';

  private menuItems: MenuItem[] = [
    {
      modules: [Module.SITE_AWARENESS, Module.OBSERVATIONS, Module.SUPERVISOR_DASHBOARD, Module.WORKER_STATUS, Module.LEADERBOARDS, Module.CULTURE, Module.ASSET_STATUS, Module.CONTENT_REVIEW],
      features: [Feature.AWARENESS_MESSAGES, Feature.SITE_AWARENESS, Feature.OBSERVATION_ACTIVITY, Feature.SUPERVISOR_DASHBOARD, Feature.WORKER_STATUS, Feature.LEADERBOARDS, Feature.CULTURE_DASHBOARD, Feature.ASSET_STATUS, Feature.CONTENT_REVIEW],
      title: 'LAYOUT.Dashboards',
      icon: 'dashboard',
      level: Permission.Worker,
      children: [
        {
          title: 'SHARED.PPE_Awareness',
          url: 'pages/dashboard/home',
          level: Permission.Admin,
          module: Module.SITE_AWARENESS,
          feature: Feature.PPE_AWARENESS_DASHBOARD,
          subscriberAccess: {
            account: 'site_awareness_user_access',
            permission: 'site_awareness_permission_access',
            user: 'site_awareness_specific_users'
          }
        },
        {
          title: 'LAYOUT.Observation_Activity',
          url: 'pages/dashboard/main',
          level: Permission.Supervisor,
          module: Module.OBSERVATIONS,
          feature: Feature.OBSERVATION_ACTIVITY,
          subscriberAccess: {
            permission: 'observation_activity_permission_access',
            account: 'observation_activity_user_access',
            user: 'observation_activity_specific_users'
          }
        },
        {
          title: 'LAYOUT.Supervisor_Taskboard',
          url: 'pages/dashboard/supervisor-dashboard',
          level: Permission.Supervisor,
          module: Module.SUPERVISOR_DASHBOARD,
          feature: Feature.SUPERVISOR_DASHBOARD,
          isValid: () => get(this.userDataService, 'teams.length', 0) > 0
        },
        {
          title: 'SHARED.Leaderboards',
          url: 'pages/dashboard/leaderboards',
          module: Module.LEADERBOARDS,
          feature: Feature.LEADERBOARDS,
          level: Permission.Admin,
          subscriberAccess: {
            account: 'leaderboards_user_access',
            permission: 'leaderboards_permission_access',
            user: 'leaderboards_specific_users'
          }
        },
        {
          title: 'LAYOUT.Worker_Status',
          url: 'pages/dashboard/worker',
          level: Permission.Supervisor,
          module: Module.WORKER_STATUS,
          feature: Feature.WORKER_STATUS,
          subscriberAccess: {
            permission: 'worker_status_permission_access',
            account: 'worker_status_user_access',
            user: 'worker_status_specific_users'
          }
        },
        {
          title: 'LAYOUT.Worker_Zone_Status',
          url: '/pages/dashboard/worker-zone',
          module: Module.WORKER_STATUS,
          feature: Feature.WORKER_STATUS,
          subscriberAccess: {
            permission: 'worker_status_permission_access',
            account: 'worker_status_user_access',
            user: 'worker_status_specific_users'
          }
        },
        {
          title: 'LAYOUT.Asset_Status',
          url: '/pages/dashboard/asset',
          module: Module.ASSET_STATUS,
          feature: Feature.ASSET_STATUS,
          subscriberAccess: {
            account: 'asset_status_user_access',
            permission: 'asset_status_permission_access',
            user: 'asset_status_specific_users'
          }
        },
        {
          title: 'SHARED.Frontline_Culture',
          url: '/pages/dashboard/frontline-culture',
          module: Module.CULTURE,
          feature: Feature.CULTURE_DASHBOARD,
          subscriberAccess: {
            permission: 'culture_permission_access',
            account: 'culture_user_access',
            user: 'culture_specific_users'
          }
        },
        {
          title: 'CONTENT.Document_Status',
          url: '/pages/dashboard/document-status',
          module: Module.CONTENT_REVIEW,
          feature: Feature.CONTENT_REVIEW,
          subscriberAccess: {
            permission: 'content_review_permission_access',
            account: 'content_review_user_access',
            user: 'content_review_specific_users'
          }
        }
      ]
    },
    {
      title: 'SHARED.Active_Learning',
      icon: `active_learning`,
      class: 'active_learning',
      defaultPageFor: Brands.HOP,
      level: Permission.Supervisor,
      module: Module.ACTIVE_LEARNING,
      key: Module.ACTIVE_LEARNING,
      children: [
        {title: 'SHARED.Explore_Learn', url: 'pages/management/explore', module: Module.EXPLORE},
        {title: 'SHARED.Learning_Topics', url: 'pages/management/topics', module: Module.TOPICS},
        {title: 'SHARED.Learning_Teams', url: 'pages/management/learning-teams', module: Module.LEARNING_TEAMS},
        {title: 'PDCA.Module_Name', url: 'pages/dashboard/pdca-list', module: Module.PDCA, feature: Feature.PDCA},
        {title: 'SHARED.Feedback', url: 'pages/dashboard/feedback', module: Module.INQUIRIES}
      ]
    },
    {
      title: 'SHARED.Observations',
      icon: `${this.subscriber.brandInfo().brand}/submissions`,
      class: 'observations',
      level: Permission.Worker,
      module: Module.OBSERVATIONS,
      key: Module.OBSERVATIONS,
      children: [
        {title: 'SHARED.Unsafe_Conditions', url: 'pages/dashboard/condition-table', module: Module.CONDITION},
        {title: 'SHARED.Asset_Issues', url: 'pages/dashboard/assets', module: Module.ASSET_ISSUES},
        {title: 'SHARED.COACHING_OPPORTUNITIES', url: 'pages/dashboard/coaching-table', module: Module.BEHAVIOR},
        {title: 'SHARED.Quality_Issues', url: 'pages/dashboard/quality-table', module: Module.QUALITY},
        {
          title: 'SHARED.Process_Improvements',
          url: 'pages/dashboard/process-table',
          module: Module.PROCESS_IMPROVEMENT
        },
        {title: 'SHARED.Corrective_Actions', url: 'pages/dashboard/ca-table', module: Module.CHECKS},
        {title: 'SHARED.Thumbs-Up', url: 'pages/dashboard/compliment-table', module: Module.COMPLIMENT, level: Permission.Supervisor},
        {title: 'SHARED.Opportunities', url: 'pages/dashboard/opportunities', module: Module.OPPORTUNITIES}
      ]
    },
    {
      title: 'SHARED.Checks',
      icon: 'checks',
      module: Module.CHECKS,
      class: 'checks',
      level: Permission.Admin,
      children: [
        {title: 'SHARED.Check_Management', url: 'pages/management/checks', module: Module.CHECKS},
        {title: 'SHARED.Available_Checks', url: 'pages/management/responses', module: Module.CHECKS}
      ]
    },
    {
      title: 'SHARED.Communications',
      icon: 'communications',
      modules: [Module.MESSAGING, Module.NOTIFICATIONS],
      url: 'pages/communications-list',
      class: 'communications',
      level: Permission.Worker
    },
    {
      title: 'SHARED.Work_Notes',
      icon: 'workNoteIcon',
      url: 'pages/work-notes',
      module: Module.WORK_NOTES,
      features: [Feature.WN_SHIFT_NOTES, Feature.WN_PERSONAL_NOTES],
      level: Permission.Worker
    },
    {
      title: 'SHARED.Reporting',
      icon: 'reporting',
      module: Module.REPORTING,
      url: 'pages/reporting',
      level: Permission.Supervisor,
      accountType: AccountTypes.Reporting
    },
    {
      title: 'SHARED.Libraries',
      icon: `icon-nav-content-library`,
      level: Permission.Worker,
      modules: [Module.CONTENT, Module.ASSETS, Module.PROPERTIES],
      key: Module.ASSETS,
      children: [
        {title: 'LAYOUT.Assets', url: 'pages/management/assets', level: Permission.Admin, module: Module.ASSETS},
        {title: 'CONTENT.Module_Name', url: 'pages/management/content-list', module: Module.CONTENT, feature: Feature.CONTENT,
          subscriberAccess: {
            permission: 'content_library_permission_access',
            account: 'content_library_user_access',
            user: 'content_library_specific_users'
          }
        },
        {title: 'PROPERTY.Properties', url: 'pages/management/properties', level: Permission.Admin, module: Module.PROPERTIES, feature: Feature.PROPERTIES},
      ]
    },
    {
      title: 'SHARED.Administration',
      icon: 'icon-nav-site-management',
      class: 'site-management',
      level: Permission.Admin,
      children: [
        {title: 'SHARED.USERS', url: 'pages/management/users'},
        {title: 'LAYOUT.Roles', url: 'pages/management/roles', module: Module.ROLES},
        {title: 'SHARED.TEAMS', url: 'pages/management/teams'},
        {title: 'SHARED.Certifications', url: 'pages/management/certifications', module: Module.CERTIFICATIONS},
        {title: 'SHARED.PPE', url: 'pages/management/gear-list', module: Module.PPE},
        {
          title: 'SHARED.Notification_Rules',
          url: 'pages/management/notification-rules',
          modules: [Module.NOTIFICATIONS, Module.OBSERVATIONS],
          // features: [Feature.CONDITION, Feature.PROCESS_IMPROVEMENT, Feature.QUALITY, Feature.COMPLIMENT, Feature.BEHAVIOR]
        },
        {
          title: 'SHARED.Awareness_Messages',
          url: 'pages/management/site-awareness-messaging',
          module: Module.SITE_AWARENESS,
          feature: Feature.AWARENESS_MESSAGES
        },
        {title: 'SHARED.Tag_Management', url: 'pages/management/custom-tags', module: Module.OBSERVATIONS},
        {
          title: 'SHARED.Site_Management',
          url: 'pages/site-management',
          level: Permission.Admin,
          module: Module.LOCATIONS
        },
        {
          title: 'SHARED.Relays',
          url: 'pages/management/relays',
          level: Permission.Corvex,
          module: Module.RELAYS
        },
        {
          title: 'LAYOUT.Work_Temp_Settings',
          url: 'pages/plugins/work-temp',
          plugin: 'ergo-temp',
          module: Module.WORK_TEMP
        },
        {
          title: 'SHARED.External_Data_Management',
          url: 'pages/management/external-data-management',
          module: Module.EXTERNAL_DATA_MANAGEMENT,
          level: Permission.Corvex
        },
      ]
    },
    {
      title: 'LAYOUT.Subscriber_Settings',
      icon: 'settings',
      url: 'pages/subscriber',
      level: Permission.SuperAdmin
    }
  ];

  constructor(
    private userDataService: UserdataService,
    private subscriber: SubscriberService,
    private permissions: PermissionsService,
    private router: Router,
    private moduleAccessService: ModuleAccessService
  ) {
  }

  public getMenuItems(): MenuItem[] {
    return map(cloneDeep(this.menuItems), (item: MenuItem) => {
      this.updateAvailable(item);
      this.checkIsActive(item);
      return item;
    });
  }

  public getMenuItemByKey(key: Module): MenuItem {
    return find(this.getMenuItems(), {key});
  }

  public checkMenuItemByUrl(url: string): boolean {
    const menuItemUrl: string = this.parseMenuItemUrl(url);
    const menuItem = this.findMenuItemByUrl(menuItemUrl);
    return this.checkPermission(menuItem);
    // let isAvailable = true;

    // if (get(menuItem, 'isValid')) {
    //   isAvailable = menuItem.isValid();
    // }

    // return isAvailable;
  }

  public isActive(menuItem: MenuItem): boolean {
    const additionalNavMap: any = {
      'pages/dashboard/quality-table': 'pages/dashboard/quality-detail',
      'pages/dashboard/coaching-table': 'pages/dashboard/coaching-detail',
      'pages/dashboard/condition-table': 'pages/dashboard/condition-detail',
      'pages/dashboard/process-table': 'pages/dashboard/process-detail',
      'pages/dashboard/compliment-table': 'pages/dashboard/compliment-detail',
      'pages/dashboard/ca-table': 'pages/dashboard/ca-detail',
      'pages/dashboard/main': 'pages/dashboard/location-dashboard'
    };
    const matchNavItem: string = additionalNavMap[menuItem.url];

    return this.router.isActive(menuItem.url, false) || (matchNavItem ? this.router.isActive(matchNavItem, false) : false);
  }

  private findMenuItemByUrl(url: string, menuItems: MenuItem[] = this.menuItems): MenuItem {
    let foundMenuItem: MenuItem = null;

    each(menuItems, (menuItem: MenuItem) => {
      if (menuItem.children) {
        const childMenuItem = this.findMenuItemByUrl(url, menuItem.children);
        if (childMenuItem) {
          foundMenuItem = childMenuItem;
          return false;
        }
      } else {
        const menuItemUrl: string = this.parseMenuItemUrl(menuItem.url);
        if (url === menuItemUrl) {
          foundMenuItem = menuItem;
          return false;
        }
      }
    });

    return foundMenuItem;
  }

  private parseMenuItemUrl(url: string): string {
    return last(split(url, 'pages/'));
  }

  private updateAvailable(item: MenuItem): void {
    const isNoPermissionsUser: boolean = this.userDataService.isNoPermissionsUser();

    // assume it is available
    item.isAvailable = this.checkPermission(item);

    each(item.children, (child: MenuItem) => {
      if (!child.level) {
        child.level = item.level;
      }

      this.updateAvailable(child);
    });

    if (some(item.children, (child: MenuItem) => child.module && child.subscriberAccess && child.isAvailable)) {
      item.isAvailable = true;
    }

    if (item.isAvailable && item.isValid) {
      item.isAvailable = item.isValid();
    }
  }

  public checkPermission(item): boolean {
    if (!item) {
      return true;
    }

    const isNoPermissionsUser: boolean = this.userDataService.isNoPermissionsUser();

    // assume it is available
    let isAvailable = true;
    if (this.subscriber.usesPlugin(item.plugin) || !item.plugin) {
      const isPermissionAccessAllowed: boolean = !item.level || this.permissions.canView(<string>item.level);

      isAvailable = (isPermissionAccessAllowed && !isNoPermissionsUser) || this.userDataService.type === item.accountType || !isEmpty(item.subscriberAccess);

      if (isAvailable) {
        if (item.hasOwnProperty('module')) {
          if (!this.subscriber.usesModule(item.module, true)) {
            isAvailable = false;
          }
        } else if (item.hasOwnProperty('modules')) {
          let matched = false;
          each(item.modules, (module) => {
            if (this.subscriber.usesModule(module, true)) {
              matched = true;
            }
          });
          isAvailable = matched;
        }

        if (isAvailable) {
          // this user can see this item... is this feature enabled though
          if (item.hasOwnProperty('feature')) {
            if (!this.subscriber.usesFeature(item.feature)) {
              isAvailable = false;
            }
          } else if (item.hasOwnProperty('features')) {
            let matched = false;
            each(item.features, (feature) => {
              if (this.subscriber.usesFeature(feature, true)) {
                matched = true;
              }
            });
            isAvailable = matched;
          }
        }
      }
    } else if (item.hasOwnProperty('plugin') && !this.subscriber.usesPlugin(item.plugin)) {
      isAvailable = false;
    }

    if (isAvailable && item.subscriberAccess) {
      isAvailable = this.moduleAccessService.hasSubscriberAccess(item.subscriberAccess, isNoPermissionsUser);

      // if (this.moduleAccessService.isAllowedAccount()) {
      //   item.isAvailable = !item.level || this.permissions.canView(<string>item.level) || this.moduleAccessService.isAccountPermitted(item.subscriberAccess);
      // }
    }

    each(item.children, (child: MenuItem) => {
      if (!child.level) {
        child.level = item.level;
      }

      this.updateAvailable(child);
    });

    if (some(item.children, (child: MenuItem) => child.module && child.subscriberAccess && child.isAvailable)) {
      isAvailable = true;
    }

    if (isAvailable && item.isValid) {
      isAvailable = item.isValid();
    }
    return isAvailable;
  }

  private checkIsActive(item: MenuItem): void {
    if (item.children) {
      item.isActive = false;
      item.isOpen = false;

      each(item.children, (child: MenuItem) => {
        child.isActive = this.isActive(child);

        if (child.isActive) {
          item.isActive = true;
          item.isOpen = true;
        }
      });
    } else if (item.url) {
      item.isActive = this.isActive(item);
    }
  }
}
