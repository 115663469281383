import { Router } from '@angular/router';
import { Location } from '@angular/common';
import { ModalController } from '@ionic/angular';
import { Component, EventEmitter, Injector, Input, OnDestroy, Output } from '@angular/core';

import { AbstractModalCloseHandler } from '@shared/components/abstractModalCloseHandler/abstractModalCloseHandler';

import { SubscriptionLike } from 'rxjs';

@Component({
  selector: 'app-close-button',
  templateUrl: './close-button.component.html',
  styleUrls: ['./close-button.component.scss'],
})
export class CloseButtonComponent extends AbstractModalCloseHandler implements OnDestroy {
  @Input() backUrl: string;
  @Input() isModal: boolean;
  @Output() onClicked: EventEmitter<void> = new EventEmitter<void>();

  private locationSubscription: SubscriptionLike;

  constructor(
    private location: Location,
    private modalController: ModalController,
    private router: Router,
    protected injector: Injector
  ) {
    super(injector);
    this.initListeningLocationChange();
  }

  ngOnDestroy() {
    this.locationSubscription.unsubscribe();
  }

  public back(): void {
    if (this.onClicked?.observers?.length) {
      this.onClicked.emit();
    } else if (this.isModal) {
      this.modalController.dismiss();
    } else {
      this.navigateBack();
    }
  }

  private navigateBack() {
    this.backUrl ? this.router.navigate([this.backUrl]) : this.location.back();
  }

  private initListeningLocationChange() {
    this.locationSubscription = this.location.subscribe(() => {
      if (this.location.isCurrentPathEqualTo(this.router.url)) {
        this.setNavigationState(false);
      }
    });
  }
}
