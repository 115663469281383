import { MenuController } from '@ionic/angular';
import { ElementRef, HostListener, Injectable, Injector } from '@angular/core';

@Injectable({ providedIn: 'root' })
export abstract class AbstractModalCloseHandler {
  protected isModal: boolean;
  private isBackNavigationActivated: boolean;

  protected menuController: MenuController = this.injector.get(MenuController);
  protected elementRef: ElementRef = this.injector.get(ElementRef);

  constructor(protected injector: Injector) {}

  public abstract back();

  @HostListener('document:keydown.escape', ['$event']) async onKeyDownHandler() {
    const isElementVisible: boolean = this.elementRef.nativeElement.offsetParent;
    const isPopoverNotActive: boolean = document.getElementsByTagName('ion-popover').length === 0;
    const isModalNotActive: boolean = document.getElementsByTagName('ion-modal').length === 0;
    const isMenuNotActive: boolean = !(await this.menuController.isOpen());

    if (!this.isBackNavigationActivated && isElementVisible && isPopoverNotActive && isModalNotActive && isMenuNotActive) {
      this.setNavigationState();

      setTimeout(() => {
        this.back();
      }, 200);
    }
  }

  @HostListener('window:popstate', ['$event'])
  onPopState() {
    if (this.isModal) {
      this.back();
    }
  }

  public setNavigationState(activated = true) {
    this.isBackNavigationActivated = activated;
  }
}
