<div class="selected-items">
  <ol>
    <ng-container *ngFor="let item of selectedItems">
      <li *ngIf="item?.description">
        <span class="item-info description">{{item.description}}</span>
      </li>
    </ng-container>
  </ol>
</div>
<a (click)="showQuestionsToIncludeForm()" class="popup-open-button operation ui-btn button-styled">{{btnTitle}}</a>

