import { Component, OnInit } from '@angular/core';

import { CompareValue } from './model';
import { CustomFormComponent } from '@services/formBuilder/abstract-custom-form-field';

import { includes } from 'lodash';
import { TranslateService } from '@ngx-translate/core';

@Component({
  selector: 'app-compare-value',
  templateUrl: './compare-value.component.html',
  styleUrls: ['./compare-value.component.scss'],
})
export class CompareValueComponent extends CustomFormComponent implements OnInit {
  public selectedOption: string;
  public minValue: number;
  public maxValue: number;
  public data = [
    { id: CompareValue.EqualTo, text: this.translate.instant('SHARED.Equal_to') },
    { id: CompareValue.NotEqualTo, text: this.translate.instant('SHARED.Not_equal_to') },
    { id: CompareValue.GreaterThan, text: this.translate.instant('SHARED.Greater_than') },
    { id: CompareValue.LessThan, text: this.translate.instant('SHARED.Less_than') },
    { id: CompareValue.GreaterThanEqualLessThanEqual, text: this.translate.instant('SHARED.Greater_than_equal_Less_than_equal') },
    { id: CompareValue.GreaterThanLessThan, text: this.translate.instant('SHARED.Greater_than_Less_than') }
  ];
  public isRange: boolean;
  public options = {
    minimumResultsForSearch: Infinity,
    placeholder: this.translate.instant('DASHPAGES.CHANGING_TYPE_TS_placeholder'),
    sorter: (data) => data
  };

  constructor(private translate: TranslateService) {
    super();
  }

  public ngOnInit() {
    this.selectedOption = this.formValue?.id || CompareValue.EqualTo;
    this.minValue = this.formValue?.min;
    this.maxValue = this.formValue?.max;

    this.defineRangeOptions();
  }

  public onTypeChange(selection: { value: string }) {
    this.selectedOption = selection.value;
    this.defineRangeOptions();
    this.emitChanges();
  }

  public onChangedValue(value: number, isMin = true) {
    if (isMin) {
      this.minValue = value;
    } else {
      this.maxValue = value;
    }

    this.emitChanges();
  }

  private emitChanges() {
    if (this.selectedOption) {
      if (this.isRange) {
        if (this.minValue && this.maxValue) {
          this.formValue = { id: this.selectedOption, min: this.minValue, max: this.maxValue };
        }
      } else if (this.minValue) {
        this.formValue = { id: this.selectedOption, min: this.minValue };
      }
    }
  }

  private defineRangeOptions() {
    this.isRange = includes(['greaterThanEqualLessThanEqual', 'greaterThanLessThan'], this.selectedOption);
  }
}
