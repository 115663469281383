import { NGXLogger } from 'ngx-logger';
import { Injectable, Injector } from '@angular/core';
import { FormField } from '@services';
import { BaseField, ReportBaseClass, ReportClass } from '@modules/reporting/models/types/base';
import { WorkerModelService } from '@modules/reporting/models/types/worker/service';
import { DataToIncludeFormComponent } from './../../../components/data-to-include-form/data-to-include-form.component';
import * as moment from 'moment-timezone';
import { awaitHandler } from '@utils/awaitHandler';
import { SelectFiltersFormComponent } from '@modules/reporting/components';
import { cloneDeep, difference, each, filter, find, forEach, get, map, merge, orderBy } from 'lodash';

@Injectable()
export class WorkerReport extends ReportBaseClass {

  public workerModelService: WorkerModelService = this.injector.get(WorkerModelService);
  private logger: NGXLogger = this.injector.get(NGXLogger);

  public reportColumnOptions = this.workerModelService.getColumns();
  public reportFieldOptions = this.workerModelService.getFieldOptions();
  public reportType = ReportClass.Worker;
  public dataToIncludeFields: any[] = [];

  filtersFields = [
    // user status check boxes
    {
      containerClass: 'report-field obsdets check checkDetail checkType worker',
      title: this.translate.instant('REPORTING.User_Status'),
      name: 'userStatus',
      type: 'checkbox',
      role: 'none',
      class: 'inline-checkboxes',
      multiple: true,
      canDelete: true,
      placeholder: 'Any Status',
      options: [
        {
          id: 'active',
          description: this.translate.instant('SHARED.Active')
        },
        {
          id: 'deactivated',
          description: this.translate.instant('REPORTING.User_Deactivated')
        },
      ],
      onRemoveField: (field: any) => this.onRemoveField(field),
    },
    // end user status check boxes

    // activity types check boxes
    {
      containerClass: 'report-field obsdets check checkDetail checkType worker',
      title: this.translate.instant('REPORTING.Activity_Types'),
      name: 'activityTypes',
      type: 'checkbox',
      role: 'none',
      class: 'inline-checkboxes',
      multiple: true,
      canDelete: true,
      placeholder: 'Any Results',
      options: [
        {
          id: 'gear',
          description: this.translate.instant('SHARED.PPE')
        },
        {
          id: 'condition',
          description: this.translate.instant('SHARED.Unsafe_Condition')
        },
        {
          id: 'behavior',
          description: this.translate.instant('SHARED.Coaching_Opportunity')
        },
        {
          id: 'quality',
          description: this.translate.instant('SHARED.CHANGING_TYPE_TS_quality')
        },
        {
          id: 'pi',
          description: this.translate.instant('REPORTING.EDIT_pi')
        },
        {
          id: 'compliment',
          description: this.translate.instant('SHARED.Thumbs-Up')
        },
        {
          id: 'ca',
          description: this.translate.instant('SHARED.Corrective_Actions')
        },
        {
          id: 'response',
          description: this.translate.instant('REPORTING.Check_Completion')
        },
        {
          id: 'tier',
          description: this.translate.instant('REPORTING.Tier_Achievement')
        },
        {
          id: 'session',
          description: this.translate.instant('REPORTING.Login_Logout')
        },
        {
          id: 'consent',
          description: this.translate.instant('REPORTING.Consent')
        }
      ],
      onRemoveField: (field: any) => this.onRemoveField(field),
    },
    //  end activity types check boxes
    {
      containerClass: 'report-field obsdets team zone observation PPESummary worker',
      title: this.translate.instant('SHARED.Locations'),
      name: 'locations',
      type: 'selectmenu',
      placeholder: this.translate.instant('SHARED.All_Locations'),
      multiple: true,
      canDelete: true,
      valueProperty: 'locationID',
      options: this.locations,
      func: (ref: any) => ref.name,
      onRemoveField: (field: any) => this.onRemoveField(field),
      test: (ref) => {
        if (ref.hasOwnProperty('disabledAt') && ref.disabledAt) {
          return false;
        } else {
          return true;
        }
      },
      onChange: (locationIds) => {
        this.syncFieldsBySelectedLocations(map(locationIds, Number));
      }
    },
    {
      containerClass: 'report-field obsdets team zone observation, worker',
      title: this.translate.instant('SHARED.Zones'),
      name: 'zones',
      type: 'selectmenu',
      multiple: true,
      canDelete: true,
      placeholder: this.translate.instant('SHARED.Any_Zone'),
      options: this.zones,
      onRemoveField: (field: any) => this.onRemoveField(field),
      func: (ref) => this.showDeactivated(ref.id, ref)
    },
    {
      containerClass: 'report-field team zone check checkDetail, worker',
      title: this.translate.instant('REPORTING.EDIT_Workers'),
      name: 'users',
      type: 'selectmenu',
      multiple: true,
      canDelete: true,
      placeholder: this.translate.instant('SHARED.EDIT_Any_Worker'),
      valueProperty: 'userID',
      originalOrder: true,
      options: this.getWorkerOptions(),
      func: (ref: any) => this.userService.getFullname(ref.userID),
      onRemoveField: (field: any) => this.onRemoveField(field),
      test: (ref) => {
        if (ref?.disabledAt || !ref?.active) {
          return false;
        } else {
          return true;
        }
      },
    },
    {
      containerClass: 'report-field team zone check checkDetail, worker',
      title: this.translate.instant('SHARED.EDIT_Permissions'),
      name: 'permissions',
      type: 'selectmenu',
      multiple: true,
      canDelete: true,
      placeholder: this.translate.instant('SHARED.EDIT_Any_Permissions'),
      valueProperty: 'id',
      options: this.permissionsService.permissions.data,
      onRemoveField: (field: any) => this.onRemoveField(field),
      test: (ref) => this.filterPermission(ref)
    },
    { //teams
      containerClass: 'report-field worker',
      title: this.translate.instant('REPORTING.Worker_Primary_team'),
      name: 'workerGroups',
      type: 'selectmenu',
      placeholder: this.translate.instant('SHARED.Any_Team'),
      multiple: true,
      canDelete: true,
      valueProperty: 'groupID',
      options: this.getTeamOptions(),
      func: (ref: any) => ref.name,
      onRemoveField: (field: any) => this.onRemoveField(field),
      test: (ref) => {
        if (ref.hasOwnProperty('disabledAt') && ref.disabledAt) {
          return false;
        } else {
          return true;
        }
      },
      originalOrder: true
    },
  ];

  public fields = [
    this.baseFields[BaseField.Timespan],
    this.baseFields[BaseField.LocationTime],
    {
      containerClass: 'custom-data-field report-field worker',
      title: this.translate.instant('REPORTING.EDIT_Data_to_Include'),
      name: 'workerDetailReportColumns',
      type: 'customElement',
      component: DataToIncludeFormComponent,
      inputs: {
        options: cloneDeep(this.reportColumnOptions)
      },
      outputs: {
        onChanged: (selectedItems) => {
          const field = find(this.formConfig.fields, {name: 'workerDetailReportColumns'}) as FormField;
          (field.componentRef as DataToIncludeFormComponent).selectedItems = selectedItems;
          this.dataToIncludeFields = selectedItems;
        }
      },
      options: cloneDeep(this.reportColumnOptions)
    },
    this.baseFields[BaseField.IncludingAll],
    {
      containerClass: 'report-field obsdets observation check checkDetail ccsFormSubdivider',
      title: this.translate.instant('REPORTING.EDIT_Report_Data_Selection'),
      type: 'divider',
    },
    {
      containerClass: 'custom-data-field report-field worker',
      title: this.translate.instant('SHARED.Select_Filters'),
      name: 'filtersFields',
      type: 'customElement',
      component: SelectFiltersFormComponent,
      inputs: {
        options: cloneDeep(this.filtersFields),
        selectedItems: []
      },
      outputs: {
        onChanged: (selectedItems) => {
          const field = <FormField>find(this.formConfig.fields, {name: 'filtersFields'});
          (field.componentRef as SelectFiltersFormComponent).selectedItems = selectedItems;
          this.removedFilters = difference(this.filters, selectedItems);
          this.filters = selectedItems;
          this.showFilters();
        }
      },
      options: cloneDeep(this.filtersFields)
    },
  ];


  constructor(protected injector: Injector) {
    super(injector);
  }

  public init(reportData) {
    this.dataToIncludeFields = get(reportData, 'selectors.extraColumns') || [];
    const workerDetailReportColumns: any = find(this.formConfig.fields, {name: 'workerDetailReportColumns'});
    workerDetailReportColumns.inputs.reportID = this.messageID;
  }

  public async report(theTable, theChart, report, reportId?: number) {
    this.reportData = report;
    const opts = cloneDeep(report.selectors);
    const timeByTimespan = this.getTimeByTimespan(opts.timespan, opts.startTime, opts.endTime);
    opts.startTime = timeByTimespan.startTime;
    opts.endTime = timeByTimespan.endTime;
    let [r] = await awaitHandler(this.findInterval(opts));

    const tableDef = merge({}, this.tableDef);

    // let's start building that table
    const pRef: any = find(this.reportFieldOptions, {id: opts.primary});

    // if (pRef) {
    //   tableDef.columns.push({
    //     id: pRef.id,
    //     title: pRef.label,
    //     fromID: pRef.fieldName,
    //     headerClass: 'text-left',
    //     class: 'tableexport-string'
    //   });
    // }

    const totalGroup = opts.period === 'none' ? null : 'total';

    if (opts.extraColumns) {
      each(opts.extraColumns, (colname) => {
        const c: any = find(this.reportColumnOptions, {id: colname.id});
        let t = '';
        if (c.hasOwnProperty('cellType')) {
          t += ' tableexport-' + c.cellType;
        } else {
          t += ' tableexport-string';
        }
        tableDef.columns.push({
          id: c.id,
          title: c.label,
          fromID: c.id,
          headerClass: 'text-left',
          colgroup: totalGroup,
          class: t,
          minwidth: '75px',
          func: c.func
        });
      });
    }

    $('.report-view-has-chart').hide();

    if (tableDef.columns.length) {
      r = this.sortDataByColumn(tableDef.columns[0].id, r);
      tableDef.data = r;
      tableDef.columns = this.preparationColumns(tableDef.columns);
      this.tableService.showDataTable(theTable, tableDef);
    }
  }

  public sortDataByColumn(colName, data) {
    if (colName === 'timestamp') {
      data = orderBy(data, 'when', 'desc');
    } else if (colName === 'activity' || colName === 'type' || colName === 'workerPoint') {
      data = orderBy(data, colName, ['asc', 'desc']);
    } else {
      forEach(data, (ref) => {
        ref.label = '';
        if (colName === 'user') {
          ref.label = this.accountsService.fullname(+ref.userID);
        } else if (colName === 'location') {
          if (ref.locationID) {
            const loc = this.userService.getLocation(ref.locationID);
            if (loc) {
              ref.label = loc.name;
            }
          }
        } else if (colName === 'zone') {
          const zoneObj = this.userService.findAnyZone(this.userService.getLocation(ref.locationID), ref.zoneID);
          if (zoneObj) {
            ref.label = zoneObj.name;
          }
        } else if (colName === 'observationID') {
          if (ref.relatedData) {
            ref.label = ref.relatedData.oid;
          }
        } else if (colName === 'shift') {
          if (ref.relatedData) {
            const shiftData = this.shift.get(ref.relatedData.creator_shift);
            if (shiftData) {
              const r = this.userService.getLocation(ref.locationID);
              if (r) {
                ref.label = r.name + ' ' + shiftData.name;
              } else {
                ref.label = ' ' + shiftData.name;
              }
            }
          }
        } else if (colName === 'primaryTeam') {
          ref.label = this.teamsService.teamNameByID(ref.groupID);
        }
      });
      data = orderBy(data, 'label', ['asc', 'desc']);
    }
    return data;
  }

  public async findInterval(opts, items?) {

    const reqObject: any = {};
    const workerPoint = find(opts.extraColumns, {id:'workerPoint'});

    each(['locations', 'zones', 'users', 'permissions', 'groups'], val => {
      reqObject[val] = JSON.stringify(opts[val]);

    });

    if (opts.activityTypes?.length) {
      reqObject.eventTypes = JSON.stringify(opts.activityTypes);
    }

    reqObject.groups = JSON.stringify(opts.workerGroups);
    reqObject.startTime = this.utils.toSeconds(opts.startTime);
    reqObject.endTime = this.utils.toSeconds(opts.endTime);
    reqObject.locationTime = opts.locationTime ?? 0;
    reqObject.tz = moment.tz.guess();

    let [users] = await awaitHandler(this.reportService.getWorkerEvents(reqObject));

    if (opts.includingAll !== 'include' && workerPoint) {
      users = map(users, (userEvents) => {
        return filter(userEvents, 'value');
      });
    }

    // let's flat out the object collections
    // each user-event will have its own row

    const userEvents = [];
    each(users, (val, key) => {
      each(val, uActivity => {
        userEvents.push(uActivity);
      });
    });


    return userEvents;

  }

  public async getTableItems(queryParams: any) {
    this.logger.log('starting from here');
    // TODO add logic of getting table data
  }

}
