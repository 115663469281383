import { OverlayEventDetail } from '@ionic/core';
import { PopoverController } from '@ionic/angular';
import { Component, Input, OnInit } from '@angular/core';

import { Asset } from '@services/assets/asset.interfaces';
import { IProperty } from '@services/property/property-model.interfaces';
import { CustomFormComponent } from '@services/formBuilder/abstract-custom-form-field';
import { ITreeViewModalConfig, TreeViewNodeIdType } from '@shared/modules/tree-viewer/models';
import { FolderPickerService } from '@modules/management/modules/folders/services/folder-picker.service';
import { AssetsService, FoldersDataService, ObjectItem, ObjectsService, PropertyService } from '@services';
import {
  ViewModalTreePopupComponent
} from '@shared/modules/tree-viewer/view-modals/view-modal-popup/view-modal-popup.component';
import {
  FolderDataType,
  FolderPickerConfig,
  FolderPickerEntities,
  FolderPickerItem,
  FolderPickerSelection
} from '@modules/management/modules/folders/model/folders.interfaces';

import { assign, filter, join, map, reject, split } from 'lodash';

@Component({
  selector: 'app-folder-picker',
  templateUrl: './folder-picker.component.html',
  styleUrls: ['./folder-picker.component.scss'],
})
export class FolderPickerComponent extends CustomFormComponent implements OnInit {
  @Input() set selectedIds(ids: FolderPickerSelection[]) {
    if (ids) {
      this.selectedEntities = ids;
    }
  }
  @Input() type: FolderDataType;
  @Input() treeViewTitle: string;
  @Input() showTreeButtonTitle = 'MGMT_LIST.Edit_Selections';
  @Input() hideSelectedList: boolean;
  @Input() disableParentSelection: boolean;
  @Input() folderPickerConfig: FolderPickerConfig;

  public selectedItems: { label: string; isFolder?: boolean }[] = [];
  public getSourceItems: () => FolderPickerItem[];

  private folderEntities: FolderPickerEntities = {};
  private selectedEntities: FolderPickerSelection[];

  constructor(
    private assetsService: AssetsService,
    private folderPickerService: FolderPickerService,
    private popoverController: PopoverController,
    private foldersDataService: FoldersDataService,
    private objectsService: ObjectsService,
    private propertyService: PropertyService
  ) {
    super();
  }

  ngOnInit() {
    this.selectedEntities ??= this.formValue;
    this.defineSelectedItems();
  }

  public showTree() {
    this.popoverController.create({
      component: ViewModalTreePopupComponent,
      animated: false,
      componentProps: {
        config: <ITreeViewModalConfig>{
          title: this.getTreeViewTitle(),
          nodes: this.folderPickerService.getTreeNodes(this.getTreeConfigByType()),
          multiselect: true,
          disableParentSelection: this.disableParentSelection
        }
      }
    }).then((element: HTMLIonPopoverElement) => {
      element.present();

      element.onDidDismiss().then((event: OverlayEventDetail<TreeViewNodeIdType[]>) => {
        if (event.data) {
          this.onSelection(event.data);
        }
      });
    });
  }

  private emitChanges() {
    this.formValue = this.selectedEntities;
  }

  public resetSelection() {
    this.onSelection([]);
  }

  private getTreeConfigByType(): FolderPickerConfig {
    const sourceData = this.getSourceItems && this.getSourceItems();
    const config: FolderPickerConfig = {
      dataType: this.type,
      entities: this.folderEntities,
      selectedIds: this.selectedEntities,
      parentIds: [],
      getFolderItems: () => [],
      getItemId: (item: FolderPickerSelection) => item?.itemID,
      getFolderId: (folder) => folder?.folderID
    };

    if (this.type === FolderDataType.ASSET) {
      assign(config, {
        selectAllLabel: 'MGMT_LIST.All_Assets',
        getFolderItems: () => sourceData || reject(this.assetsService.getLocalAssets(), 'disabledAt'),
        getItemId: (asset: Asset) => asset?.assetID,
        getItemTitle: (asset: Asset) => asset?.name,
      });
    } else if (this.type === FolderDataType.CONTENT) {
      assign(config, {
        getFolderItems: () => sourceData || this.objectsService.filterData(this.objectsService.getCachedObjectByAlias('content'), true),
        getItemId: (object: ObjectItem | FolderPickerSelection) => (object as ObjectItem)?.objectID || (object as FolderPickerSelection)?.itemID,
        getItemTitle: (object: ObjectItem) => object?.description,
      });
    } else if (this.type === FolderDataType.PROPERTY) {
      assign(config, {
        getFolderItems: () => sourceData || this.propertyService.getProperties(),
        getItemId: (property: IProperty | FolderPickerSelection) => (property as IProperty)?.propertyID || (property as FolderPickerSelection)?.itemID,
        getItemTitle: (property: IProperty) => property?.title,
      });
    }

    if (this.folderPickerConfig) {
      assign(config, this.folderPickerConfig);
    }

    return config;
  }

  private onSelection(data: TreeViewNodeIdType[]) {
    if (this.type === FolderDataType.ASSET) {
      this.selectedEntities = map(data, (nodeId): FolderPickerSelection => {
        const folderID = +split(nodeId as string, FolderPickerService.folderIdPrefix)?.[1];

        if (isNaN(folderID)) {
          return { assetID: +nodeId };
        } else {
          return { folderID };
        }
      });
    } else {
      this.selectedEntities = map(data, (nodeId): FolderPickerSelection => {
        const folderID = +split(nodeId as string, FolderPickerService.folderIdPrefix)?.[1];

        if (isNaN(folderID)) {
          return { itemID: +nodeId };
        } else {
          return { folderID };
        }
      });
    }

    this.defineSelectedItems();
    this.emitChanges();
  }

  private defineSelectedItems() {
    const config = this.getTreeConfigByType();
    this.selectedItems = filter(map(this.selectedEntities, (selectedEntity) => {
      let selectedItem = { label: '', isFolder: false };

      if (selectedEntity.assetID) {
        selectedItem.label = this.assetsService.getAssetById(selectedEntity.assetID)?.[0]?.name;
      } else if (selectedEntity.itemID) {
        if (this.type === FolderDataType.CONTENT) {
          selectedItem.label = this.objectsService.getCachedObjectById(selectedEntity.itemID, 'content', true)?.description;
        } else if (this.type === FolderDataType.PROPERTY) {
          selectedItem.label = this.propertyService.getPropertyById(selectedEntity.itemID)?.title;
        }
      } else if (selectedEntity.folderID) {
        if (this.foldersDataService.isActive(selectedEntity.folderID)) {
          selectedItem.label = join(this.foldersDataService.getFolderPath(selectedEntity.folderID, true), '/ ');
          selectedItem.isFolder = true;
        } else {
          // this item shouldn't be here - it is not selectable
          return;
        }
      } else {
        selectedItem.label = config.selectAllLabel || 'SHARED.All';
        selectedItem.isFolder = true;
      }

      return selectedItem;
    }), 'label');
  }

  private getTreeViewTitle(): string {
    let title = '';

    if (this.type === FolderDataType.ASSET) {
      title = 'MGMT_DETAILS.Asset_Targets';
    } else if (this.type === FolderDataType.PROPERTY) {
      title = 'PROPERTY.Properties';
    }

    if (this.treeViewTitle) {
      title = this.treeViewTitle;
    }

    return title;
  }
}
