import { Injectable } from '@angular/core';

import { IObjectStringKeyMap } from '@shared/models';
import { Events } from '@services/events/events.service';
import { IncrementEntryFieldsModel } from '@modules/management/pages/details/check/models';
import {
  Asset,
  IPropertyMeta,
  IPropertyValue,
  IPropertyValueZone,
  PropertyActivity
} from '@services/assets/asset.interfaces';
import {
  AssetsService,
  AssetState,
  CommsService,
  FoldersDataService,
  ISelectMenuItem,
  Module,
  Permission,
  PermissionsService,
  SubscriberService,
  UserdataService,
  UtilsService
} from '@services';
import {
  IProperty,
  IPropertyEvent,
  IPropertyEventActivity,
  IPropertyEventDurationValue,
  IPropertyEventMeasurementValue,
  IPropertyEventRequestsParams,
  IPropertyEventValue,
  LimitType,
  PropertyCategory,
  PropertyMethod,
  PropertySource,
  PropertyStyle,
  PropertySubject,
  ThresholdType
} from './property-model.interfaces';

import { MarkdownService } from 'ngx-markdown';
import { TranslateService } from '@ngx-translate/core';
import {
  cloneDeep,
  flatten,
  isArray,
  indexOf,
  each,
  filter,
  find,
  includes,
  intersection,
  isUndefined,
  map,
  some,
  isObject,
  last,
  has
} from 'lodash';

@Injectable({
  providedIn: 'root'
})
export class PropertyService {
  public aiStatuses = {
    [AssetState.Down]: {
      type: 'red',
      title: 'PROPERTY.State_Down'
    },
    [AssetState.Strained]: {
      type: 'yellow',
      title: 'PROPERTY.State_Strained'
    },
    [AssetState.Up]: {
      type: 'green',
      title: 'PROPERTY.State_Up'
    }
  };

  private properties = {
    lastRequest: null,
    data: {} as IObjectStringKeyMap<IProperty>
  };

  constructor(
    private commsService: CommsService,
    private events: Events,
    private subscriberService: SubscriberService,
    private permissionService: PermissionsService,
    private userData: UserdataService,
    private translate: TranslateService,
    private utils: UtilsService,
    private markdownService: MarkdownService,
    private assetsService: AssetsService,
    private foldersDataService: FoldersDataService
  ) {}

  public refresh(): Promise<IObjectStringKeyMap<IProperty>> {
    const requestData: any = {
      cmd: 'getProperties',
      lastRequest: this.properties.lastRequest,
    };

    return this.commsService.sendMessage(requestData, false, false).then((response) => {
      if (response?.reqStatus === 'OK') {
        this.updateCache(response);
      }

      return this.properties.data;
    });
  }

  public updateCache(data) {
    this.properties.data = data.result.properties;
    this.properties.lastRequest = data.result.timestamp;
    this.events.publish('ccs:propertiesUpdate', true);
  }

  public getPropertiesAsList(folderID: number = 0, includeInactive: boolean = false, includeDisabled: boolean = false, sifter?: (item: IProperty) => boolean ): IProperty[] {
    const theList: IProperty[] = [];

    each(this.properties.data, (item) => {
      if (!isUndefined(folderID) && (item.folderID !== folderID)) {
        return;
      }

      if (!includeInactive && !item.active) {
        return;
      }
      if (!includeDisabled && item.disabledAt) {
        return;
      }
      if (!isUndefined(sifter) && !sifter(item)) {
        return;
      }
      theList.push(item);
    });

    return theList;
  }

  public getPropertiesByIds(ids: IPropertyMeta[]): IProperty[] {
    return filter(map(ids, (id) => this.getPropertyById(id.propertyID)));
  }
  public getProperties(): IObjectStringKeyMap<IProperty> {
    return this.properties.data;
  }

  public getPropertyEvents(propertyID: number, subjectID: number | string, subject: PropertySubject, activity?: string): Promise<IPropertyEvent[]> {
    const requestData = {
      cmd: 'getPropertyEvents',
      propertyID,
      subjectID,
      subject,
      activity
    };
    return this.commsService.sendMessage(requestData, false, false).then((response) => {
      if (response?.reqStatus === 'OK') {
        return response?.result?.events || [];
      }
    });
  }

  public async getPropertyWithEvents(propertyID: number, limit?: number): Promise<IProperty> {
    const requestData: IObjectStringKeyMap<any> = {
      cmd: 'getProperties',
      propertyID,
      includeEvents: 1
    };

    if (limit) {
      requestData.limit = limit;
    }

    return this.commsService.sendMessage(requestData, false, false).then((response) => {
      if (response?.reqStatus === 'OK') {
        if (has(response?.result?.properties, propertyID)) {
          return response.result.properties[propertyID];
        } else {
          return this.getPropertyById(propertyID);
        }
      }
    });
  }

  public getPropertyById(id: number): IProperty {
    return cloneDeep(this.properties.data[id]);
  }

  public folderPath(property: IProperty): string {
    let propertyPath = '';

    if (property.folderID) {
      const folderPath = this.foldersDataService.getFolderPath(property.folderID);

      each(folderPath, (path) => {
        propertyPath = `/${path}${propertyPath}`;
      });
    }

    return propertyPath;
  }

  public addProperty(data: any): Promise<any> {
    const requestData = {
      cmd: 'addProperty',
      ...data
    };
    return this.commsService.sendMessage(requestData, false, false);
  }

  public async updateProperty(data: any): Promise<any> {
    const requestData = {
      cmd: 'updateProperty',
      ...data
    };
    const result = await this.commsService.sendMessage(requestData, false, false);
    await this.refresh();
    return result;
  }

  public deleteProperty(ids: number[] | number | string): Promise<any> {
    const requestData = {
      cmd: 'deleteProperty',
      properties: flatten([ids])
    };

    return this.commsService.sendMessage(requestData, false, false);
  }

  public capturePropertyEvents(params: IPropertyEventRequestsParams): Promise<any> {
    const requestData = {
      cmd: 'capturePropertyEvents',
      sendTime: Date.now(),
      ...params
    };

    return this.commsService.sendMessage(requestData, false, false);
  }

  public getLimitTypeList(): ISelectMenuItem[] {
    return [
      { id: LimitType.Healthy, description: 'PROPERTY.Healthy'},
      { id: LimitType.Strained, description: 'PROPERTY.Strained'},
    ];
  }
  public getThresholdTypeList(category?: PropertyCategory): ISelectMenuItem[] {
    const ret = [];
    if (category === PropertyCategory.InService || category === PropertyCategory.ContentAvailable) {
      ret.push(
        { id: ThresholdType.Selector, description: 'PROPERTY.Selector'},
      );
    } else if (category === PropertyCategory.ReapprovalDate || category === PropertyCategory.ReviewDate) {
      // this is something that will be reapproved or renewed; the thresholds need to be dates
      ret.push({ id: ThresholdType.Interval, description: 'PROPERTY.TimeUntil'});
    } else {
    ret.push (
      { id: ThresholdType.Toggle, description: 'PROPERTY.Boolean'},
      { id: ThresholdType.Ascending, description: 'PROPERTY.Ascending'},
      { id: ThresholdType.Descending, description: 'PROPERTY.Descending'},
    );
    }
    return ret;
  }

  public getCalculationMethodList(limit?: PropertySource): ISelectMenuItem[] {
    const ret = [];
    if (!limit) {
      ret.push(
      { id: PropertyMethod.Latest, description: 'PROPERTY.Latest'},
      { id: PropertyMethod.Highest, description: 'PROPERTY.Highest'},
      { id: PropertyMethod.Lowest, description: 'PROPERTY.Lowest'},
      { id: PropertyMethod.Cumulative, description: 'PROPERTY.Cumulative'},
      { id: PropertyMethod.Average, description: 'PROPERTY.Average'},
      );
    } else {
      if (limit === PropertySource.Derived) {
        ret.push(
          { id: PropertyMethod.Cumulative, description: 'PROPERTY.Cumulative'},
        );
      } else if (limit === PropertySource.OverTime) {
        ret.push(
          { id: PropertyMethod.Cumulative, description: 'PROPERTY.Cumulative'},
        );
      } else {
      ret.push(
        { id: PropertyMethod.Latest, description: 'PROPERTY.Latest'},
        { id: PropertyMethod.Highest, description: 'PROPERTY.Highest'},
        { id: PropertyMethod.Lowest, description: 'PROPERTY.Lowest'},
        { id: PropertyMethod.Cumulative, description: 'PROPERTY.Cumulative'},
        { id: PropertyMethod.Average, description: 'PROPERTY.Average'},
      );
      }
    }
    return ret;
  }

  public getSourceList(limit?: string): ISelectMenuItem[] {
    const ret = [];
    if (!limit) {
      ret.push(
        { id: PropertySource.Manual, description: 'PROPERTY.Manual' },
        { id: PropertySource.Hybrid, description: 'PROPERTY.Hybrid' },
        { id: PropertySource.Derived, description: 'PROPERTY.Derived' },
        { id: PropertySource.TimeUntil, description: 'PROPERTY.TimeUntil' },
        { id: PropertySource.OverTime, description: 'PROPERTY.OverTime' },
        { id: PropertySource.Pull, description: 'PROPERTY.Pull' },
        { id: PropertySource.Push, description: 'PROPERTY.Push' },
      );
    } else {
      if (limit.match(/^check:/)) {
        ret.push({ id: PropertySource.OverTime, description: 'PROPERTY.OverTime' });
      } else if (limit.match(/^content:/)) {
        ret.push({ id: PropertySource.TimeUntil, description: 'PROPERTY.TimeUntil' });
      } else if (limit.match(/^observation:/)) {
        ret.push({ id: PropertySource.Derived, description: 'PROPERTY.Derived' });
      } else if (limit === PropertyCategory.Zone) {
        ret.push({ id: PropertySource.Push, description: 'PROPERTY.Push' });
      } else {
        ret.push(
          { id: PropertySource.Manual, description: 'PROPERTY.Manual' },
          { id: PropertySource.Hybrid, description: 'PROPERTY.Hybrid' },
          { id: PropertySource.Push, description: 'PROPERTY.Push' }
        );
      }
    }

    return ret;
  }
  public getStyleList(limit?: string): ISelectMenuItem[] {
    const ret = [];
    if (!limit) {
    ret.push (
      { id: PropertyStyle.Numeric, description: 'PROPERTY.Numeric' },
      { id: PropertyStyle.Text, description: 'PROPERTY.Text' },
      { id: PropertyStyle.TextArea, description: 'PROPERTY.TextArea' },
      { id: PropertyStyle.Note, description: 'PROPERTY.Note' },
      { id: PropertyStyle.Flipswitch, description: 'PROPERTY.Flipswitch' },
      { id: PropertyStyle.Duration, description: 'PROPERTY.Duration' },
      { id: PropertyStyle.Timestamp, description: 'MGMT_DETAILS.Date' },
      { id: PropertyStyle.Measurement, description: 'PROPERTY.Measurement' },
      { id: PropertyStyle.Counter, description: 'PROPERTY.Counter' }
    );
    } else if (limit.match(/^content:/)) {
      ret.push ({ id: PropertyStyle.Numeric, description: 'PROPERTY.Numeric' });
    } else if (limit.match(/^check:/)) {
      ret.push ({ id: PropertyStyle.Numeric, description: 'PROPERTY.Numeric' });
    } else if (limit.match(/^observation:/)) {
      ret.push ({ id: PropertyStyle.Numeric, description: 'PROPERTY.Numeric' });
    } else if (limit === PropertyCategory.InService) {
      ret.push (
        { id: PropertyStyle.UpDown, description: 'PROPERTY.UpDown' },
        { id: PropertyStyle.UpStrainedDown, description: 'PROPERTY.UpStrainedDown' },
      );
    } else if (limit === PropertyCategory.ContentAvailable) {
      ret.push (
        { id: PropertyStyle.UpDown, description: 'PROPERTY.UpDown' },
      );
    } else if (limit === PropertyCategory.Numeric) {
      ret.push (
        { id: PropertyStyle.Numeric, description: 'PROPERTY.Numeric' },
        { id: PropertyStyle.Measurement, description: 'PROPERTY.Measurement' },
        // { id: PropertyStyle.Counter, description: 'PROPERTY.Counter' }
      );
    } else if (limit === PropertyCategory.Text) {
      ret.push (
        { id: PropertyStyle.Text, description: 'PROPERTY.Text' },
        { id: PropertyStyle.TextArea, description: 'PROPERTY.TextArea' },
        // { id: PropertyStyle.Note, description: 'PROPERTY.Note' }
      );
    }
    return ret;
  }

  public getCategoryList(value?: string[] | string): ISelectMenuItem[] {
    if (isUndefined(value)) {
      value = [];
    } else if ( !isArray(value)) {
      value = [ value ];
    }

    const cats = [];
    if (!value.length || indexOf(value, PropertySubject.Asset) > -1) {
      cats.push( { id: PropertyCategory.InService, description: 'PROPERTY.InService'} );

      if (this.subscriberService.usesModule(Module.CHECKS)) {
        cats.push(
          { id: PropertyCategory.CompletedCheck, description: 'PROPERTY.CompletedCheck'},
          { id: PropertyCategory.MissedCheck, description: 'PROPERTY.MissedCheck'},
          { id: PropertyCategory.SkippedCheck, description: 'PROPERTY.SkippedCheck'},
          { id: PropertyCategory.PassedCheck, description: 'PROPERTY.PassedCheck'},
          { id: PropertyCategory.FailedCheck, description: 'PROPERTY.FailedCheck'},
        );
      }

      if (this.subscriberService.usesModule(Module.OBSERVATIONS)) {
        cats.push(
          { id: PropertyCategory.OpenObservations, description: 'PROPERTY.OpenObservations'},
          { id: PropertyCategory.UnassignedObservations, description: 'PROPERTY.UnassignedObservations'}
        );
      }

      cats.push({
        id: PropertyCategory.Zone,
        description: 'SHARED.Zone'
      });
    }

    if (!value.length || indexOf(value, PropertySubject.Content) > -1) {
      cats.push(
        // { id: PropertyCategory.ContentAvailable, description: 'CONTENT.IsAvailable'},
        { id: PropertyCategory.ReapprovalDate, description: 'CONTENT.ReapprovalComing'},
        { id: PropertyCategory.ReviewDate, description: 'CONTENT.ReviewDate'},
        // { id: PropertyCategory.ReviewsCompleted, description: 'CONTENT.ReviewsCompleted'},
        // { id: PropertyCategory.ReviewsMissing, description: 'CONTENT.ReviewsMissing'}
      );
    }

    if (!value.length || indexOf(value, PropertySubject.Content) === -1) {
      cats.push(
        { id: PropertyCategory.Numeric, description: 'PROPERTY.Numeric'},
        { id: PropertyCategory.Text, description: 'PROPERTY.Text'},
        { id: PropertyCategory.Timestamp, description: 'SHARED.Timestamp'}
      );
    }

    return cats;
  }

  public getSubjectList(): ISelectMenuItem[] {
    const subjects = [
      {
        id: PropertySubject.Content,
        description: 'CONTENT.Module_Name'
      },
      {
        id: PropertySubject.Asset,
        description: 'SHARED.Asset'
      },
      // {
      //   id: PropertySubject.Worker, // TODO [azyulikov] will be implemented in next release
      //   description: 'SHARED.User'
      // },
      // {
      //   id: PropertySubject.Zone,
      //   description: 'SHARED.Zone'
      // },
      // {
      //   id: PropertySubject.Location,
      //   description: 'SHARED.Location'
      // }
    ];
    return subjects;
  }

  public getCategoryName(category: PropertyCategory): string {
    let ret = 'SHARED.None';

    switch (category) {
      case PropertyCategory.Text: {
        ret = 'PROPERTY.Text';
        break;
      }
      case PropertyCategory.Numeric: {
        ret = 'PROPERTY.Numeric';
        break;
      }
      case PropertyCategory.InService: {
        ret = 'PROPERTY.InService';
        break;
      }
      case PropertyCategory.MissedCheck: {
        ret = 'PROPERTY.MissedCheck';
        break;
      }
      case PropertyCategory.SkippedCheck: {
        ret = 'PROPERTY.SkippedCheck';
        break;
      }
      case PropertyCategory.CompletedCheck: {
        ret = 'PROPERTY.CompletedCheck';
        break;
      }
      case PropertyCategory.FailedCheck: {
        ret = 'PROPERTY.FailedCheck';
        break;
      }
      case PropertyCategory.PassedCheck: {
        ret = 'PROPERTY.PassedCheck';
        break;
      }
      case PropertyCategory.OpenObservations: {
        ret = 'PROPERTY.OpenObservations';
        break;
      }
      case PropertyCategory.UnassignedObservations: {
        ret = 'PROPERTY.UnassignedObservations';
        break;
      }
      case PropertyCategory.Zone: {
        ret = 'PROPERTY.Zone';
        break;
      }
      case PropertyCategory.Timestamp: {
        ret = 'PROPERTY.TimeStamp';
        break;
      }
      case PropertyCategory.ReapprovalDate: {
        ret = 'CONTENT.ReapprovalComing';
        break;
      }
      // case PropertyCategory.ReviewsCompleted: {
      //   ret = 'CONTENT.ReviewsCompleted';
      //   break;
      // }
      // case PropertyCategory.ReviewsMissing: {
      //   ret = 'CONTENT.ReviewsMissing';
      //   break;
      // }
      case PropertyCategory.ReviewDate: {
        ret = 'CONTENT.ReviewDate';
        break;
      }
      case PropertyCategory.ContentAvailable: {
        ret = 'CONTENT.IsPublished';
        break;
      }
    }
    return ret;
  }

  public isEventHistoryAvailable(property: IProperty): boolean {
    return includes([PropertySource.Manual, PropertySource.Derived, PropertySource.Hybrid, PropertySource.TimeUntil], property.source);
  }

  public canAddManualPropertyEvent(property: IProperty): boolean {
    return (this.canViewManualProperty(property) || this.isInService(property)) &&
      this.isUserAllowedToAddEntry(property) &&
      !!property?.manualInput?.addEvent?.enabled;
  }

  public canViewManualProperty(property: IProperty): boolean {
    return includes([PropertyCategory.Numeric, PropertyCategory.Text, PropertyCategory.Timestamp], property?.category) &&
      this.isManualSource(property);
  }

  public isManualSource(property: IProperty): boolean {
    return includes([PropertySource.Manual, PropertySource.Hybrid], property?.source);
  }

  public isUserAllowedToAddEntry(property: IProperty) {
    const { permissions, teams, users, types } = (property?.manualInput?.addEvent?.permissions || {});
    let isValid = false;

    if (users?.length && includes(users, this.userData.userID)) {
      isValid = true;
    }

    if (isValid && permissions?.length) {
      isValid = some(permissions, (permission) => {
        return this.permissionService.canView(permission);
      })
    }

    if (isValid && teams?.length) {
      isValid = intersection(teams, this.userData.teams).length > 0;
    }

    if (isValid && types?.length) {
      isValid = includes(types, this.userData.type);
    }

    return isValid || this.permissionService.canView(Permission.Corvex);
  }

  public isInService(property: IProperty): boolean {
    return this.isManualSource(property) && property?.category === PropertyCategory.InService;
  }

  public getPropertyActivity(activity: PropertyActivity | IPropertyEventActivity) {
    const activityMap = {
      [PropertyActivity.Calculated]: 'PROPERTY.Calculated',
      [PropertyActivity.Manual]: 'PROPERTY.Manual',
      [PropertyActivity.InRange]: 'PROPERTY.In_Range',
      [PropertyActivity.OutOfRange]: 'PROPERTY.Out_Of_Range'
    };

    if (activityMap[activity as PropertyActivity]) {
      return this.translate.instant(activityMap[activity as PropertyActivity]);
    } else {
      let activityValue  = activity as string;
      try {
        const activityObject: IPropertyEventActivity = JSON.parse(activity as string);
        activityValue = `${activityObject.type}: ${activityObject.from}`;
      } catch (e) {}

      return activityValue;
    }
  }

  public getPropertyValue(propertyValue: IPropertyValue, propertyID: number) {
    let value = propertyValue?.value;
    const style = (value as IPropertyEventValue)?.style;
    const activity = propertyValue?.activity;

    if (includes([PropertyActivity.InRange, PropertyActivity.OutOfRange], activity)) {
      return (value as IPropertyValueZone)?.rssi;
    } else if (activity === PropertyActivity.Calculated) {
      return value;
    } else {
      if (this.isHealthPropertyValue(propertyValue, propertyID)) {
        return this.getPropertyHealthValue(propertyValue, propertyID);
      } else if (style) {
        return this.getManualEntryPropertyValue(value as IPropertyEventValue);
      } else {
        const eventValue = value as IPropertyEventValue;
        return eventValue?.value ?? eventValue;
      }
    }
  }

  public getPropertyHealthValue(propertyValue: IPropertyValue, propertyID: number, stateValue = false) {
    let value = propertyValue?.value;

    if (this.isHealthPropertyValue(propertyValue, propertyID)) {
      const healthValue = (value as IPropertyEventValue)?.value ?? value;
      const status = this.getAIStatusByValue(+healthValue);

      if (stateValue) {
        return status ? +healthValue : null;
      } else {
        return status ? this.translate.instant(status?.title) : healthValue;
      }
    }
  }

  public isHealthPropertyValue(propertyValue: IPropertyValue, propertyID: number) {
    const property = this.getPropertyById(propertyID);
    let value = propertyValue?.value;
    const style = (value as IPropertyEventValue)?.style;

    return ((!isObject(value) && !style) || includes([PropertyStyle.UpDown, PropertyStyle.UpStrainedDown], style)) && property?.healthRelated;
  }

  public getTableCellValue(valueObject: IPropertyEventValue | string, asset: Asset, propertyID: number, cellType = 'display'): string {
    const value = (valueObject as IPropertyEventValue)?.value ?? valueObject;
    const status = this.getAIStatusByValue(+value);

    if (cellType === 'display') {
      return this.getTableCellHealth(valueObject, asset, propertyID) || ((valueObject as IPropertyEventValue).value || valueObject) as string;
    } else if (cellType === 'export') {
      return valueObject as string;
    } else {
      return status?.type || valueObject;
    }
  }

  public getTableCellHealth(valueObject: IPropertyEventValue | string, asset: Asset, propertyID: number) {
    const property = this.getPropertyById(propertyID);
    const value = (valueObject as IPropertyEventValue)?.value ?? valueObject;
    const status = this.getAIStatusByValue(+value);

    if (status?.type && !this.assetsService.isAssetNoState(asset) && property.healthRelated) {
      return `
        <div class="property-status">
          <span class="observation-circle ${status.type}"></span>
          <span class="property-value">${this.translate.instant(status.title)}</span>
        </div>
      `;
    }
  }

  public getManualEntryPropertyValue(valueObject: IPropertyEventValue) {
    if (!valueObject) {
      return '';
    }

    const style = valueObject.style;

    if (style === PropertyStyle.Measurement) {
      const measurementData = (valueObject as IPropertyEventMeasurementValue);
      const measurementByUnits = measurementData?.[measurementData?.units];
      return measurementByUnits ? `${measurementData[measurementData.units]} ${this.getMeasurementTitle(measurementData.units)}` : valueObject?.note;
    } else if (includes([PropertyStyle.Date, PropertyStyle.Timestamp], style)) {
      return this.utils.dateTimeFormat(+valueObject.value / 1000, null, false, true);
    } else if (style === PropertyStyle.Duration) {
      const durationData = (valueObject as IPropertyEventDurationValue);
      return `${durationData.value} ${durationData.value_unit}`;
    } else if (style === PropertyStyle.Flipswitch) {
      return (valueObject as IPropertyEventValue).value ? this.translate.instant('SHARED.Yes') : this.translate.instant('SHARED.No');
    } else if (style === PropertyStyle.TextArea) {
      return this.markdownService.parse(valueObject.value.toString());
    }

    return valueObject?.value ?? valueObject?.note ?? valueObject;
  }

  public getMeasurementTitle(unitId: string): string {
    const unit = find(IncrementEntryFieldsModel.UTILS, { id: unitId });
    return unit?.description ? this.translate.instant(unit?.description) : unitId;
  }

  public defineEventStatus(propertyID: number, propertyStyle: PropertyStyle, events: IPropertyEvent[], propertyEventStatusRef: IObjectStringKeyMap<string>) {
    const lastEvent = last(filter(events, (event) => {
      return (event?.value as IPropertyEventValue)?.value ?? (!isObject(event?.value) && event?.value);
    })) as IPropertyEvent;

    if (lastEvent) {
      const style = (lastEvent.value as IPropertyEventValue)?.style;
      const eventValue = lastEvent?.value;
      const propertyStyles = [PropertyStyle.UpDown, PropertyStyle.UpStrainedDown];

      if ((!isObject(lastEvent.value) && includes(propertyStyles, propertyStyle)) || includes(propertyStyles, style)) {
        const stateValue = +((lastEvent.value as IPropertyEventValue)?.value ?? lastEvent.value as string);
        propertyEventStatusRef[propertyID] = this.assetsService.assetState(stateValue);
      } else if (style) {
        propertyEventStatusRef[propertyID] = this.getManualEntryPropertyValue(lastEvent.value as IPropertyEventValue);
      } else {
        propertyEventStatusRef[propertyID] = (eventValue as IPropertyEventValue)?.value as string ?? (eventValue as IPropertyEventValue)?.note ?? eventValue as string;
      }
    }
  }

  public getAIStatusByValue(value: number) {
    return this.aiStatuses[value];
  }
}
