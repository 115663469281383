import { PopoverController } from '@ionic/angular';
import { Component, OnInit } from '@angular/core';

import { ICheck, ICheckGroup } from '../../models';
import { CustomFormComponent } from '@services/formBuilder/abstract-custom-form-field';
import { QuestionsToIncludeFormModalComponent } from '../questions-to-include-form-modal/questions-to-include-form-modal.component';

import { compact, filter, find, get } from 'lodash';
import { TranslateService } from '@ngx-translate/core';

@Component({
  selector: 'app-questions-to-include-form',
  templateUrl: './questions-to-include-form.component.html',
  styleUrls: ['./questions-to-include-form.component.scss'],
})
export class QuestionsToIncludeFormComponent extends CustomFormComponent implements OnInit {
  public btnTitle: string;
  public options: any[];
  public theCheck: ICheck;
  public groupID: string;
  public selectedItems: any[] = [];
  constructor(
    private popoverController: PopoverController,
    private translate: TranslateService
  ) {
    super();
  }

  ngOnInit() {
    if (this.selectedItems && !this.selectedItems.length) {
      if (this.groupID && this.theCheck) {
        const groups: ICheckGroup[] = get(this.theCheck, 'checkGroups') || [];
        const thisGroup = find(groups, { groupID: this.groupID });
        if (thisGroup) {
          this.selectedItems = thisGroup.questions;
        }
      }
    }
    this.btnTitle = this.translate.instant('MGMT_DETAILS.Select_Questions');
    // sanitize the array of selectedItems
    this.selectedItems = filter(compact(this.selectedItems));
  }

  public showQuestionsToIncludeForm(): void {
    this.popoverController.create({
      component: QuestionsToIncludeFormModalComponent,
      animated: false,
      componentProps: {
        options: this.options,
        selectedItems: this.selectedItems
      }
    }).then((element: HTMLIonPopoverElement) => {
      element.present();

      element.onDidDismiss().then((event) => {
        if (event.data) {
          if (event.data !== this.selectedItems) {
            this.formValue = event.data;
          }
          this.onChanged.emit(event.data);
        }
      });
    });
  }
}
