import { Component, Injector } from '@angular/core';

import { awaitHandler } from '@utils/awaitHandler';
import { IObjectStringKeyMap } from '@shared/models';
import { ContentFolderService } from '@modules/management/pages/list/content/services/content-folder.service';
import { FoldersDataService, FormConfig, FormField, Module, ObjectItem, ObjectLifecycle, ObjectPhase, ObjectState, SubscriberService } from '@services';
import {
  AbstractContentBasePage,
  ContentBaseField
} from '@modules/management/pages/details/content/pages/abstract-content-base-page';

import { assign, cloneDeep, each, filter, find, flatten, get, has, isArray, isObject, keys, map, merge } from 'lodash';
import { PropertySubject } from '@services/property/property-model.interfaces';
import { PropertyPickerComponent } from '@modules/management/modules/property-picker/components';
import { PropertyPickerConfig } from '@modules/management/modules/property-picker/model/property-picker.interfaces';
import { IFolder } from '@modules/management/modules/folders/model/folders.interfaces';

@Component({
  selector: 'app-content',
  templateUrl: './content.page.html',
  styleUrls: ['./content.page.scss'],
})
export class ContentPage extends AbstractContentBasePage {
  public title: string = this.translate.instant('MGMT_DETAILS.Add_Item');
  protected routeIdParameterName = 'id';
  protected newForm: any = {
    id: 'contentAddForm',
    cancelButton: 'contentAddCancel',
    saveButton: 'contentAddSave'
  };
  protected editForm: any = {
    id: 'contentEditForm',
    cancelButton: 'contentEditCancel',
    saveButton: 'contentEditSave',
    deleteButton: 'contentEditDelete'
  };
  private mediaConfig = {
    options: {
      dynamicFormData: () => {
        // this is for the real object being uploaded - we need all of the form data
        const contentField: FormField = find(this.formConfig.fields, {name: 'contentObject'});
        const fields = cloneDeep(contentField?.mediaConfig?.options?.relatedData) ;
        each(keys(fields), (fieldName) => {
          if (isObject(fields?.[fieldName])) {
            fields[fieldName] = JSON.stringify(fields[fieldName]);
          }
        });
        delete fields.type;
        delete fields.subtype;

        return fields;
      },
      autoSubmit: false,
      showCancel: true,
      relatedData: {
        originalData: null,
        mediaType: null
      },
      formData: {
        type: 'content',
        subtype: null
      },
      allowedTypes: "*",
      acceptFiles: ''
    },
    events: {
      onSuccess: (data) => {
        this.objectData = data;
      },
      onError: () => {
        this.toggleFormControls();
        this.customValidate.validateFailed('error');
      },
      onSelect: (fileId: string, files: File[]) => {
        const field = find(this.formConfig.fields, {name: 'translation_contentObject'});
        this.formBuilderService.updateFields(this.formConfig, [field], fileId, this.formId);

        // get the info about the selected file(s) kk
        if (files?.length) {
          let name = files[0]?.name;
          if (name) {
            // this is the original name of the file - send that along
            const contentField: FormField = find(this.formConfig.fields, {name: 'contentObject'});
            contentField.mediaConfig.options.relatedData.originalName = name;
            contentField.mediaConfig.options.relatedData.mediaType = files[0].type;
            if (this.folderData && this.folderData.folderID) {
              contentField.mediaConfig.options.relatedData.folderID = this.folderData.folderID;
            }
          }
        }
      },
      onCancel: () => this.hideTranslationObjects(),
      onRemove: () => this.hideTranslationObjects()
    }
  };
  protected formConfig: FormConfig = {
    autocomplete: false,
    canClear: true,
    containers: true,
    prefix: 'contentAdd',
    del: null,
    save: this.translate.instant('MGMT_DETAILS.Add_Item'),
    cancel: this.translate.instant('SHARED.Cancel'),
    fields: [
      this.sharedFields[ContentBaseField.Description],
      {
        inputtype: 'verbatim',
        name: 'translation_description',
        title: this.translate.instant('MGMT_DETAILS.Title_Translation'),
        type: 'textTranslations',
        size: 20,
      },
      assign({}, this.sharedFields[ContentBaseField.ContentObject], {
        mediaConfig: cloneDeep(this.mediaConfig),
      }),
      {
        containerClass: 'translation-object',
        name: 'translation_contentObject',
        title: this.translate.instant('MGMT_DETAILS.Content_Translation'),
        type: 'mediaTranslations',
        extensions: ['image', 'pdf', 'audio', 'video', 'other'],
        mediaConfig: Object.assign({}, this.mediaConfig, {
          events: {
            onSuccess: () => this.toggleFormControls(),
            onError: () => this.toggleFormControls(),
            onSubmit: () => this.toggleFormControls(false),
            onRemove: async (id: number) => {
              if (!this.messageID) {
                this.loadingService.enable();
                await awaitHandler(this.objectsService.removeObject(id));
                this.loadingService.disable();
              }
            },
          },
          options: {
            dynamicFormData: () => {
              const data = this.getData();
              const contentField: FormField = find(this.formConfig.fields, {name: 'translation_contentObject'});
              contentField.mediaConfig.options.relatedData.translationOf = data.objectID || this.objectData?.objectID;

              const fields = cloneDeep(contentField.mediaConfig.options.relatedData) ;
              each(Object.keys(fields), fieldName => {
                if (typeof(fields[fieldName]) == 'object') {
                  fields[fieldName] = JSON.stringify(fields[fieldName]);
                }
              });
              if (data.objectID || this.objectData?.objectID) {
                fields.translationOf = data.objectID || this.objectData?.objectID;
              }
              delete fields.type;
              delete fields.subtype;
              return fields;
            },
            autoSubmit: false,
            showCancel: true,
            relatedData: {
              originalData: null,
              mediaType: null
            },
            formData: {
              type: 'content',
              subtype: null
            },
            allowedTypes: "*",
            acceptFiles: ''
          },
        }),
        multiple: false,
        addButton: this.translate.instant('SHARED.Upload_Image'),
        updateButton: this.translate.instant('SHARED.Replace_Image'),
        imageSize: '150px'
      },
      assign({}, this.sharedFields[ContentBaseField.Revision], {
        containerClass: 'review',
      }),
      this.sharedFields[ContentBaseField.Tags],
      this.sharedFields[ContentBaseField.Resources]
    ]
  };
  private folderData: IFolder;

  constructor(
    protected injector: Injector,
    private contentFolderService: ContentFolderService,
    private foldersDataService: FoldersDataService,
    private subscriber: SubscriberService
  ) {
    super(injector);
  }

  protected deleteHandler: any = () => this.objectsService.removeObject(this.objectData.objectID, this.objectData?.revision);

  protected updateHandler: any = async (formData: any) => {
    await awaitHandler(this.savePendingAttachments());
    await this.settingsService.encodeTags(formData, 'tags');
    return this.updateObject(formData);
  };

  protected addHandler: any = async (formData: any) => {
    // we are adding a new document
    const translationContentObjectField = find(this.formConfig.fields, {name: 'translation_contentObject'});
    const contentField = find(this.formConfig.fields, {name: 'contentObject'});

    // build out relatedData in the media fields
    let theData = this.prepareFormData(formData);
    let state = 'inactive'

    if (formData?.attributes?.lifecycle === ObjectLifecycle.Review) {
      state = 'draft';
    } else if (formData.active) {
      state = 'active';
    }

    theData.state = state;
    contentField.mediaConfig.options.relatedData = cloneDeep(merge(contentField.mediaConfig.options.relatedData, theData)) ;
    translationContentObjectField.mediaConfig.options.relatedData = cloneDeep(merge(translationContentObjectField.mediaConfig.options.relatedData, theData)) ;

    await awaitHandler(this.savePendingAttachments());

    // at this point we should have an objectID and a revision; inject those
    if (this.objectData?.objectID) {
      theData.objectID = this.objectData.objectID;
      theData.revision = this.objectData.revision;
    }
    await this.settingsService.encodeTags(theData, 'tags');
    return this.settingsService.handleNewTagsByForm(theData, (data) => this.objectsService.updateObject(data));
  };

  protected getData() {
    const folderID = +get(this.route.snapshot.queryParams, 'folderID');
    let data: any = { folderID };
    this.folderData = this.foldersDataService.getFolderByID(data.folderID);

    if (this.messageID) {
      this.objectData = this.objectsService.getCachedObjectById(+this.messageID, 'content');
      this.handleAccessFields(this.objectData, false);
      this.decodeReviewFields(this.objectData);
      data = this.objectData;
      this.folderData = this.foldersDataService.getFolderByID(data.folderID);

      if (data.objectID) {
        data.contentObject = data.objectID;
      }

      if (data.state === ObjectState.Active) {
        data.active = 1;
      } else {
        data.active = 0;
      }

      this.utils.decodeTranslations(this.objectData, ['translation_description'], ['description', 'translation_contentObject']);
      this.decodeTranslationsObjects();

      let limited = 0;
      each(['permissions', 'locations', 'roles', 'certifications', 'shifts', 'teams'], (key) => {
        if (has(data, key) && isArray(data[key]) && data[key].length)  {
          limited = 1;
        }
      });
      data.limit_access = limited;
    } else {
      if (this.folderData?.attributes?.settings_inherited || this.folderData?.attributes?.document_review) {
        assign(data, this.folderData?.attributes);
      }
      data.users = this.folderData?.attributes?.users ?? [];
    }
    data.parentAttributes = this.folderData?.attributes

    return data;
  }

  protected prepareFormConfig(): void {
    const data = this.getData();
    const contentAvailableField: FormField = {
      name: 'active',
      containerClass: 'basic',
      title: this.translate.instant('MGMT_DETAILS.Content_available'),
      type: 'flipswitch',
      value: 1,
      default: 1
    };

    if (this.messageID) {
      this.title = this.translate.instant('MGMT_DETAILS.Edit_Item');

      this.formConfig.prefix = 'contentEdit';
      this.formConfig.save = this.translate.instant('SHARED.Save_Changes');
      this.formConfig.del = this.translate.instant('MGMT_DETAILS.Delete_Content');

      this.deletePopoverConfig.title = this.translate.instant('MGMT_DETAILS.Delete_Content');
      this.deletePopoverConfig.description = this.translate.instant('MGMT_DETAILS.Content_Delete_Popover_Description');

      this.updatePopoverConfig.title = this.translate.instant('MGMT_DETAILS.Update_Content');
      this.updatePopoverConfig.description = this.translate.instant('MGMT_DETAILS.Content_Update_Popover_Description');

      if (data?.attributes?.lifecycle === ObjectLifecycle.Basic) {
        this.formConfig.fields.push(contentAvailableField);
      }
    } else {
      // new document
      if (!data?.parentAttributes?.document_review) {
        this.formConfig.fields.push(contentAvailableField);
      }
    }

    this.formConfig.fields.push(
      ...this.contentFolderService.getLimitContentAccessFields(),
      ...this.contentFolderService.getDocumentReviewFields(!!data?.parentAttributes?.settings_inherited, !!data?.parentAttributes?.document_review, true)
    );

    if (this.subscriber.usesModule(Module.PROPERTIES)) {
      this.formConfig.fields.push({
        type: 'customElement',
        name: 'selectedProperties',
        component: PropertyPickerComponent,
        module: Module.PROPERTIES,
        containerClass: 'review',
        inputs: {
          pickerConfig: <PropertyPickerConfig>{
            type: PropertySubject.Content
          },
          parentIds: this.contentFolderService.getParentIds(this.folderData)
        }
      });
    }
  }

  protected async prepareDataAsync(): Promise<any> {
    await super.prepareDataAsync();
    const translationContentObjectField = find(this.formConfig.fields, {name: 'translation_contentObject'});
    const contentField = find(this.formConfig.fields, {name: 'contentObject'});

    if (this.messageID) {
      this.objectData = this.objectsService.getCachedObjectById(+this.messageID, 'content') || {} as ObjectItem;

      if (this.objectData.objectID) {
        contentField.mediaConfig.options.relatedData.updates = this.objectData.objectID;
        translationContentObjectField.mediaConfig.options.relatedData.folderID = this.objectData.folderID;
      }
    } else {
      translationContentObjectField.mediaConfig.options.relatedData.folderID = +get(this.route.snapshot.queryParams, 'folderID');
    }
  }

  protected onFinish(): void {
    this.contentFolderService.onAccessLocationChange(this.formConfig);
  }

  private updateObject(formData) {
    this.prepareFormData(formData);
    return this.objectsService.updateObject(formData);
  }

  private prepareFormData(formData): any {
    const folderID = +get(this.route.snapshot.queryParams, 'folderID');
    this.handleAccessFields(formData);
    this.encodeReviewFields(formData);

    if (formData?.attributes?.lifecycle === ObjectLifecycle.Basic) {
      // document review is NOT set
      delete formData.attributes.reviewInfo;
      if (has(formData, 'active') && +formData.active === 1) {
        formData.state = ObjectPhase.Active;
      } else {
        formData.state = ObjectPhase.Inactive;
      }
    }

    const convertArrayToJSON = (key: string): void => {
      const items = map(flatten([formData[key]]), (arrayItem: string | number) => isNaN(+arrayItem) ? arrayItem : +arrayItem);
      formData[key] = JSON.stringify(filter(items));
    };

    this.utils.encodeTranslations(formData, ['translation_description'], ['description']);

    formData.type = 'content';
    if (this.objectData) {
      formData.objectID = this.objectData.objectID;
      formData.objectUUID = this.objectData.objectUUID;
    }
    each(['users', 'permissions', 'tags', 'locations', 'roles', 'certifications', 'shifts', 'teams'], (key) => convertArrayToJSON(key));
    delete formData.tagIDs;

    if (folderID) {
      formData.folderID = folderID;
    }

    return formData;
  }

  private handleAccessFields(formData: IObjectStringKeyMap<any>, encode = true) {
    const renameFields = [
      'certifications',
      'locations',
      'roles',
      'teams',
      'permissions',
      'shifts'
    ];

    if (encode) {
      each(renameFields, (fieldName) => {
        const oldFieldName = `access_${fieldName}`;
        formData[fieldName] = formData[oldFieldName];
        delete formData[oldFieldName];
      });
    } else {
      each(renameFields, (fieldName) => {
        formData[`access_${fieldName}`] = formData[fieldName];
      });
    }
  }

  protected encodeReviewFields(formData: IObjectStringKeyMap<any>) {
    this.contentFolderService.encodeReviewFields(formData);

    if (formData.attributes?.reviewInfo?.document_review) {
      formData.attributes.lifecycle = ObjectLifecycle.Review;
    } else {
      formData.attributes.lifecycle = ObjectLifecycle.Basic;
    }
  }
}
