import { Injectable, Injector } from '@angular/core';

import { IObjectStringKeyMap } from '@shared/models';
import { FormField, PropertyService } from '@services';
import { Asset } from '@services/assets/asset.interfaces';
import { SelectFiltersFormComponent } from '@modules/reporting/components';
import { AssetByPropertyEvent } from '@modules/reporting/models/types/asset/model';
import { AssetDetailModelService } from '@modules/reporting/models/types/assetDetail/service';
import { FolderDataType } from '@modules/management/modules/folders/model/folders.interfaces';
import { BaseField, ReportBaseClass, ReportClass } from '@modules/reporting/models/types/base';
import { ObservationTableService } from '@services/observationTableService/observation-table-service.service';
import { DataToIncludeFormComponent } from './../../../components/data-to-include-form/data-to-include-form.component';

import { fromEvent, interval } from 'rxjs';
import { debounce, map } from 'rxjs/operators';
import {
  cloneDeep,
  difference,
  each,
  filter,
  find,
  get,
  includes,
  merge,
  reject,
  values,
  map as _map,
  assign,
  some,
  has,
  isNull,
  replace,
  intersection
} from 'lodash';

@Injectable()
export class AssetDetailReport extends ReportBaseClass {
  public dataToIncludeFields: any[] = [];
  public reportType: ReportClass = ReportClass.AssetDetail;

  private assetDetailModelService: AssetDetailModelService = this.injector.get(AssetDetailModelService);
  private propertyService: PropertyService = this.injector.get(PropertyService);
  private observationTableService: ObservationTableService = this.injector.get(ObservationTableService);
  public reportColumnOptions = this.assetDetailModelService.getColumns();
  public reportFieldOptions = this.assetDetailModelService.getFieldOptions();

  public filtersFields = [
    this.baseFields[BaseField.Locations],
    assign({}, this.baseFields[BaseField.TargetZones], {
      title: this.translate.instant('SHARED.Zones'),
      name: 'zones',
    }),
    assign({}, this.baseFields[BaseField.TargetTeams], {
      title: this.translate.instant('SHARED.TEAMS'),
      name: 'teams',
    }),
    assign({}, this.baseFields[BaseField.TargetUsers], {
      title: this.translate.instant('SHARED.USERS'),
      name: 'users',
    }),
    assign({}, this.baseFields[BaseField.TargetAssets], {
      title: this.translate.instant('REPORTING.Asset_Or_Folder')
    }),
    this.baseFields[BaseField.TargetProperties],
    {
      containerClass: 'report-field',
      title: this.translate.instant('PROPERTY.State_Related'),
      name: 'stateRelated',
      type: 'selectmenu',
      role: 'none',
      default: 1,
      multiple: false,
      canClear: false,
      canDelete: true,
      options: [
        {
          id: 1,
          description: this.translate.instant('SHARED.Yes')
        },
        {
          id: 0,
          description: this.translate.instant('SHARED.No')
        }
      ],
      onRemoveField: (field: any) => this.onRemoveField(field)
    },
    {
      containerClass: 'report-field',
      title: this.translate.instant('SHARED.Status'),
      name: 'status',
      type: 'selectmenu',
      role: 'none',
      placeholder: 'Any State',
      multiple: true,
      canDelete: true,
      originalOrder: true,
      options: [
        {
          id: 2,
          description: this.translate.instant('PROPERTY.State_Up')
        },
        {
          id: 1,
          description: this.translate.instant('PROPERTY.State_Strained')
        },
        {
          id: 0,
          description: this.translate.instant('PROPERTY.State_Down')
        },
        {
          id: -1,
          description: this.translate.instant('SHARED.None')
        }
      ],
      onRemoveField: (field: any) => this.onRemoveField(field)
    },
  ];
  public fields = [
    this.baseFields[BaseField.Timespan],
    {
      containerClass: 'custom-data-field report-field assetDetail',
      title: this.translate.instant('REPORTING.EDIT_Data_to_Include'),
      name: 'assetDetailReportColumns',
      type: 'customElement',
      required: true,
      component: DataToIncludeFormComponent,
      inputs: {
        options: cloneDeep(this.reportColumnOptions)
      },
      outputs: {
        onChanged: (selectedItems) => {
          const field = <FormField>find(this.formConfig.fields, {name: 'assetDetailReportColumns'});
          (field.componentRef as DataToIncludeFormComponent).selectedItems = selectedItems;
          this.dataToIncludeFields = selectedItems;
        }
      },
      options: cloneDeep(this.reportColumnOptions)
    },
    {
      containerClass: 'report-field obsdets observation assetDetail ccsFormSubdivider',
      title: this.translate.instant('REPORTING.EDIT_Report_Data_Selection'),
      type: 'divider',
    },
    {
      containerClass: 'custom-data-field report-field assetDetail',
      title: this.translate.instant('SHARED.Select_Filters'),
      name: 'filtersFields',
      type: 'customElement',
      component: SelectFiltersFormComponent,
      inputs: {
        options: cloneDeep(this.filtersFields),
        selectedItems: []
      },
      outputs: {
        onChanged: (selectedItems) => {
          const field = <FormField>find(this.formConfig.fields, {name: 'filtersFields'});
          (field.componentRef as SelectFiltersFormComponent).selectedItems = selectedItems;
          this.removedFilters = difference(this.filters, selectedItems);
          this.filters = selectedItems;
          this.showFilters();
        }
      },
      options: cloneDeep(this.filtersFields)
    }
  ];
  private opts: any;

  constructor(protected injector: Injector) {
    super(injector);
  }

  public findInterval(opts?, items?) {
    return {};
  }

  public init(reportData) {
    const assetDetailReportColumns: FormField = find(this.formConfig.fields, {name: 'assetDetailReportColumns'});
    assetDetailReportColumns.inputs.reportID = this.messageID;
    this.dataToIncludeFields = get(reportData, 'selectors.extraColumns') || [];

    super.init(reportData);
  }

  public async report(theTable, theChart, report) {
    this.reportData = report;
    const tableDef = merge({}, this.tableDef, {
      onRowClicked: async (currentRow, event) => {
        const asset: AssetByPropertyEvent = currentRow?.asset;
        const activity = replace(this.propertyService.getPropertyActivity(asset?.propertyValue?.value?.activity), ' ', '');

        if (includes(activity, ':observation')) {
          let observationId = null;

          try {
            observationId = JSON.parse(asset?.propertyValue?.value?.activity as string)?.id;
          } catch (e) {}

          if (observationId) {
            this.observationTableService.openObservationDetail(+observationId, false, true);
          }
        }
      },
      initComplete: () => {
        const searchElement = $(theTable).closest('.dataTables_wrapper').find(`input[type='search']`);
        searchElement.off();

        fromEvent(searchElement, 'input')
          .pipe(
            debounce(() => interval(700)),
            map((event: any) => event?.target?.value)
          ).subscribe( async val => {
            await this.loadingService.enable(this.translate.instant('SHARED.Search'));
            $(theTable).DataTable().search(val).draw();
            await this.loadingService.disable();
            searchElement.trigger('focus');
        });
      }
    });
    const opts = cloneDeep(report.selectors);
    const timeByTimespan = this.getTimeByTimespan(opts.timespan, opts.startTime, opts.endTime);
    let data = [];
    opts.startTime = timeByTimespan.startTime;
    opts.endTime = timeByTimespan.endTime;

    if (opts?.targetAssets?.length) {
      opts.assetFolderTree = this.foldersDataService.getFolderTreeBy(FolderDataType.ASSET);
    }

    if (opts?.targetProperties?.length) {
      opts.propertyFolderTree = this.foldersDataService.getFolderTreeBy(FolderDataType.PROPERTY);
    }

    let assets = this.assetsService.getEnabledAssets();
    assets = await this.assetsService.getDetailedAssets(_map(assets, 'assetID'));
    assets = this.filterAssets(assets, opts);

    let assetEvents: AssetByPropertyEvent[] = [];

    each(assets, (asset) => {
      each(asset.propertyEvents, (events, propertyID) => {
        each(events, (event) => {
          assetEvents.push(Object.assign({}, asset, {
            propertyValue: {
              id: +propertyID,
              value: event
            }
          }));
        });
      });
    });

    assetEvents = this.filterEvents(assetEvents, opts);

    const columns = this.getColumns(opts.extraColumns, assetEvents);

    data = _map(assetEvents, (asset) => {
      const assetData = { asset };

      each(columns, (column) => {
        assetData[column.id] = column.func(asset);
      });

      return assetData;
    });

    tableDef.data = data;
    tableDef.columns = this.preparationColumns(columns);

    if (tableDef.columns.length) {
      this.tableService.showDataTable(theTable, tableDef);
    }
    $('.report-view-has-chart').hide();
  }

  private getColumns(columns: string[], assets: Asset[]) {
    const columnsInstances = _map(columns, (column) => {
      return find(this.reportColumnOptions, { id: column });
    });
    const staticColumnsInstances = reject(columnsInstances, 'multipleColumn');
    const multipleColumnsInstances = filter(columnsInstances, 'multipleColumn');
    let targetColumns = staticColumnsInstances;

    if (multipleColumnsInstances.length) {
      const multipleColumns: any[] = this.defineMultipleColumns(multipleColumnsInstances, assets);
      targetColumns.push(...multipleColumns);
    }

    return targetColumns;
  }

  private defineMultipleColumns(columns: any[], assets: Asset[]) {
    const targetColumns = {};

    each(assets, (asset) => {
      each(columns, (column) => {
        column.multipleColumnHandler && column.multipleColumnHandler(asset, targetColumns);
      });
    });

    return values(targetColumns);
  }

  public getTableItems(queryParams: any): any {
    return {};
  }

  public preparationColumns(columns) {
    return _map(columns, (column) => {
      const columnConfig: any = {
        title: `${column.label} <span class="sort-icon"></span>`,
        exportTitle: `${column.label}`,
        data: column.fromID || column.id,
        render: (data, type, row) => {
          if (includes(['sort', 'export'], type)) {
            return column.func(row?.asset, type) || data;
          }
          return data;
        }
      };

      return columnConfig;
    });
  }

  private filterEvents(events: AssetByPropertyEvent[], opts: IObjectStringKeyMap<any>): AssetByPropertyEvent[] {
    return filter(events, (event) => {
      const eventTime = event.propertyValue.value.time * 1000;
      let isValid = opts.startTime <= eventTime && eventTime <= opts.endTime;

      if (isValid && opts?.users?.length) {
        isValid = includes(opts?.users, event?.propertyValue?.value?.userID);
      }

      if (isValid && opts?.targetProperties?.length) {
        const property = this.propertyService.getPropertyById(event?.propertyValue?.id);

        if (property) {
          isValid = some(opts.targetProperties, (propertyFolder) => {
            if (propertyFolder.itemID) {
              return property.propertyID === propertyFolder.itemID;
            } else {
              return this.foldersDataService.hasIn(propertyFolder.folderID, property.folderID, opts.propertyFolderTree);
            }
          });
        }
      }

      if (isValid && has(opts, 'stateRelated')) {
        const property = this.propertyService.getPropertyById(event?.propertyValue?.id);

        if (property) {
          isValid = property.healthRelated === opts.stateRelated;
        }
      }

      if (isValid && opts.status?.length) {
        isValid = some(opts.status, (status) => {
          status = +status;
          const healthValue = this.propertyService.getPropertyHealthValue(event?.propertyValue?.value, event?.propertyValue?.id, true);

          if (status === -1) {
            return isNaN(+healthValue) || isNull(healthValue);
          } else {
            return healthValue === status;
          }
        });
      }

      if (isValid && opts?.teams?.length) {
        const user = this.accountsService.getAccount(event?.propertyValue?.value?.userID);

        if (user) {
          isValid = intersection(opts.teams, user.groups).length > 0;
        }
      }

      return isValid;
    });
  }

  private filterAssets(assets: Asset[], opts: IObjectStringKeyMap<any>): Asset[] {
    return filter(assets, (asset) => {
      let isValid = true;

      if (opts?.targetAssets?.length) {
        isValid = some(opts?.targetAssets, (assetFolder) => {
          if (assetFolder.assetID) {
            return assetFolder.assetID === +asset.assetID;
          } else {
            return this.foldersDataService.hasIn(assetFolder.folderID, asset?.folderID, opts.assetFolderTree);
          }
        });
      }

      if (isValid && opts?.targetProperties?.length) {
        const propertyIds = _map(asset.selectedProperties, 'propertyID');
        const properties = filter(_map(propertyIds, (id) => this.propertyService.getPropertyById(id)));
        const folderIDs = _map(properties, 'folderID');

        isValid = some(opts.targetProperties, (propertyFolder) => {
          if (propertyFolder.itemID) {
            return includes(propertyIds, propertyFolder.itemID);
          } else {
            return some(folderIDs, (id) => {
              return this.foldersDataService.hasIn(propertyFolder.folderID, id, opts.propertyFolderTree);
            });
          }
        });
      }

      if (isValid && opts?.locations?.length) {
        isValid = includes(opts.locations, asset.location);
      }

      if (isValid && opts?.zones?.length) {
        isValid = includes(opts.zones, asset.zone.toString());
      }

      return isValid;
    });
  }
}
