import { NGXLogger } from 'ngx-logger';
import { Injectable } from '@angular/core';
import { Location } from '@angular/common';
import { ActivatedRouteSnapshot, CanActivate, Router, RouterStateSnapshot } from '@angular/router';

import { AuthService } from '@services/auth/auth.service';
import { DataRestoreService } from '@services/dataRestore/data-restore.service';
import { PermissionsService } from '@services/permissions/permissions.service';
import { UserdataService } from '@services/userdata/userdata.service';
import { AccessService } from '@services/access/access.service';
import { DeepLinkService } from '@services/deep-link/deep-link.service';
import { AccountTypes } from '@services';

import { Observable } from 'rxjs';
import { TranslateService } from '@ngx-translate/core';
import { CommsService } from '@services/comms/comms.service';
import { HttpClient, HttpErrorResponse } from '@angular/common/http';

import { environment } from '@env';
import { StorageService } from '@services/storage/storage.service';
import { each, get, indexOf, isEmpty, last } from 'lodash';
import { awaitHandler } from '@utils/awaitHandler';


@Injectable({
  providedIn: 'root'
})
export class GuardGuard implements CanActivate {

  private readonly loginUrl: string = '/pages/login';
  private readonly loginPreparingUrl: string = '/pages/login/preparing';
  private readonly obtainConsentUrl: string = '/pages/login/obtain-consent';
  private readonly onboardingURL: string = '/pages/login/onboarding';
  private readonly dashboardUrl: string = '/pages/dashboard/main';
  private readonly accessUrl: string = '/pages/access';
  private readonly ssoUrl: string = '/pages/login/sso';
  private readonly badTokenUrl: string = '/pages/badtoken';

  private readonly tokenPages: string[] = [
    '/pages/leaderboard',
    '/pages/assetboard'
  ];

  private configTokens = [
    'subscriberID',
    'endpoint',
    'servicePath',
    'subscriberName',
    'SSEURL',
    'brand'
  ];

  constructor(
    private logger: NGXLogger,
    private router: Router,
    private authService: AuthService,
    private dataRestoreService: DataRestoreService,
    private deepLinkService: DeepLinkService,
    private permissionsService: PermissionsService,
    private userDataService: UserdataService,
    private accessService: AccessService,
    private translate: TranslateService,
    private location: Location,
    private comms: CommsService,
    private http: HttpClient,
    private storageService: StorageService
  ) {
  }

  canActivate(next: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<boolean> | Promise<boolean> | boolean {
    let token: string = next.queryParamMap.get('token');

    // this.logger.debug('In the canActivate method');
    // this method gets called all the time, including VERY early in a reload / clean startup.
    // attempt to restore state if it is stored anywhere
    if (!token && !this.isSSOPage(state.url) && !this.storageService.isRestored) {
      this.logger.debug('Reloading state data from local storage');
      this.storageService.getState();
      this.authService.auth0RestoreResult();
    }

    return new Promise(async (resolve: (value: boolean) => void) => {

      if (token && this.isTokenPage(state.url) && !this.authService.isUserLogged()) {
        // strip enclosing angle brackets if there are any
        if (token[0] === '<' && token[-1] === '>') {
          token = token.substring(1, token.length-1);
        }
        const [res, err] = await awaitHandler( this.authService.authenticateWithToken(token));

        if (res) {
          // we authenticated - set up everything
          await this.authService.processSignin();
        } else {
          this.goToBadToken(state.url, resolve);
          return resolve(false);
        }
      }

      if (this.isSSOPage(state.url)) {
        // this should have been refreshed in the app module during initialization
        // await this.authService.subscriber.refresh();
        const p = state.url.replace(/^.*#/, '');
        this.authService.auth0PopupCallback(p);
        return resolve(true);
      }

      // we are not going to the SSO page... verify our token if there is one
      if (this.authService.usesAuth0() && this.authService.auth0Token()) {
        // check to ensure the token remains valid
        const profile = await this.authService.auth0CheckAuth(false);

        if (profile === null) {
          this.logger.error('User is not authenticated - logging out');
          // we are not authorized any longer; force a logout
          this.authService.handleNoAuth();
          return resolve(false);
        }
      }

      // user is logged in but needs to update their consent setting
      if (this.authService.isUserLogged() && !this.isTokenPage(state.url) && !this.userDataService.hasConsented()) {
        if (this.isAccessPage(state.url) && this.location.path() !== this.accessUrl) {
          return resolve(true);
        } else {
          this.storeAllObservations(state.url);
          this.deepLinkService.recognizeAndSaveLink(state.url);
          return this.goToConsentPage(state.url, resolve);
        }
      }

      if (this.authService.isUserLogged() && this.userDataService.needsOnboarding()) {
        if (this.isAccessPage(state.url) && this.location.path() !== this.accessUrl) {
          return resolve(true);
        } else {
          this.storeAllObservations(state.url);
          this.deepLinkService.recognizeAndSaveLink(state.url);
          return this.goToOnboarding(state.url, resolve);
        }
      }

      if (this.authService.isUserLogged()) {
        if (this.isLoginPage(state.url) || this.isPreparingPage(state.url)) {
          if (this.router.url === '/') {
            this.goToLastOpenedDashboardPage();
          }
          return resolve(false);
        }

        // okay - here is where we start the reload process!
        this.dataRestoreService.sync().then(( restored: boolean ) => {
          if (!restored) {
            // the data restore failed.   bail out
            this.authService.handleNoAuth();
          }
          if (!this.userDataService.hasAccess()) {
            this.goToAccessPage();
            return resolve(false);
          } else {
            if (this.userDataService.getLanguage() !== undefined) {
              this.translate.setDefaultLang(this.userDataService.getLanguage());
            }
          }

          if (this.deepLinkService.hasNextId()) {
            this.deepLinkService.applyNextId();
            resolve(false);
          } else {
            const permission: string = get(next.data, 'permission');
            const isPermissionAccessDenied: boolean = !this.userDataService.isNoPermissionsUser() && permission && !this.permissionsService.canView(permission);
            const isPageUnavailable: boolean = this.isAccessPage(state.url) && !this.accessService.isPageAvailable;

            if (isPermissionAccessDenied || isPageUnavailable) {
              const isModuleGuard = !isEmpty(get(next.data, 'moduleAccess'));

              if (isModuleGuard) {
                resolve(true);
              } else {
                this.goToLastOpenedDashboardPage();
                resolve(false);
              }
            } else {
              const lastDashboardType: string = this.userDataService.getPreference('lastDashboardType');
              const userAccountType: AccountTypes = this.userDataService.type;
              const isFirstAppRun: boolean = this.isFirstAppRun(next);

              if (this.authService.prevObservationRoute || lastDashboardType && isFirstAppRun && userAccountType !== AccountTypes.Reporting) {
                this.goToLastOpenedDashboardPage();
                resolve(false);
              } else {
                resolve(true);
              }
            }
          }
        });
      } else {
        if (this.isAccessPage(state.url) && this.location.path() !== this.accessUrl) {
          resolve(true);
        } else if (this.isBadTokenPage(state.url) && this.location.path() !== this.badTokenUrl) {
          resolve(true);
        } else {
          this.storeAllObservations(state.url);
          this.deepLinkService.recognizeAndSaveLink(state.url);
          this.goToLogin(state.url, resolve);
        }
      }
    });
  }

  public async checkSubscriberID() {
    return new Promise((resolve, reject) => {
      if (environment.data.subscriberID === 0) {
        let baseHref: string = environment.baseHref;
        if (last(baseHref) !== '/') {
          baseHref += '/';
        }

        const url = `${location.origin}${baseHref}assets/config/settings.json`;

        this.http.get(url).subscribe((response: any) => {
          each(this.configTokens, (name) => {
            const l = get(response, name);
            if (l) {
              if (name === 'subscriberID') {
                environment.data.subscriberID = +l;
              } else {
                environment.data[name] = l;
              }
            }
          });
          this.comms.init({noAuth: () => this.authService.handleNoAuth()});
          resolve(true);
        }, (error: HttpErrorResponse) => {
          reject(error);
        });
      } else {
        this.comms.init({noAuth: () => this.authService.handleNoAuth()});
        resolve(true);
      }
    });
  }


  private storeAllObservations(route: string) {
    if (route.includes('dashboard/quality-detail')
      || route.includes('dashboard/condition-detail')
      || route.includes('dashboard/coaching-detail')
      || route.includes('dashboard/condition-detail')
      || route.includes('dashboard/process-detail')
      || route.includes('dashboard/compliment-detail')) {
      this.authService.prevObservationRoute = route.split('?')[0];
    }
  }

  private goToLogin(url: string, resolve: (value: boolean) => void): void {
    if (this.isLoginPage(url)) {
      return resolve(true);
    }
    this.router.navigate([this.loginUrl]);
    resolve(false);
  }

  private isLoginPage(url: string): boolean {
    return url === this.loginUrl;
  }

  private isConsentPage(url: string): boolean {
    return url === this.obtainConsentUrl;
  }

  private isPreparingPage(url: string): boolean {
    return url === this.loginPreparingUrl;
  }

  private isMainPage(url: string): boolean {
    return url === this.dashboardUrl;
  }

  private isAccessPage(url: string): boolean {
    return url === this.accessUrl;
  }

  private isOnboardingPage(url: string): boolean {
    const m = url.match(this.onboardingURL);
    return !!m;
  }

  private isSSOPage(url: string): boolean {
    // strip the query portion
    const p = url.replace(/#.*$/, '');
    return p === this.ssoUrl;
  }

  private isTokenPage(url: string): boolean {
    // strip the query portion
    let p = url.replace(/#.*$/, '');
    p = url.replace(/\?.*$/, '');
    if (indexOf(this.tokenPages, p) !== -1 ) {
      return true;
    } else {
      return false;
    }
  }

  private goToBadToken(url: string, resolve: (value: boolean) => void): void {
    if (this.isBadTokenPage(url)) {
      return resolve(true);
    }
    this.router.navigate([this.badTokenUrl]);
    resolve(false);
  }

  private isBadTokenPage(url: string): boolean {
    return url === this.badTokenUrl;
  }


  private isFirstAppRun(state: ActivatedRouteSnapshot): boolean {
    const numberOfChildren: number = get(this.router.getCurrentNavigation(), 'extractedUrl.root.numberOfChildren');
    const targetUrl: string = get(state.url, '[0].path');
    const currentUrl: string = this.router.url;

    return numberOfChildren === 0 && targetUrl === 'dashboard' && currentUrl === '/';
  }

  private goToAccessPage(): void {
    this.authService.handleSignout();

    setTimeout(() => {
      this.accessService.goToAccessPage();
    });
  }

  private goToOnboarding(url: string, resolve: (value: boolean) => void): void {
    if (this.isOnboardingPage(url)) {
      return resolve(true);
    }
    this.router.navigate([this.onboardingURL]);
    return resolve(false);
  }

  private goToConsentPage(url: string, resolve: (value: boolean) => void): void {
    if (this.isConsentPage(url)) {
      return resolve(true);
    }
    this.router.navigate([this.obtainConsentUrl]);
    return resolve(false);
  }

  private goToLastOpenedDashboardPage(): void {
    let lastDashboardType: string = this.userDataService.getPreference('lastDashboardType');
    const redirectedDashboardPage = this.authService.prevObservationRoute;
    this.authService.prevObservationRoute = null;
    this.router.navigate([redirectedDashboardPage || lastDashboardType || this.dashboardUrl]);
  }
}
