import { Injectable } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';

import { ColumnBuilderService } from '@services/observationTableService/column-builder.service';
import { ObservationService } from '@services/observations/observation.service';
import { ObservationDetailService } from '@services/observationDetail/observation-detail.service';
import { DataTableOptions, TableService } from '@services/table/table.service';
import { DataTableNavigationService, LoadingService } from '@services';
import * as Mark from 'mark.js';
import { UserdataService } from '@services/userdata/userdata.service';
import { TranslateService } from '@ngx-translate/core';

import { environment } from '@env';
import { cloneDeep, each, filter, flatten, get, includes, map, orderBy, reject, replace, sortBy } from 'lodash';

interface TableConfig {
  tab: { id: string };
  tableData: any;
  filterString: string;
  loadingState: { [id: string]: boolean };
  isTabChanging?: boolean;
  recalculate?: boolean;
  disableLoading?: boolean;
}

@Injectable({
  providedIn: 'root'
})
export class ObservationTableService {

  public filterObject: any = {};


  constructor(
    private observationService: ObservationService,
    private columnBuilder: ColumnBuilderService,
    private observationDetail: ObservationDetailService,
    private tableService: TableService,
    private router: Router,
    private route: ActivatedRoute,
    private loadingService: LoadingService,
    private userdataService: UserdataService,
    private translate: TranslateService,
    private dataTableNavigationService: DataTableNavigationService
  ) {
  }

  /**
   * @param observation types: pi, condition, behavior, quality or compliment
   * getOpenObservations()
   */
  public getObservation(type, options?) {
    const filtObj: any = Object.assign({}, cloneDeep(this.filterObject), options);
    filtObj.obstype = type;
    const pGroup = {
      fieldName: 'state'
    };
    // always filter by the user's locations
    if (!filtObj.hasOwnProperty('locations') && this.userdataService.locations.length) {
      filtObj.locations = cloneDeep(this.userdataService.locations);
    }
    if (type === 'ca') {
      this.observationService.addCaSearchIndices();
    }
    const filteredData = this.observationService.findInIntervals(filtObj, pGroup);
    return filteredData;
  }

  public updateNearestObservationIdsByObservationId(id: number): void {
    const ids: number[] = map(this.getNearestObservationsById(id), 'observationID');
    this.observationDetail.setNearestObservationIdsIfEmpty(ids);
  }

  public getNearestObservationsById(id: number): any {
    const observation: any = this.observationService.observations.data[id];
    const type: string = this.observationService.getProperty(observation, 'type');
    const subType: string = this.observationService.getProperty(observation, 'subtype');
    const state: string = observation.state;
    const rawData: any = this.getObservation(type);

    let observations = [];

    let activeTab: string;
    let url: string;

    switch (type) {
      case 'condition': {
        url = 'pages/dashboard/condition-table';
        if (observation.state === 'new' || observation.state === 'escalated') {
          activeTab = 'open';
        } else if (observation.state === 'workorder') {
          activeTab = 'workorder';
        } else if (observation.state === 'fixed') {
          activeTab = 'fixed';
        } else if (observation.state === 'resolved') {
          activeTab = 'closed';
        }
        break;
      }
      case 'quality': {
        url = 'pages/dashboard/quality-table';
        if (this.observationService.getProperty(observation, 'subtype') === 'receiving') {
          if (observation.state === 'resolved') {
            activeTab = 'qualityClosed';
          } else {
            activeTab = 'qualityReceiving';
          }
        } else {
          if (observation.state === 'new' || observation.state === 'escalated') {
            activeTab = 'qualityOpen';
          } else if (observation.state === 'fixed' || observation.state === 'dropped') {
            activeTab = 'qualityFixed';
          } else if (observation.state === 'resolved') {
            activeTab = 'qualityClosed';
          }
        }
        break;
      }
      case 'behavior': {
        url = 'pages/dashboard/coaching-table';
        activeTab = 'coachingData';
        break;
      }
      case 'compliment': {
        url = 'pages/dashboard/compliment-table';
        activeTab = 'complimentData';
        break;
      }
      case 'pi': {
        url = 'pages/dashboard/process-table';
        if (observation.state === 'escalated' || observation.state === 'new') {
          if (observation.ownerID) {
            activeTab = 'processAssigned';
          } else {
            activeTab = 'processSubmitted';
          }
        } else if (observation.state === 'fixed') {
          activeTab = 'processImplemented';
        } else if (observation.state === 'resolved') {
          activeTab = 'processClosed';
        }
        break;
      }
      case 'ca': {
        url = 'pages/dashboard/ca-table';
        if (observation.state === 'new' || observation.state === 'escalated') {
          activeTab = 'caOpen';
        } else if (observation.state === 'workorder') {
          activeTab = 'caWorkorder';
        } else if (observation.state === 'resolved') {
          activeTab = 'caClosed';
        }
        break;
      }
      case 'si': {
        url = 'pages/dashboard/opportunities';
        if (observation.state === 'new') {
          activeTab = 'submitted';
        } else if (observation.state === 'fixed') {
          activeTab = 'addressed';
        }
        break;
      }
      case 'ai': {
        url = 'pages/dashboard/assets';
        if (observation.state === 'new' || observation.state === 'escalated') {
          activeTab = 'open';
        } else if (observation.state === 'workorder') {
          activeTab = 'workorder';
        } else if (observation.state === 'fixed') {
          activeTab = 'fixed';
        } else if (observation.state === 'resolved') {
          activeTab = 'closed';
        }
        break;
      }
    }

    if (type === 'condition') {
      if (includes(['resolved', 'workorder', 'fixed'], state)) {
        observations = this.getObservationsByStates(rawData, [state]);
      } else if (includes(['new', 'escalated'], state)) {
        observations = this.getObservationsByStates(rawData, ['new', 'escalated']);
      }
    } else if (type === 'quality') {
      if (includes(['new', 'escalated'], state)) {
        observations = this.getObservationsByStates(rawData, ['new', 'escalated']);
        observations = reject(observations, <any>{subtype: 'receiving'});
      } else if (includes(['fixed', 'dropped'], state)) {
        observations = this.getObservationsByStates(rawData, ['fixed', 'dropped']);

        if (subType === 'receiving') {
          observations = filter(observations, <any>{subtype: 'receiving'});
        } else {
          observations = reject(observations, <any>{subtype: 'receiving'});
        }
      } else if (state === 'resolved') {
        observations = this.getObservationsByStates(rawData, [state]);
      }
    } else if (type === 'pi') {
      if (includes(['new', 'escalated'], state)) {
        observations = this.getObservationsByStates(rawData, ['new', 'escalated']);

        if (observation.ownerID) {
          observations = filter(observations, 'ownerID');
        } else {
          observations = reject(observations, 'ownerID');
        }
      } else if (includes(['resolved', 'fixed'], state)) {
        observations = this.getObservationsByStates(rawData, [state]);
      }
    } else if (type === 'ca') {
      if (includes(['resolved', 'workorder', 'fixed'], state)) {
        observations = this.getObservationsByStates(rawData, [state]);
      } else if (includes(['new', 'escalated'], state)) {
        observations = this.getObservationsByStates(rawData, ['new', 'escalated']);
      }
    } else if (type === 'si') {
      observations = this.getObservationsByStates(rawData, [state]);
    } else if (includes(['compliment', 'behavior'], type)) {
      observations = rawData.rows.resolved.items;
    } else if (type === 'ai') {
      if (includes(['resolved', 'workorder', 'fixed'], state)) {
        observations = this.getObservationsByStates(rawData, [state]);
      } else if (includes(['new', 'escalated'], state)) {
        observations = this.getObservationsByStates(rawData, ['new', 'escalated']);
      }
    }

    const columns: any[] = this.columnBuilder.buildTableColumn({id: activeTab});
    let baseHref: string = environment.baseHref;
    if (includes(baseHref, '/')) {
      baseHref = replace(baseHref, /\//g, '');
      if (baseHref) {
        baseHref += '/';
      }
    }
    const order: any[] = get(JSON.parse(localStorage.getItem(`DataTables_${activeTab}_/${baseHref}${url}`)), 'order', []);

    return this.sortTableData(observations, columns, order);
  }

  public getObservationsByStates(rawData: any, states: string[]): any {
    return flatten(map(states, (state: string) => get(rawData, `rows.${state}.items`, [])));
  }

  public fillCount(tabArray, tableData) {
    each(tabArray, element => {
      element.count = get(tableData[element.id], 'length', 0);
    });
    return tabArray;
  }

  public initDataTable(idReference, dataSet, tab: any, highlight?: string, options: any = {}, recalculate?: boolean) {
    const offset: number = get($(`${idReference}`).closest('.table-container').offset(), 'top') || 0;
    const scrollY: number = $(window).height() - offset - 150;

    if ($(`#${tab.id} thead`).length) {
      const table: any = $(idReference).DataTable();
      table.clear().rows.add(dataSet);

      setTimeout(() => {
        this.loadingService.disable();
        if (recalculate) {
          table.rows().recalcHeight().draw();
        }
      });
      this.tableService.handleResize('.dataTables_scrollBody:visible:last', 50, 200, false);
      return;
    }
    // this method uses datatable's native table builder
    $(idReference).show();

    if (get(options, 'columns.length', 0)) {
      options.columns = this.columnBuilder.addIconAndTranslate(options.columns);
    }

    const dataTableConfig: DataTableOptions = Object.assign({}, {
      data: dataSet,
      stateSave: true, // saves the state, selected column, ordered column etc..
      destroy: true,  // kills table and draws new when new data set comes in
      paging: true,  // imp for virtual scroll
      searching: false,  // top search bar for DT
      info: false,
      language: {
        searchPlaceholder: this.translate.instant('SHARED.Search'),
        search: '',
        emptyTable: this.translate.instant('SHARED.emptyTable'),
        sZeroRecords: this.translate.instant('SHARED.sZeroRecords'),
        loadingRecords: this.translate.instant('DASHPAGES.Loading_Observations')
      },
      deferRender: true, // part of the plugin for virtual scroll, paging= true
      scroller: { // part of virtual scroll
        loadingIndicator: true
      },
      scrollY: `${scrollY}px`,
      scrollX: true,  // scroll enabled horizontally
      dom: 'Blfrtip', // initailized button elements
      order: [[0, 'desc']], // default sorting by OID
      buttons: {
        buttons: [
          $.extend(true, {}, {   // settings on how exported XLS should look like
            exportOptions: {
              columns: ':visible',
              orthogonal: 'export', // check on column builder to see how export type is defined
              stripNewlines: 'false',
            }
          }, {
            extend: 'excel',
            text: this.translate.instant('SHARED.Save_to_Excel'),
            className: 'button-styled',
            title: ''
          }),
          {
            extend: 'colvis',  // settings for select/hide columns
            columns: ':not(.no-vis)',
            text: this.translate.instant('MGMT_LIST.Select_Columns'),
            className: 'button-styled',
            collectionLayout: 'three-column',
          }
        ]
      },
      columns: this.columnBuilder.buildTableColumn(tab),  // figures out what to display for types of observations
      colReorder: {
        enable: false,
        fixedColumnsLeft: 1,
        realtime: false
      },
      columnDefs: [{
        sSortDataType: `custom-order-${tab.id}`,
        targets: '_all'
      }],
      drawCallback: () => this.markSearchResult(idReference)
    }, options);

    this.tableService.validateColumns(dataTableConfig);

    const t: any = $(idReference).DataTable(dataTableConfig);

    $(idReference).width('100%');
    $(t.table().header()).addClass('table-header sorting');  // Adding custom header class
    t.search('').draw();
    this.loadingService.disable();
    this.tableService.handleResize();

    setTimeout(() => {
      t.columns.adjust().draw(true);
    });

    $(`${idReference} tbody`).off('click').on('click', 'tr', (event: Event) => {
      const data = t.row(event.currentTarget).data();
      const tableArr = t.columns(0).data().eq(0);

      if (data) {
        this.loadingService.enable().then(() => {
          if (options.onClick) {
            options.onClick(data);
          } else {
            this.observationDetail.navigateToDetail(data, tableArr);
          }
        });
      }
    });

    (<any>$.fn).dataTableExt.afnSortData[`custom-order-${tab.id}`] = (oSettings) => {
      const columns: any = this.columnBuilder.buildTableColumn(tab);
      const order: any = t.order();
      let observations: any = map(cloneDeep(oSettings.aoData), (observationItem: any) => {
        const observation = observationItem._aData;
        observation.idx = observationItem.idx;
        return observation;
      });

      observations = this.sortTableData(observations, columns, order);
      oSettings.aiDisplayMaster = map(observations, 'idx');
      return oSettings;
    };
  }

  findSiteHealthColor(riskScore) {
    let riskScoreColor;
    if (riskScore >= 60) {
      riskScoreColor = '#D0021B';
    } else if (riskScore < 60 && riskScore >= 30) {
      riskScoreColor = '#FAD400';
    } else if (riskScore > 0 && riskScore < 30) {
      riskScoreColor = '#279F3E';
    } else {
      riskScoreColor = 'black';
    }
    return riskScoreColor;
  }

  filterValuesAvailable(filterComps) {
    let filterAvailable = false;
    each(filterComps, comp => {
      if (comp.value && comp.value.length > 0) {
        filterAvailable = true;
        return false;
      }
    });
    return filterAvailable;
  }

  public updateRouterActiveTab(activeTab: string): void {
    this.router.navigate(
      [],
      {
        relativeTo: this.route,
        queryParams: {activeTab}
      }
    );
  }

  public openObservationDetail(observationId: number, replaceUrl = true, hideHeader = false) {
    const observation: any = this.observationService.observations.data[observationId];

    if (observation) {
      const observations: any = this.getNearestObservationsById(observationId);
      const observationsIds: string[] = map(observations, 'observationID');
      this.observationDetail.navigateToDetail(observation, sortBy(observationsIds), replaceUrl, undefined, {}, hideHeader);
    }

    return !!observation;
  }

  public getFooterData(id: number): any {
    this.updateNearestObservationIdsByObservationId(id);
    return this.observationDetail.getFooterData(id);
  }

  public clearFilterObject(): void {
    this.filterObject = {};
  }

  public async buildTableByTab({
                                 tab,
                                 tableData,
                                 filterString,
                                 loadingState,
                                 isTabChanging,
                                 recalculate,
                                 disableLoading
                               }: TableConfig) {
    if (!disableLoading) {
      await this.loadingService.enable();
    }
    const tableId = `#${tab.id}`;
    const isTableShown: boolean = $(`${tableId} thead`).length > 0;

    setTimeout(() => {
      this.loadingService.disable();

      if (isTabChanging && isTableShown && !loadingState[tab.id]) {
        this.tableService.handleResize('.dataTables_scrollBody:visible:last', 50, 0);
        setTimeout(() => {
          $(tableId).DataTable().columns.adjust();
        });
        return;
      }
      loadingState[tab.id] = false;

      this.initDataTable(`#${tab.id}`, tableData[tab.id], tab, filterString, {}, recalculate);
    });
  }

  private markSearchResult(selector: string): void {
    const searchString: string = get(this.filterObject, 'searchString');

    if (searchString) {
      const instance = new Mark(document.querySelector(`${selector}.dataTable tbody`));

      if (instance) {
        instance.mark(searchString, {element: 'span', className: 'highlight'});
      }
    }
  }

  private sortTableData(observations: any[], columns: any, order: any[]): any {
    if (columns.length && order.length && observations.length) {
      observations = orderBy(observations, this.dataTableNavigationService.getOrderFieldsByColumns(observations, order, columns), this.dataTableNavigationService.getOrder(order));
    }

    return observations;
  }

// public prepareCaTableData(rawData): [any] {
// each(rawData.rows, obsCollection =>{
//   // get following info from check with obs.responseID, once the data comes in
//   each(obsCollection.items, obs =>{
//     obs.check = {
//       name: 'Forklift Pre-check',
//       question: 'It be working?',
//       answer: 'fail',
//       target: 'Forklift 1',
//       type: 'Asset'
//     }
//   });
// });
// return rawData;
// }

}
