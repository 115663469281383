import { Injectable, Injector } from '@angular/core';

import { ObservationSummaryModelService } from '@modules/reporting/models/types/obsSummary/service';
import { BaseField, ReportBaseClass, ReportClass } from '@modules/reporting/models/types/base';

import { SelectFiltersFormComponent } from '@modules/reporting/components';
import { FormField } from '@services';
import { IObjectStringKeyMap } from '@shared/models';
import {
  assign,
  clone,
  cloneDeep,
  difference,
  each,
  filter,
  find,
  flatten,
  forEach,
  get,
  has,
  indexOf,
  intersectionBy,
  isArray,
  isUndefined,
  keys,
  map,
  merge,
  orderBy,
  sortBy,
  unionBy,
  values
} from 'lodash';
import { FolderDataType } from '@modules/management/modules/folders/model/folders.interfaces';

@Injectable()
export class obsSummaryReport extends ReportBaseClass {

  public reportType = ReportClass.Observation;
  private observationSummaryModelService: ObservationSummaryModelService = this.injector.get(ObservationSummaryModelService);
  public reportColumnOptions = this.observationSummaryModelService.getColumns();
  public reportFieldOptions = this.observationSummaryModelService.getFieldOptions();

  filtersFields = [
    {
      containerClass: 'report-field obsdets observation',
      title: this.translate.instant('SHARED.Observation_Type'),
      name: 'obstype',
      type: 'selectmenu',
      role: 'none',
      multiple: true,
      canDelete: true,
      placeholder: this.translate.instant('SHARED.Any_Type'),
      options: [
        {
          id: 'condition',
          description: this.translate.instant('SHARED.Unsafe_Condition')
        },
        {
          id: 'behavior',
          description: this.translate.instant('SHARED.Coaching_Opportunity')
        },
        {
          id: 'quality',
          description: this.translate.instant('SHARED.Quality')
        },
        {
          id: 'pi',
          description: this.translate.instant('SHARED.Process_Improvement')
        },
        {
          id: 'compliment',
          description: this.translate.instant('SHARED.Thumbs-Up')
        },
        {
          id: 'ca',
          description: this.translate.instant('SHARED.Corrective_Action')
        },
        {
          id: 'si',
          description: this.translate.instant('SHARED.Opportunity')
        },
        {
          id: 'ai',
          description: this.translate.instant('SHARED.Asset_Issues')
        }
      ],
      onRemoveField: (field: any) => this.onRemoveField(field),
      // onChange: (selections) => this.defineObstypeVisibilityByValue(selections)
    },
    {
      containerClass: 'report-field obsdets observation observationType conditionObservation qualityObservation piObservation ',
      title: this.translate.instant('REPORTING.EDIT_Observation_Status'),
      name: 'states',
      type: 'checkbox',
      role: 'none',
      class: 'inline-checkboxes',
      multiple: true,
      canDelete: true,
      placeholder: 'Any Status',
      options: [
        {
          id: 'open',
          description: this.translate.instant('SHARED.Open')
        },
        {
          id: 'unassigned',
          description: this.translate.instant('SHARED.Unassigned')
        },
        {
          id: 'fixed',
          description: this.translate.instant('SHARED.Marked_as_Fixed')
        },
        {
          id: 'closed',
          description: this.translate.instant('SHARED.Closed')
        }
      ],
      onRemoveField: (field: any) => this.onRemoveField(field),
    },
    {
      containerClass: 'report-field obsdets observation observationType conditionObservation',
      name: 'ownershipStatus',
      title: this.translate.instant('REPORTING.EDIT_Ownership_Status'),
      type: 'checkbox',
      role: 'none',
      class: 'inline-checkboxes',
      multiple: true,
      canDelete: true,
      placeholder: 'Any Status',
      options: [
        {
          id: 'assigned',
          description: this.translate.instant('SHARED.Assigned')
        },
        {
          id: 'unassigned',
          description: this.translate.instant('SHARED.Unassigned')
        }
      ],
      onRemoveField: (field: any) => this.onRemoveField(field),
    },
    {
      containerClass: 'report-field obsdets observation observationType conditionObservation',
      type: 'flipswitch',
      name: 'hasWorkorder',
      title: this.translate.instant('REPORTING.EDIT_Has_Workorder'),
      onText: this.translate.instant('SHARED.Yes'),
      offText: this.translate.instant('SHARED.No'),
      value: 1,
      canDelete: true,
      onRemoveField: (field: any) => this.onRemoveField(field),
    },
    {
      containerClass: 'report-field obsdets observation observationType conditionObservation',
      title: this.translate.instant('REPORTING.EDIT_Condition_Observation_Categories'),
      name: 'categories',
      type: 'selectmenu',
      placeholder: this.translate.instant('SHARED.Any_Category'),
      multiple: true,
      canDelete: true,
      valueProperty: 'messageID',
      options: this.settingsService.getSettingSync('category', this.userdataService.locations),
      onRemoveField: (field: any) => this.onRemoveField(field),
      func: (ref) => this.showDeactivated(ref.messageTitle, ref)
    },
    {
      containerClass: 'report-field obsdets observation observationType conditionObservation',
      title: this.translate.instant('SHARED.Severity'),
      name: 'severities',
      type: 'checkbox',
      class: 'inline-checkboxes',
      role: 'none',
      multiple: true,
      canDelete: true,
      options: this.reportService.severities,
      onRemoveField: (field: any) => this.onRemoveField(field),
      func: (item) => {
        const dot = this.reportService.colorDot(item.id);
        return '<img width=\'24\' src=\'' + dot + '\' />';
      }
    },
    {
      containerClass: 'report-field obsdets observation observationType conditionObservation',
      title: this.translate.instant('SHARED.Likelihood'),
      name: 'likelihoods',
      type: 'checkbox',
      multiple: true,
      canDelete: true,
      class: 'inline-checkboxes',
      role: 'none',
      options: this.reportService.likelihoods,
      onRemoveField: (field: any) => this.onRemoveField(field),
      func: (item) => {
        const dot = this.reportService.colorDot(item.id);
        return '<img width=\'24\' src=\'' + dot + '\' />';
      }
    },
    {
      containerClass: 'report-field obsdets observation',
      title: this.translate.instant('MGMT_LIST.Coaching_Opportunity_Categories'),
      name: 'behaviors',
      type: 'selectmenu',
      placeholder: this.translate.instant('SHARED.Any_Category'),
      multiple: true,
      canDelete: true,
      valueProperty: 'messageID',
      options: this.settingsService.getSettingSync('behavior', this.userdataService.locations),
      onRemoveField: (field: any) => this.onRemoveField(field),
      func: (ref) => this.showDeactivated(ref.messageTitle, ref)
    },
    {
      containerClass: 'report-field obsdets observation',
      title: this.translate.instant('REPORTING.EDIT_Mitigation_Categories'),
      name: 'mitigations',
      type: 'selectmenu',
      placeholder: this.translate.instant('SHARED.Any_Category'),
      multiple: true,
      canDelete: true,
      valueProperty: 'messageID',
      options: this.settingsService.getSettingSync('mitigation', this.userdataService.locations),
      onRemoveField: (field: any) => this.onRemoveField(field),
      func: (ref) => this.showDeactivated(ref.messageTitle, ref)
    },
    {
      containerClass: 'report-field obsdets observation',
      title: this.translate.instant('REPORTING.EDIT_Quality_Categories'),
      name: 'qualitycats',
      type: 'selectmenu',
      placeholder: this.translate.instant('SHARED.Any_Category'),
      multiple: true,
      canDelete: true,
      valueProperty: 'messageID',
      options: this.settingsService.getSettingSync('quality', this.userdataService.locations),
      onRemoveField: (field: any) => this.onRemoveField(field),
      func: (ref) => this.showDeactivated(ref.messageTitle, ref)
    },
    {
      containerClass: 'report-field obsdets observation',
      title: this.translate.instant('REPORTING.EDIT_PI_Categories'),
      name: 'picats',
      type: 'selectmenu',
      placeholder: this.translate.instant('SHARED.Any_Category'),
      multiple: true,
      canDelete: true,
      valueProperty: 'messageID',
      options: this.settingsService.getSettingSync('pi', this.userdataService.locations),
      onRemoveField: (field: any) => this.onRemoveField(field),
      func: (ref) => this.showDeactivated(ref.messageTitle, ref)
    },
    {
      containerClass: 'report-field obsdets observation',
      title: this.translate.instant('REPORTING.EDIT_Thumbs_Up_Categories'),
      name: 'compliments',
      type: 'selectmenu',
      placeholder: this.translate.instant('SHARED.Any_Category'),
      multiple: true,
      canDelete: true,
      valueProperty: 'messageID',
      options: this.settingsService.getSettingSync('compliment', this.userdataService.locations),
      onRemoveField: (field: any) => this.onRemoveField(field),
      func: (ref) => this.showDeactivated(ref.messageTitle, ref)
    },
    this.baseFields[BaseField.Locations],
    {
      containerClass: 'report-field obsdets team worker zone observation',
      title: this.translate.instant('SHARED.Zones'),
      name: 'zones',
      type: 'selectmenu',
      multiple: true,
      canDelete: true,
      placeholder: this.translate.instant('SHARED.Any_Zone'),
      options: this.zones,
      onRemoveField: (field: any) => this.onRemoveField(field),
      func: (ref) => this.showDeactivated(ref.id, ref)
    },
    {
      containerClass: 'report-field obsdets observation',
      title: this.translate.instant('REPORTING.EDIT_Creators'),
      name: 'creators',
      type: 'selectmenu',
      multiple: true,
      canDelete: true,
      valueProperty: 'userID',
      placeholder: this.translate.instant('REPORTING.EDIT_Any_Creator'),
      originalOrder: true,
      options: this.getWorkerOptions(),
      onRemoveField: (field: any) => this.onRemoveField(field),
      func: (ref) => this.showDeactivated(this.userService.getFullname(ref.userID), ref)
    },
    {
      containerClass: 'report-field obsdets observation',
      title: this.translate.instant('REPORTING.EDIT_Creator_Teams'),
      name: 'creatorGroups',
      type: 'selectmenu',
      placeholder: this.translate.instant('SHARED.Any_Team'),
      multiple: true,
      canDelete: true,
      valueProperty: 'groupID',
      options: this.getTeamOptions(),
      func: (group) => this.showDeactivated(group.name, group),
      onRemoveField: (field: any) => this.onRemoveField(field),
      originalOrder: true
    },
    {
      containerClass: 'report-field obsdets observation',
      title: this.translate.instant('REPORTING.EDIT_Owners'),
      name: 'owners',
      type: 'selectmenu',
      multiple: true,
      canDelete: true,
      placeholder: this.translate.instant('REPORTING.EDIT_Any_Owner'),
      valueProperty: 'userID',
      options: this.accounts,
      onRemoveField: (field: any) => this.onRemoveField(field),
      func: (ref) => this.showDeactivated(this.userService.getFullname(ref.userID), ref)
    },
    this.baseFields[BaseField.Groups],
    {
      containerClass: 'report-field obsdets observation team worker zone',
      title: this.translate.instant('REPORTING.EDIT_Tag'),
      name: 'tags',
      type: 'selectmenu',
      placeholder: this.translate.instant('REPORTING.EDIT_Any_Tag'),
      multiple: true,
      canDelete: true,
      valueProperty: 'tagID',
      options: sortBy(this.settingsService.customTags.data, 'tag'),
      func: (tag) => this.showDeactivated(tag.tag, tag),
      onRemoveField: (field: any) => this.onRemoveField(field),
      originalOrder: true
    },
    {
      containerClass: 'report-field obsdets observation',
      title: this.translate.instant('REPORTING.EDIT_Receiving_Workers'),
      name: 'recipients',
      type: 'selectmenu',
      multiple: true,
      canDelete: true,
      placeholder: this.translate.instant('SHARED.Any_User'),
      valueProperty: 'userID',
      options: this.accounts,
      onRemoveField: (field: any) => this.onRemoveField(field),
      func: (ref) => this.showDeactivated(this.userService.getFullname(ref.userID), ref)
    },
    {
      containerClass: 'report-field obsdets observation',
      title: this.translate.instant('SHARED.Notification__Receiving_Teams'),
      name: 'targetGroups',
      type: 'selectmenu',
      placeholder: this.translate.instant('SHARED.Any_Team'),
      multiple: true,
      canDelete: true,
      valueProperty: 'groupID',
      options: this.getTeamOptions(),
      func: (group) => this.showDeactivated(group.name, group),
      onRemoveField: (field: any) => this.onRemoveField(field),
      originalOrder: true
    },
    {
      containerClass: 'report-field obsdets observation',
      title: this.translate.instant('SHARED.Creator_Role'),
      name: 'creatorRole',
      type: 'selectmenu',
      placeholder: this.translate.instant('SHARED.Any_Role'),
      multiple: true,
      canDelete: true,
      valueProperty: 'roleID',
      options: this.rolesService.roles.data,
      func: (role) => this.showDeactivated(role.name, role),
      onRemoveField: (field: any) => this.onRemoveField(field),
      originalOrder: true
    },
    {
      containerClass: 'report-field obsdets obsDetail observation',
      title: this.translate.instant('SHARED.Date_Created'),
      placeholder: this.translate.instant('REPORTING.EDIT_Any_Date'),
      name: 'dateCreated',
      type: 'dateRangePicker',
      required: false,
      multiple: false,
      canDelete: true,
      options: this.reportService.timespans,
      onChange: (value: any) => {
        if (value.type === 'custom') {
          assign(this.reportService.customDateCreated, value.range);
        }
      },
      onRemoveField: (field: any) => this.onRemoveField(field),
      originalOrder: true,
      default: null
    },
    {
      containerClass: 'report-field obsdets obsDetail observation',
      title: this.translate.instant('SHARED.Date_Fixed'),
      placeholder: this.translate.instant('REPORTING.EDIT_Any_Date'),
      name: 'dateFixed',
      type: 'dateRangePicker',
      required: false,
      multiple: false,
      canDelete: true,
      options: this.reportService.timespans,
      onChange: (value: any) => {
        if (value.type === 'custom') {
          assign(this.reportService.customDateFixed, value.range);
        }
      },
      onRemoveField: (field: any) => this.onRemoveField(field),
      originalOrder: true,
      default: null
    },
    {
      containerClass: 'report-field obsdets obsDetail observation',
      title: this.translate.instant('SHARED.Date_Closed'),
      placeholder: this.translate.instant('REPORTING.EDIT_Any_Date'),
      name: 'dateClosed',
      type: 'dateRangePicker',
      required: false,
      multiple: false,
      canDelete: true,
      options: this.reportService.timespans,
      onChange: (value: any) => {
        if (value.type === 'custom') {
          assign(this.reportService.customDateClosed, value.range);
        }
      },
      onRemoveField: (field: any) => this.onRemoveField(field),
      originalOrder: true,
      default: null
    },
    Object.assign({}, this.baseFields[BaseField.TargetAssets], {
      title: this.translate.instant('SHARED.ASSETS')
    })
  ];
  public fields = [
    {
      containerClass: 'report-field obsdets observation',
      title: this.translate.instant('REPORTING.EDIT_title2'),
      name: 'observationPrimaryField',
      type: 'selectmenu',
      multiple: false,
      required: true,
      originalOrder: false,
      options: cloneDeep(this.reportFieldOptions),
      hideFieldsOnValue: {
        fieldNames: ['observationSecondaryField', 'timespan', 'period', 'reportColumns', 'graphType'],
        value: 'none'
      },
      onChange: () => this.updateGraphFields('secondary')
    },
    {
      containerClass: 'report-field obsdets observation',
      title: this.translate.instant('REPORTING.EDIT_title3'),
      name: 'observationSecondaryField',
      canClear: true,
      type: 'selectmenu',
      placeholder: this.translate.instant('SHARED.None'),
      multiple: false,
      originalOrder: false,
      required: false,
      options: this.reportFieldOptions,
      onChange: () => this.updateGraphFields('primary')
    },
    this.baseFields[BaseField.Timespan],
    this.baseFields[BaseField.LocationTime],
    this.baseFields[BaseField.Period],
    {
      containerClass: 'report-field obsdets observation',
      title: this.translate.instant('REPORTING.EDIT_Data_to_Include'),
      name: 'observationReportColumns',
      type: 'selectmenu',
      placeholder: this.translate.instant('SHARED.None'),
      multiple: true,
      required: true,
      options: this.reportColumnOptions,
      onChange: (values) => this.syncGraphSecondaryField('observationReportColumns', 'observationGraphSecondary', values)
    },
    this.baseFields[BaseField.GraphType],
    {
      containerClass: 'report-field obsdets graphing observation',
      title: this.translate.instant('REPORTING.EDIT_Graph_by'),
      name: 'observationGraphPrimary',
      type: 'selectmenu',
      multiple: false,
      required: false,
      options: unionBy(this.reportFieldOptions, <any>[
        {id: 'period', description: this.translate.instant('SHARED.Period')},
        // { id: 'timespan', description: "Timespan" }
      ], 'id')
    },
    {
      containerClass: 'report-field obsdets graphing observation',
      title: this.translate.instant('REPORTING.EDIT_Include_in_graph'),
      name: 'observationGraphSecondary',
      type: 'selectmenu',
      placeholder: this.translate.instant('SHARED.None'),
      multiple: false,
      required: false,
      options: this.reportColumnOptions
    },
    this.baseFields[BaseField.IncludingAll],
    this.baseFields[BaseField.ShowDataLabels],
    {
      containerClass: 'report-field obsdets observation check checkDetail ccsFormSubdivider',
      title: this.translate.instant('REPORTING.EDIT_Report_Data_Selection'),
      type: 'divider',
    },
    {
      containerClass: 'custom-data-field report-field observation',
      title: this.translate.instant('SHARED.Select_Filters'),
      name: 'filtersFields',
      type: 'customElement',
      component: SelectFiltersFormComponent,
      inputs: {
        options: cloneDeep(this.filtersFields),
        selectedItems: []
      },
      outputs: {
        onChanged: (selectedItems) => {
          const field = <FormField>find(this.formConfig.fields, {name: 'filtersFields'});
          (field.componentRef as SelectFiltersFormComponent).selectedItems = selectedItems;
          this.removedFilters = difference(this.filters, selectedItems);
          this.filters = selectedItems;
          this.showFilters();
        }
      },
      options: cloneDeep(this.filtersFields)
    },
  ];

  constructor(protected injector: Injector) {
    super(injector);
  }

  public init(reportData) {
    // this.defineObstypeVisibilityByValue(reportData.selectors.obstype);
    super.init(reportData);
    this.syncGraphSecondaryField('observationReportColumns', 'observationGraphSecondary');
  }

  public async report(theTable, theChart, report, reportId?: number) {
    this.reportData = report;
    // try {
    const opts = cloneDeep(report.selectors);
    const primaryGroup: number = get(this.accountsService.getAccount(this.userdataService.userID), 'primaryGroup');

    if (primaryGroup) {
      opts.groups = map(opts.groups, (group: number) => group === -1 ? primaryGroup : group);
    }

    const timeByTimespan = this.getTimeByTimespan(opts.timespan, opts.startTime, opts.endTime);
    opts.startTime = timeByTimespan.startTime;
    opts.endTime = timeByTimespan.endTime;
    // opts.includingAll = !opts.hasOwnProperty('locations') || opts.locations.length === 0 ? opts.includingAll : '';
    if (!opts.hasOwnProperty('locations') || opts.locations.length === 0) {
      opts.locations = this.userdataService.locations;
    }

    // prune out meaninless options - empty arrays are the same
    // as "all"

    each(keys(opts), item => {
      const rItem = opts[item];
      if (isArray(rItem)) {
        if (!rItem.length) {
          delete opts[item];
        }
      }
    });

    const r = await this.findInterval(opts);
    const tableDef = merge({}, this.tableDef);

    // create a table using the primary (and possibly secondary columns)
    const pRef: any = find(this.reportFieldOptions, {id: opts.primary});
    const sRef: any = find(this.reportFieldOptions, {id: opts.secondary});

    if (pRef) {
      tableDef.columns.push({
        id: pRef.id,
        title: pRef.label,
        fromID: pRef.fieldName,
        headerClass: 'text-left',
        class: 'tableexport-string'
      });
    }
    if (sRef) {
      tableDef.columns.push({
        id: sRef.id,
        title: sRef.label,
        fromID: `secondary_${sRef.fieldName}`,
        headerClass: 'text-left',
        class: 'tableexport-string'
      });
      tableDef.groupBy = 0;
    }
    let counter = 0;

    // create columns for each period

    const totalGroup = opts.period === 'none' ? null : 'total';
    if (totalGroup) {
      tableDef.colgroups[totalGroup] = {title: 'TOTAL'};
    }

    if (opts.period !== 'none') {
      forEach(r.intervals, (ref) => {
        const item = 'p' + counter;
        if (opts.extraColumns) {
          tableDef.colgroups[item] = {title: ref.label};
          each(opts.extraColumns, (colname, index: number) => {
            const column = find(this.reportColumnOptions, {id: colname});
            tableDef.columns.push(this.addColumn(column, index, item + colname, ref.label));
          });
        }
        counter++;
      });
    }

    // need columns for the totals of any extra columns
    if (opts.extraColumns) {
      each(opts.extraColumns, (colname, index: number) => {
        const column = find(this.reportColumnOptions, {id: colname});
        tableDef.columns.push(this.addColumn(column, index, 'total' + colname, '', true));
      });
    }


    // okay - iterate over the returned data, sorting by primary and possibly secondary key
    let rowRefs = [];
    // first, order the primary labels
    const rows = flatten(values(this.filterCategories(r.rows, opts)));
    const pOrder = this.utils.sortArray(rows, [
      {
        name: 'label',
        function: (item) => {
          if (item && typeof item === 'string') {
            return item.toLowerCase();
          } else {
            return item;
          }
        }
      }]
    );
    const significantPrimaryKeys = [];
    const significantSecondaryKeys = [];
    if (sRef) {
      // we have a secondary key
      each(pOrder, (pitem: any, pds) => {
        let significant = false;
        const sOrder = this.utils.sortArray(map(pitem.secondary), [{
          name: 'label',
          function: (item) => {
            if (item && typeof item === 'string') {
              return item.toLowerCase();
            } else {
              return item;
            }
          }
        }]);
        each(sOrder, (item, sds) => {
          if (item.items.length || opts.includingAll) {
            significant = true;
            if (indexOf(significantSecondaryKeys, item.label) < 0) {
              significantSecondaryKeys.push(item.label);
            }
            // there were matching items
            // push this data into a row
            const d = item;
            d.totalObservations = item.items.length;
            each(item.extras, (value, col) => {
              d['total' + col] = value;
            });
            each(item.intervals, (iref, name) => {
              d[name] = iref.items.length;
              each(iref.extras, (value, col) => {
                d[name + col] = value;
              });
            });
            rowRefs.push(d);
          }
        });
        if (significant) {
          if (indexOf(significantPrimaryKeys, pitem.label) < 0) {
            significantPrimaryKeys.push(pitem.label);
          }
        }
      });
    } else {
      // we only have a primary key
      each(pOrder, (item: any, pds) => {
        if (item.items.length || opts.includingAll) {
          if (indexOf(significantPrimaryKeys, item.label) < 0) {
            significantPrimaryKeys.push(item.label);
          }
          // push this data into a row
          const d = item;
          d.totalObservations = d.items.length;
          each(item.extras, (value, col) => {
            d['total' + col] = value;

          });
          each(item.intervals, (iref, name) => {
            d[name] = iref.items.length;
            each(iref.extras, (value, col) => {
              d[name + col] = value;
            });
          });
          rowRefs.push(d);
        }
      });
    }

    if (theChart) {
      // okay all the rows are gathered.  Do we need to graph?
      if (opts.graphType !== 'none') {
        const observationReportFieldOptions = clone(this.reportFieldOptions);
        const observationReportColumnOptions = clone(this.reportColumnOptions);
        const keys = {
          primary: significantPrimaryKeys,
          secondary: significantSecondaryKeys
        };

        // if we are graphing by observation category, let's send in an ordered list of colors
        let graphData: any;
        if (opts.graphPrimary === 'period') {
          graphData = this.graphService.graphdataByPeriod(opts, r, rowRefs, keys, observationReportFieldOptions, observationReportColumnOptions);
        } else if (opts.graphPrimary === opts.primary) {
          graphData = this.graphService.graphdataByPrimary(opts, rowRefs, keys, observationReportFieldOptions, observationReportColumnOptions);
        } else if (opts.secondary && opts.graphPrimary === opts.secondary) {
          graphData = this.graphService.graphdataBySecondary(opts, rowRefs, keys, observationReportFieldOptions, observationReportColumnOptions);
        }
        if (graphData) {
          $('.report-view-has-chart').show();
          this.drawReportGraph(theChart, graphData, opts, reportId);
        }
      } else {
        $('.report-view-has-chart').hide();
      }
    }
    if (theTable) {
      rowRefs = orderBy(rowRefs, 'label', ['asc', 'desc']);
      tableDef.data = rowRefs;
      tableDef.columns = this.preparationColumns(tableDef.columns);
      this.tableService.showDataTable(theTable, tableDef);
    }

    // } catch (err) {
    // this.loadingService.disable();
    // window.alert('report build failed: ' + err.message + '\n' + err.stack);
    // console.log(err.stack);
    // }
  }

  public async findInterval(opts) {
    const intervals = this.observations.calendarInterval(opts.startTime, opts.endTime, opts.period, opts.timespan, opts.format);

    if (!this.observations.isLoaded()) {
      await this.observations.waitUntilLoaded();
    }

    const obs = this.observations.observations.data;

    let primary: IObjectStringKeyMap<any> = {};
    const primaryKeys = {};
    const secondaryKeys = {};

    const pRef: any = find(this.reportFieldOptions, {id: opts.primary});
    const sRef: any = find(this.reportFieldOptions, {id: opts.secondary});

    primary = this.getPrimary(opts, sRef, intervals);

    if (opts?.targetAssets?.length) {
      opts.assetFolderTree = this.foldersDataService.getFolderTreeBy(FolderDataType.ASSET);
    }

    opts.intervals = {
      allTime: {
        start: opts.startTime,
        end: opts.endTime,
        timezones: {}
      }
    };

    // accumulate iknformation about every observation OPENED in the range of the report
    // if the locationTime option is set, then adjust those times as needed
    forEach(obs, (ref: any) => {
      const ctime = ref.created * 1000;
      if (this.observations.outOfRange(opts, ref, ctime, opts.intervals.allTime)) {
        // this one is out of our range
        return;
      }
      if (!this.observations.checkObservation(ref, opts)) {
        // this one doesn't match the filters
        return;
      }

      if (ref.type === 'ca') {
        if (ref.caCategory && +ref.caCategory.split(':')[1]) {
          ref.categories = [0];
        }
        if (ref.caTags && ref.caTags !== '[]') {
          ref.tags = map(ref.caTags.replace('[', '').replace(']', '').split(','), Number) || [];
        }
      }

      const createItem = (key: string, label: string) => {
        const newItem: any = {
          extras: {},
          items: [],
          label,
          intervals: {},
          [pRef.fieldName]: label,
          fieldName: pRef.fieldName
        };

        primaryKeys[label] = key;

        if (sRef) {
          newItem.secondary = {};
        } else {
          if (opts.interval !== 'none') {
            each(intervals, (iref, counter) => {
              const iname = 'p' + counter;
              newItem.intervals[iname] = {extras: {}, items: []};
            });
          }
        }

        return newItem;
      };

      const findItem = (key: string, label: string) => {
        if (!has(primary, key)) {
          primary[key] = createItem(key, label);
        } else {
          const item = find(flatten([primary[key]]), <any>{label});

          if (!item) {
            primary[key] = [primary[key], createItem(key, label)];
          }
        }

        return find(flatten([primary[key]]), <any>{label});
      };

      // function to accumulate an individual item
      const addItem = (p, s) => {
        if (p[0] !== undefined) {
          let target = null;
          // we have a key
          const item: any = findItem(p[0], p[1]);
          if (sRef) {
            if (s[0] !== undefined) {
              // we have a key
              if (!has(secondaryKeys, s[1])) {
                // remember it
                secondaryKeys[s[1]] = s[0];
              }

              if (!has(item.secondary, s[0])) {
                // we don't have this bucket yet
                item.secondary[s[0]] = {
                  extras: {},
                  items: [],
                  label: s[1],
                  intervals: {},
                  fieldName: pRef.fieldName,
                  secondFieldName: sRef.fieldName,
                  [pRef.fieldName]: p[1],
                  [`secondary_${sRef.fieldName}`]: s[1]
                };
                // add in the interval buckets too
                if (opts.interval !== 'none') {
                  each(intervals, (iref, counter) => {
                    const iname = 'p' + counter;
                    item.secondary[s[0]].intervals[iname] = {extras: {}, items: []};
                  });
                }
              }
              item.secondary[s[0]].items.push(ref);
              target = item.secondary[s[0]];
            }
          } else {
            item.items.push(ref);
            target = item;
          }

          if (opts.interval !== 'none') {
            forEach(intervals, (iref, counter) => {
              // in each interval, add the data into the primary and secondary accumulators
              if (ctime >= iref.start && ctime <= iref.end) {
                const iname = 'p' + counter;
                target.intervals[iname].items.push(ref);
                // we found a bucket for this observation
                return false;
              } else {
                return true;
              }
            });
          }
        }
      };

      // hold the primary key and label
      // get the primary field value from the record
      let pvList = get(ref, pRef.fieldName);
      if (pvList === undefined) {
        if (pRef.fieldRequired) {
          pvList = null;
        }
      }
      if (!isArray(pvList)) {
        pvList = [pvList];
      }
      each(pvList, (pv) => {
        let p = [];
        if (pv == null) {
          return;
        }
        if (pRef.hasOwnProperty('fieldFunc')) {
          // use the field function to derive the key and label
          // vrom the primary value
          p = pRef.fieldFunc(pv, ref);
        } else {
          // there is no translation function.  let's just use the id as
          // the key and the value
          p = [pv, pv];
        }
        if (sRef) {
          // hold the primary key and label
          let s = [];
          // get the primary field value from the record
          let svList = get(ref, sRef.fieldName);
          if (svList === undefined) {
            if (sRef.fieldRequired) {
              svList = null;
            }
          }
          if (!isArray(svList)) {
            svList = [svList];
          }
          each(svList, (sv) => {
            if (sv == null) {
              return;
            }
            if (sRef.hasOwnProperty('fieldFunc')) {
              // use the field function to derive the key and label
              // vrom the primary value
              s = sRef.fieldFunc(sv, ref);
            } else {
              // there is no translation function.  let's just use the id as
              // the key and the value
              s = [sv, sv];
            }
            if (p && s) {
              addItem(p, s);
            }
          });
        } else {
          if (p) {
            addItem(p, [null, null]);
          }
        }
      });
    });

    // okay, we have every row populated now

    const extras = {};
    let extraColumns: any[] = get(opts, 'extraColumns', []);
    if (!isArray(extraColumns)) {
      extraColumns = [extraColumns];
    }

    each(extraColumns, (column) => {
      // get a ref to the column definiton
      const c: any = find(this.reportColumnOptions, {id: column});

      // iterate over everything to do the calculations at each interval and each primary/secondary
      each(primary, (primaryItem: any) => {
        each(flatten([primaryItem]), (pItem) => {
          const updateIntervals = (theItem) => {
            if (opts.interval !== 'none') {
              each(theItem.intervals, (intervalRef) => {
                intervalRef.extras[column] = c.func(intervalRef.items);
              });
            }
          };

          pItem.extras[column] = c.func(pItem.items);
          if (opts.secondary) {
            // lets do this
            each(pItem.secondary, (sItem) => {
              sItem.extras[column] = c.func(sItem.items);
              updateIntervals(sItem);
            });
          } else {
            updateIntervals(pItem);
          }
        });
      });
    });

    // build an object of the accumulated data

    const r = {
      rows: opts.secondary ? primary : this.filterCategories(primary, opts),
      intervals,
      primaryKeys,
      secondaryKeys
    };

    return r;
  }

  public async getTableItems(queryParams: any): Promise<any> {
    let rows: any = flatten(values(get(await this.findInterval(queryParams), 'rows')));
    let currentRow: any = find(rows, <any>{itemID: +queryParams.primaryID}) || filter(rows, <any>{label: queryParams.primaryLabel}) || {};
    let tableItems: any[] = [];
    if (isArray(currentRow)) {
      let mergedCurrentRow = {}
      forEach(currentRow, (cr) => {
        merge(mergedCurrentRow, cr);
      })
      currentRow = mergedCurrentRow;
    }
    if (currentRow.secondary) {
      if (queryParams.secondaryLabel) {
        if (isUndefined(queryParams.index)) {
          tableItems = get(find(currentRow.secondary, <any>{label: queryParams.secondaryLabel}), 'items', []);
        } else {
          currentRow = find(currentRow.secondary, <any>{label: queryParams.secondaryLabel});
          tableItems = get(map(currentRow.intervals, 'items'), `[${queryParams.index}]`, []);
        }
      } else {
        tableItems = flatten(map(filter(currentRow.secondary, <any>{[currentRow.fieldName]: currentRow[currentRow.fieldName]}), 'items'));

        if (tableItems.length === 0) {
          tableItems = flatten(map(currentRow.secondary, 'items'));
        }
      }
    } else {
      if (isUndefined(queryParams.index)) {
        tableItems = currentRow.items;
      } else {
        tableItems = get(map(currentRow.intervals, 'items'), `[${queryParams.index}]`, []);
      }
    }
    return intersectionBy(tableItems, 'observationID');
  }

  private defineObstypeVisibilityByValue(types: string[]): void {
    setTimeout(() => {
      if (types && types.length) {
        $('.report-field.observationType').hide();
        each(types, (type) => {
          $('.report-field.observationType.' + type + 'Observation').show();
        });
      } else {
        $('.report-field.observationType').show();
      }
      this.syncStates(types);
    });
  }
}
