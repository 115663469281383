import { Injectable, Injector } from '@angular/core';

import { BaseField, ReportBaseClass, ReportClass } from '@modules/reporting/models/types/base';
import { CheckSummaryModelService } from '@modules/reporting/models/types/checkSummary/service';
import { FormField } from '@services';
import { SelectFiltersFormComponent } from '@modules/reporting/components/select-filters-form/select-filters-form.component';

import { awaitHandler } from '@utils/awaitHandler';
import { IObjectStringKeyMap } from '@shared/models';
import {
  clone,
  cloneDeep,
  concat,
  difference,
  each,
  filter,
  find,
  flatten,
  forEach,
  get,
  groupBy,
  has,
  indexOf,
  intersectionBy,
  isArray,
  isEmpty,
  isUndefined,
  map,
  toString as _toString,
  merge,
  orderBy,
  some,
  sortBy,
  split,
  unionBy,
  values, isObject
} from 'lodash';

@Injectable()
export class checkSummaryReport extends ReportBaseClass {

  public checkSummaryModelService: CheckSummaryModelService = this.injector.get(CheckSummaryModelService);
  public reportColumnOptions = this.checkSummaryModelService.getColumns();
  public reportFieldOptions = this.checkSummaryModelService.getFieldOptions();
  public reportType = ReportClass.Check;
  filtersFields = [
    this.baseFields[BaseField.CheckStatus],
    this.baseFields[BaseField.CheckResults],
    this.baseFields[BaseField.CheckType],
    {
      containerClass: 'report-field obsdets check',
      title: this.translate.instant('SHARED.Check_Name'),
      name: 'checkName',
      type: 'selectmenu',
      multiple: true,
      required: false,
      canClear: true,
      canDelete: true,
      role: 'none',
      originalOrder: false,
      valueProperty: 'checkID',
      placeholder: this.translate.instant('SHARED.Choose_a_check'),
      options: this.checksService.checks.data,
      func: (ref: any) => ref.title,
      test: (ref) => this.isFieldDisabledValid(ref),
      onChange: (selections) => this.setOptionsByChecks(selections),
      onRemoveField: (field: any) => this.onRemoveField(field)
    },
    this.baseFields[BaseField.DeploymentType],
    this.baseFields[BaseField.DeploymentName],
    this.baseFields[BaseField.TargetLocations],
    this.baseFields[BaseField.TargetZones],
    this.baseFields[BaseField.TargetAssets],
    this.baseFields[BaseField.TargetTeams],
    this.baseFields[BaseField.TargetPermissions],
    this.baseFields[BaseField.TargetUsers],
    this.baseFields[BaseField.Responders],
    this.baseFields[BaseField.Users],
    this.baseFields[BaseField.Permissions],
    this.baseFields[BaseField.Groups],
    this.baseFields[BaseField.Assigned],
    this.baseFields[BaseField.AssignedTeam],
    this.baseFields[BaseField.AssignedPerms]
  ];
  fields = [
    {
      containerClass: 'report-field check',
      title: this.translate.instant('REPORTING.EDIT_title2'),
      name: 'checkPrimaryField',
      type: 'selectmenu',
      multiple: false,
      required: true,
      originalOrder: false,
      options: this.reportFieldOptions,
      hideFieldsOnValue: {
        fieldNames: ['checkSecondaryField', 'timespan', 'period', 'reportColumns', 'graphType'],
        value: 'none'
      },
      onChange: () => this.updateGraphFields('secondary')
    },
    {
      containerClass: 'report-field check',
      title: this.translate.instant('REPORTING.EDIT_title3'),
      name: 'checkSecondaryField',
      canClear: true,
      type: 'selectmenu',
      placeholder: this.translate.instant('SHARED.None'),
      multiple: false,
      originalOrder: false,
      required: false,
      options: this.reportFieldOptions,
      onChange: () => this.updateGraphFields('primary')
    },
    this.baseFields[BaseField.Timespan],
    this.baseFields[BaseField.Period],
    this.baseFields[BaseField.LocationTime],
    {
      containerClass: 'report-field check',
      title: this.translate.instant('REPORTING.EDIT_Data_to_Include'),
      name: 'checkReportColumns',
      type: 'selectmenu',
      placeholder: this.translate.instant('SHARED.None'),
      multiple: true,
      required: true,
      options: this.reportColumnOptions,
      onChange: (values) => this.syncGraphSecondaryField('checkReportColumns', 'checkGraphSecondary', values)
    },
    this.baseFields[BaseField.GraphType],
    {
      containerClass: 'report-field obsdets graphing check checkDetail',
      title: this.translate.instant('REPORTING.EDIT_Graph_by'),
      name: 'checkGraphPrimary',
      type: 'selectmenu',
      multiple: false,
      required: false,
      options: unionBy(this.reportFieldOptions, <any>[
        {id: 'period', description: this.translate.instant('SHARED.Period')},
        // { id: 'timespan', description: "Timespan" }
      ], 'id')
    },
    {
      containerClass: 'report-field obsdets graphing check checkDetail',
      title: this.translate.instant('REPORTING.EDIT_Include_in_graph'),
      name: 'checkGraphSecondary',
      type: 'selectmenu',
      placeholder: this.translate.instant('SHARED.None'),
      multiple: false,
      required: false,
      options: this.reportColumnOptions
    },
    this.baseFields[BaseField.IncludingAll],
    this.baseFields[BaseField.ShowDataLabels],
    {
      containerClass: 'report-field obsdets observation check checkDetail ccsFormSubdivider',
      title: this.translate.instant('REPORTING.EDIT_Report_Data_Selection'),
      type: 'divider',
    },
    {
      containerClass: 'custom-data-field report-field check',
      title: this.translate.instant('SHARED.Select_Filters'),
      name: 'filtersFields',
      type: 'customElement',
      component: SelectFiltersFormComponent,
      inputs: {
        options: cloneDeep(this.filtersFields),
        selectedItems: []
      },
      outputs: {
        onChanged: (selectedItems) => {
          const field = <FormField>find(this.formConfig.fields, {name: 'filtersFields'});
          (field.componentRef as SelectFiltersFormComponent).selectedItems = selectedItems;
          this.removedFilters = difference(this.filters, selectedItems);
          this.filters = selectedItems;
          this.showFilters();
        }
      },
      options: cloneDeep(this.filtersFields)
    }
  ];
  public checkOpts: any;
  private resps: any;
  private opts: any;

  constructor(protected injector: Injector) {
    super(injector);
  }

  public init(reportData) {
    if (reportData.checkType.length) {
      this.defineCheckVisibilityByValue(reportData.checkType);
    }
    if (reportData.checkName) {
      if (isArray(reportData.checkName)) {
        this.setOptionsByChecks(reportData.checkName, reportData.checkType);
      } else {
        this.setOptionsByChecks([reportData.checkName], reportData.checkType);
      }
    } else {
      // if there is a collection of check types, limit the deployment collection
      if (reportData.checkType.length) {
        const l = this.checksService.getChecksByType(reportData.checkType);
        if (l) {
          // there are checks for these types; get their deploymentIDs
          this.setOptionsByChecks(map(l, 'checkID'));
        }
      }
    }

    super.init(reportData);
    this.syncGraphSecondaryField('checkReportColumns', 'checkGraphSecondary');
  }

  public async report(theTable, theChart, report, reportId?: number) {
    this.reportData = report;
    const opts = cloneDeep(report.selectors);
    const timeByTimespan = this.getTimeByTimespan(opts.timespan, opts.startTime, opts.endTime);
    opts.startTime = timeByTimespan.startTime;
    opts.endTime = timeByTimespan.endTime;

    const tableDef = merge({}, this.tableDef);

    const [r] = await awaitHandler(this.findInterval(opts));

    this.checkOpts = opts;

    // create a table using the primary (and possibly secondary columns)
    const pRef: any = find(this.reportFieldOptions, {id: opts.primary});
    const sRef: any = find(this.reportFieldOptions, {id: opts.secondary});

    if (pRef) {
      tableDef.columns.push({
        id: pRef.id,
        title: pRef.label,
        fromID: pRef.fieldName,
        headerClass: 'text-left',
        class: 'tableexport-string'
      });
    }
    if (sRef) {
      tableDef.columns.push({
        id: sRef.id,
        title: sRef.label,
        fromID: `secondary_${sRef.fieldName}`,
        headerClass: 'text-left',
        class: 'tableexport-string'
      });
      tableDef.groupBy = 0;
    }
    let counter = 0;

    // create columns for each period

    const totalGroup = opts.period === 'none' ? null : 'total';
    if (totalGroup) {
      tableDef.colgroups[totalGroup] = {title: 'TOTAL'};
    }

    if (opts.period !== 'none') {
      forEach(r.intervals, (ref) => {
        const item = 'p' + counter;
        if (opts.extraColumns) {
          tableDef.colgroups[item] = {title: ref.label};
          each(opts.extraColumns, (colname, index: number) => {
            const column = find(this.reportColumnOptions, {id: colname});
            tableDef.columns.push(this.addColumn(column, index, item + colname, ref.label));
          });
        }
        counter++;
      });
    }

    if (opts.extraColumns) {
      each(opts.extraColumns, (colname, index: number) => {
        const column = find(this.reportColumnOptions, {id: colname});
        tableDef.columns.push(this.addColumn(column, index, 'total' + colname, '', true));
      });
    }

    // okay - iterate over the returned data, sorting by primary and possibly secondary key
    const rowRefs = [];
    // first, order the primary labels
    const rows = flatten(values(this.filterCategories(r.rows, opts)));
    let pOrder = sortBy(rows, 'label');
    const significantPrimaryKeys = [];
    const significantSecondaryKeys = [];
    const s1 = tableDef.columns[0].fromID;
    const s1Sorter = obj => _toString(obj[s1]).toLowerCase();
    pOrder = orderBy(pOrder, [s1Sorter], ['asc', 'desc']);

    if (sRef) {
      // we have a secondary key
      each(pOrder, (pitem: any, pds) => {
        let significant = false;
        const sOrder = sortBy(pitem.secondary, 'label');
        each(sOrder, (item, sds) => {
          if (item.items.length || opts.includingAll) {
            significant = true;
            if (indexOf(significantSecondaryKeys, item.label) < 0) {
              significantSecondaryKeys.push(item.label);
            }
            // there were matching items
            // push this data into a row
            const d = item;
            d.totalObservations = item.items.length;
            each(item.extras, (value, col) => {
              d['total' + col] = value;
            });
            each(item.intervals, (iref, name) => {
              d[name] = iref.items.length;
              each(iref.extras, (value, col) => {
                d[name + col] = value;
              });
            });
            rowRefs.push(d);
          }
        });
        if (significant) {
          if (indexOf(significantPrimaryKeys, pitem.label) < 0) {
            significantPrimaryKeys.push(pitem.label);
          }
        }
      });
    } else {
      // we only have a primary key
      each(pOrder, (item: any, pds) => {
        if (item.items.length || opts.includingAll) {
          if (indexOf(significantPrimaryKeys, item.label) < 0) {
            significantPrimaryKeys.push(item.label);
          }
          // push this data into a row
          const d = item;
          d.totalObservations = d.items.length;
          each(item.extras, (value, col) => {
            d['total' + col] = value;

          });
          each(item.intervals, (iref, name) => {
            d[name] = iref.items.length;
            each(iref.extras, (value, col) => {
              d[name + col] = value;
            });
          });
          rowRefs.push(d);
        }
      });
    }

    if (theChart) {
      // okay all the rows are gathered.  Do we need to graph?
      if (opts.graphType !== 'none') {
        const checkReportFieldOptions = clone(this.reportFieldOptions);
        const checkReportColumnOptions = clone(this.reportColumnOptions);
        const keys = {
          primary: significantPrimaryKeys,
          secondary: significantSecondaryKeys
        };

        // if we are graphing by observation category, let's send in an ordered list of colors
        let graphData: any;
        if (opts.graphPrimary === 'period') {
          graphData = this.graphService.graphdataByPeriod(opts, r, rowRefs, keys, checkReportFieldOptions, checkReportColumnOptions);
        } else if (opts.graphPrimary === opts.primary) {
          graphData = this.graphService.graphdataByPrimary(opts, rowRefs, keys, checkReportFieldOptions, checkReportColumnOptions);
        } else if (opts.secondary && opts.graphPrimary === opts.secondary) {
          graphData = this.graphService.graphdataBySecondary(opts, rowRefs, keys, checkReportFieldOptions, checkReportColumnOptions);
        }
        if (graphData) {
          $('.report-view-has-chart').show();
          this.drawReportGraph(theChart, graphData, opts, reportId);
        }
      } else {
        $('.report-view-has-chart').hide();
      }
    }

    if (theTable) {
      tableDef.data = rowRefs;
      tableDef.columns = this.preparationColumns(tableDef.columns);
      this.tableService.showDataTable(theTable, tableDef);
    }
  }

  public async findInterval(opts) {
    const intervals = this.observations.calendarInterval(opts.startTime, opts.endTime, opts.period, opts.timespan, opts.format);

    this.opts = cloneDeep(opts);
    this.resps = await this.checkResponseService.getMatchingCheckResponses(this.opts);

    let primary: IObjectStringKeyMap<any> = {};
    const primaryKeys = {};
    const secondaryKeys = {};

    this.checkOpts = opts;

    const pRef: any = find(this.reportFieldOptions, {id: opts.primary});
    const sRef: any = find(this.reportFieldOptions, {id: opts.secondary});

    primary = this.getPrimary(opts, sRef, intervals);

    const respsByInstanceID = groupBy(this.resps, 'instanceID');

    forEach(respsByInstanceID, (respsGroup: any, key) => {
      let status = 'available';
      if (some(respsGroup, {status: 'complete'})) {
        status = 'complete';
      } else if (some(respsGroup, {status: 'notCompleted'})) {
        status = 'notCompleted';
      } else if (some(respsGroup, {status: 'skipped'})) {
        status = 'skipped';
      } else if (some(respsGroup, {status: 'missed'})) {
        status = 'missed';
      }
      forEach(respsGroup, (resp: any) => {
        resp.instanceStatus = status;
      });
    });

    // accumulate information about every response OPENED in the range of the report

    forEach(this.resps, (ref: any) => {
      let ctime = ref.availableTime * 1000;

      if (ref.completionTime) {
        ctime = ref.completionTime * 1000;
      } else if (ref.startTime) {
        ctime = ref.startTime * 1000;
      } else if (ref.claimTime) {
        ctime = ref.claimTime * 1000;
      }

      if (ctime < opts.startTime || ctime > opts.endTime) {
        return;
      }

      // we need to use the targetSignature to figure out the location and possibly zone
      if (!ref.locationID) {
        if (!isEmpty(ref.answers)) {
          each(ref.answers, data => {
            ref.locationID = data.locationID;
            return false;
          });
        }
        if (!ref.locationID) {
          // this responses has no answers with locations and has no base location
          // can we discover the location of its target?
          const splitSig = split(ref.targetSignature, ':');
          if (splitSig[0] == 'loc') {
            ref.locationID = +splitSig[1];
          } else if (splitSig[0] == 'asset') {
            ref.locationID = this.assetsService.getAssetLocation(+splitSig[1]);
          } else if (splitSig[0] == 'worker') {
            ref.locationID = this.accountsService.getUserLocation(+splitSig[1]);
          }
          if (!ref.locationID) {
            ref.locationID = get(ref, 'targetLocations[0]') || 0;
          }
        }
      }

      if (!ref.zoneID) {
        ref.zoneID = get(ref, 'targetZones[0]') || 0;
      }


      ref.intervals = intervals;

      if (!this.checkResponseService.checkResponse(ref, opts)) {
        return;
      }

      const createItem = (key: string, label: string) => {
        const newItem: any = {
          extras: {},
          items: [],
          label,
          intervals: {},
          [pRef.fieldName]: label,
          fieldName: pRef.fieldName
        };

        primaryKeys[label] = key;

        if (sRef) {
          newItem.secondary = {};
        } else {
          if (opts.interval !== 'none') {
            each(intervals, (iref, counter) => {
              const iname = 'p' + counter;
              newItem.intervals[iname] = {extras: {}, items: []};
            });
          }
        }

        return newItem;
      };

      const findItem = (key: string, label: string) => {
        if (!has(primary, key)) {
          primary[key] = createItem(key, label);
        } else {
          const item = find(flatten([primary[key]]), <any>{label});

          if (!item) {
            primary[key] = [primary[key], createItem(key, label)];
          }
        }

        return find(flatten([primary[key]]), <any>{label});
      };

      // function to accumulate an individual item
      const addItem = (p, s) => {
        if (p[0] !== undefined) {
          let target = null;
          // we have a key
          const item: any = findItem(p[0], p[1]);
          if (sRef) {
            if (s[0] !== undefined) {
              // we have a key
              if (!has(secondaryKeys, s[1])) {
                // remember it
                secondaryKeys[s[1]] = s[0];
              }

              if (!has(item.secondary, s[0])) {
                // we don't have this bucket yet
                item.secondary[s[0]] = {
                  extras: {},
                  items: [],
                  label: s[1],
                  intervals: {},
                  fieldName: pRef.fieldName,
                  secondFieldName: sRef.fieldName,
                  [pRef.fieldName]: p[1],
                  [`secondary_${sRef.fieldName}`]: s[1]
                };
                // add in the interval buckets too
                if (opts.interval !== 'none') {
                  each(intervals, (iref, counter) => {
                    const iname = 'p' + counter;
                    item.secondary[s[0]].intervals[iname] = {extras: {}, items: []};
                  });
                }
              }
              item.secondary[s[0]].items.push(ref);
              target = item.secondary[s[0]];
            }
          } else {
            item.items.push(ref);
            target = item;
          }

          if (opts.interval !== 'none') {
            forEach(intervals, (iref, counter) => {
              // in each interval, add the data into the primary and secondary accumulators
              if (ctime >= iref.start && ctime <= iref.end) {
                const iname = 'p' + counter;
                target.intervals[iname].items.push(ref);
                // we found a bucket for this observation
                return false;
              } else {
                return true;
              }
            });
          }
        }
      };

      // hold the primary key and label
      // get the primary field value from the record
      let pvList = get(ref, pRef.fieldName);
      if (pvList === undefined) {
        if (pRef.fieldRequired) {
          pvList = null;
        }
      }
      if (!isArray(pvList)) {
        pvList = [pvList];
      }
      each(pvList, (pv) => {
        let p = [];
        if (pv == null) {
          return;
        }
        if (pRef.hasOwnProperty('fieldFunc')) {
          // use the field function to derive the key and label
          // vrom the primary value
          p = pRef.fieldFunc(pv, ref);
        } else {
          // there is no translation function.  let's just use the id as
          // the key and the value
          p = [pv, pv];
        }
        if (sRef) {
          // hold the primary key and label
          let s = [];
          // get the primary field value from the record
          let svList = get(ref, sRef.fieldName);
          if (svList === undefined) {
            if (sRef.fieldRequired) {
              svList = null;
            }
          }
          if (!isArray(svList)) {
            svList = [svList];
          }
          each(svList, (sv) => {
            if (sv == null) {
              return;
            }
            if (sRef.hasOwnProperty('fieldFunc')) {
              // use the field function to derive the key and label
              // vrom the primary value
              s = sRef.fieldFunc(sv, ref);
            } else {
              // there is no translation function.  let's just use the id as
              // the key and the value
              s = [sv, sv];
            }
            if (p && s) {
              addItem(p, s);
            }
          });
        } else {
          if (p) {
            addItem(p, [null, null]);
          }
        }
      });
    });

    // okay, we have every row populated now

    const extras = {};
    let extraColumns: any[] = get(opts, 'extraColumns', []);
    if (!isArray(extraColumns)) {
      extraColumns = [extraColumns];
    }

    each(extraColumns, (column) => {
      // get a ref to the column definiton
      const c: any = find(this.reportColumnOptions, {id: column});

      // iterate over everything to do the calculations at each interval and each primary/secondary
      each(primary, (primaryItem: any) => {
        each(flatten([primaryItem]), (pItem) => {
          const updateIntervals = (theItem) => {
            if (opts.interval !== 'none') {
              each(theItem.intervals, (intervalRef) => {
                intervalRef.extras[column] = c.func(intervalRef.items);
              });
            }
          };

          pItem.extras[column] = c.func(pItem.items);
          if (opts.secondary) {
            // lets do this
            each(pItem.secondary, (sItem) => {
              sItem.extras[column] = c.func(sItem.items);
              updateIntervals(sItem);
            });
          } else {
            updateIntervals(pItem);
          }
        });
      });
    });

    // build an object of the accumulated data

    const r = {
      rows: opts.secondary ? primary : this.filterCategories(primary, opts),
      intervals,
      primaryKeys,
      secondaryKeys
    };

    return r;
  }

  public async getTableItems(queryParams: any) {
    const [data] = await awaitHandler(this.findInterval(queryParams));
    const currentRow: any = find(flatten(values(get(data, 'rows'))), <any>{label: queryParams.primaryLabel}) || {};
    let tableItems: any[] = [];

    if (currentRow.secondary) {
      if (queryParams.secondaryLabel) {
        if (isUndefined(queryParams.index)) {
          tableItems = get(find(currentRow.secondary, <any>{label: queryParams.secondaryLabel}), 'items', []);
        } else {
          const combineData = [];
          each(get(data, 'rows'), (dataItem) => {
            each(dataItem.secondary, (secondaryItem) => {
              if (secondaryItem.label === queryParams.secondaryLabel) {
                combineData.push(secondaryItem);
              }
            });
          });
          let currentData: any = combineData[0];
          each(combineData, (item) => {
            if (isObject(currentData) && isEmpty(currentData)) {
              currentData = item;
            } else {
              currentData.items = concat(currentData.items, item.items);
              each(data.intervals, (interval, id) => {
                currentData.intervals[id].items = concat(currentData.intervals[id].items, interval.items);
              });
            }
          });
          tableItems = get(map(currentData.intervals, 'items'), `[${queryParams.index}]`, []);
        }
      } else {
        tableItems = flatten(map(filter(currentRow.secondary, <any>{[currentRow.fieldName]: currentRow[currentRow.fieldName]}), 'items'));

        if (tableItems.length === 0) {
          tableItems = flatten(map(currentRow.secondary, 'items'));
        }
      }
    } else {
      if (isUndefined(queryParams.index)) {
        tableItems = currentRow.items;
      } else {
        tableItems = get(map(currentRow.intervals, 'items'), `[${queryParams.index}]`, []);
      }
    }

    return intersectionBy(tableItems, 'responseID');
  }

}
