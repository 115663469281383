export enum CompareValue {
  EqualTo = 'equalTo',
  NotEqualTo = 'notEqualTo',
  GreaterThan = 'greaterThan',
  LessThan = 'lessThan',
  GreaterThanEqualLessThanEqual = 'greaterThanEqualLessThanEqual',
  GreaterThanLessThan = 'greaterThanLessThan'
}

export interface OutputValue {
  id: CompareValue;
  min: number;
  max?: number;
}
