<div>

  <ion-searchbar (ionInput)="changeSearchModel($event)" *ngIf="isSearchable"
                 [value]="searchModel" placeholder="{{'SHARED.Search' | translate}}"></ion-searchbar>
  <ion-list class="ion-list" #list (deferLoad)="init()" lines="full">
    <ion-item *ngIf="isMultiple && isSelectAllEnabled"
               (click)="selectAll()" [ngClass]="{'selected': selectAllEnabled}">
      <ion-checkbox [checked]="selectAllEnabled"></ion-checkbox>
      <div class="info">
        <div [ngClass]="selectAllEnabled ? 'list-label-selected': 'list-label'" class="item-info name">
          {{'SHARED.Select_All' | translate}}
        </div>
      </div>
    </ion-item>

    <ion-item (click)="selectItem(item)"
              *ngFor="let item of viewItems; trackBy: trackByFunction | orderBy: 'name'"
              [hidden]="item.hidden"
              [ngClass]="{'selected': item.checked}"
              [disabled]="item.isDisabled">
      <ion-avatar *ngIf="item.avatarUrl">
        <img [src]="item.avatarUrl">
      </ion-avatar>

      <ion-checkbox *ngIf="isMultiple; else info" [checked]="item.checked" label-placement="end" slot="start">
        <ng-container *ngTemplateOutlet="labelRef; context: { item: item }"></ng-container>
      </ion-checkbox>

      <ng-template #info>
        <ng-container *ngTemplateOutlet="labelRef; context: { item: item }"></ng-container>
      </ng-template>
    </ion-item>

    <div (scrolled)="loadNextItems()" *ngIf="isInfinityScrollEnabled" [fromRoot]="true" [infiniteScrollContainer]="'.ion-list'" infiniteScroll></div>
  </ion-list>
</div>

<ng-template #labelRef let-item="item">
  <div class="info">
    <div [ngClass]="item.checked ? 'list-label-selected': 'list-label'" class="item-info name">{{item.name}}</div>
    <div *ngIf="item.description !== ''" class="item-info description">{{item.description}}</div>
  </div>
</ng-template>
