import { IObjectStringKeyMap } from '@modules/shared/models';
import { CustomFields } from '@modules/customFields/custom-fields.interfaces';
import { PropertyCategory } from '@services/property/property-model.interfaces';

export interface Observation {
  uuid: string;
  created: number;
  observationID: number;
  state: string;
  type: string;
  subtype?: ObservationSubtypes;
  userID: number;
  zoneID: number;
  ownerID: number;
  groupID: number;
  asset: number;
  sustainable: number;
  subjectType: string;
  subjectID: number;
  locationID: number;
  history: ObservationHistory[];
}

export interface ObservationHistory {
  activity: string;
  relatedItem: string;
  relatedType: string;
  shift: string;
  time: number;
  userID: number;
  relatedData: ObservationHistoryRelatedData;
  observationID?: number;
}

export interface ObservationHistoryRelatedData {
  activity: string;
  state: string;
  time: number;
  value: string;
  category: PropertyCategory;
}

export interface ObservationStatusCode {
  title: string;
  type: string;
}

export enum ObservationFlow {
  Required = 'required',
  Optional = 'optional',
  Disabled = 'disabled'
}

export interface IObservationSettings {
  type: ObservationTypes;
  subtype: ObservationSubtypes;
  flow: IObjectStringKeyMap<ObservationFlow> | null;
  customFields: CustomFields;
}

export enum ObservationTypes {
  Any = 'any',
  Asset = 'ai',
  Behavior = 'behavior',
  CA = 'ca',
  Compliment = 'compliment',
  Condition = 'condition',
  PI = 'pi',
  Quality = 'quality',
  Submission = 'si'
}

export enum ObservationSubtypes {
  QualityReceiving = 'receiving',
  QualityProduction = 'production',
  QualityRMA = 'rma',
  PIWaiting = 'waiting',
  PIGeneral = 'general'
}
